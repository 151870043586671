// Quote Utilities

// status check
export function inQuoteStatus(status, validStatuses) {
  return validStatuses.indexOf(status) > -1
}

// Is the quote valid to move forward?
export function validateQuote({quote, paymentOptions, lineItemCount}) {
  let errors = []

  if (quote === undefined) { errors.push("Issue with Quote") }
  if (quote === null) { errors.push("Issue with Quote") }

  // billing address?
  if (quote.billingAddress === undefined) { errors.push("Must select a Billing Address") }
  if (quote.billingAddress.id === undefined) { errors.push("Must select a Billing Address") }
  
  // shipping address?
  if (quote.shippingAddress === undefined) { errors.push("Must select a Shipping Address") }
  if (quote.shippingAddress.id === undefined) { errors.push("Must select a Shipping Address") }
  
  // line items?
  if (lineItemCount === undefined || lineItemCount === 0) { errors.push("You need to add at least one Product") }

  // taxes?
  if (quote.taxTotal === 0 && quote.taxRate === 0 && quote.taxRegion === '') { errors.push("Did you calculate Tax?") }

  // shipping cost?
  if ((quote.shippingTotal === 0 && !quote.freeShipping) && (quote.shippingTotal === 0 && !quote.willCall)) { errors.push("Did you update Shipping?") }

  // payment options?
  if (paymentOptions === undefined) { errors.push("Must select Payment Options") }
  if (paymentOptions === null) { errors.push("Must select Payment Options") }
  if (paymentOptions.length === 0) { errors.push("Must select Payment Options") }

  // const { taxRate, taxTotal, total } = quote
  // const expectedTaxTotal = Math.round(total * taxRate * 100) / 100
  // const expectedTaxTotal = (total * taxRate).toFixed(2)

  // console.log("tax => ", expectedTaxTotal, taxTotal, expectedTaxTotal !== taxTotal)

  // if (parseFloat(expectedTaxTotal) !== parseFloat(taxTotal)) { errors.push("Tax Total is not correct, please recalculate") }  

  if (!quote.taxCalculated) { errors.push("Tax has not been calculated") }

  return {
    errors,
    valid: errors.length === 0,
  }

}

// find the last entry of the given status
export function findQuoteStatus(history, status) {
  const list = history.filter((h) => h.status === status)
  return list[list.length - 1]
}