import React from 'react';
import { DropdownList } from 'react-widgets';
import { Link } from 'react-router-dom';
import ArrowRightBoxIcon from 'mdi-react/ArrowRightBoxIcon'
import { Row, Col } from 'reactstrap';

import Error from '../../Error';

import { updateCompany } from '../../../api/company';

import { renderStars } from '../../helpers';

export default class CompanyItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      active: true,
      user: null,
      errors: [],
    };
  }

  componentWillMount() {
    const { company } = this.props;
    this.setState({
      user: company.sales_rep,
      active: company.active
    });
  }

  updateCompanyData = (value, name) => {
    this.setState({
      [name]: value
    }, () => this.saveCompanyData());
  }

  saveCompanyData = () => {
    const { company } = this.props;
    const { user, active } = this.state;
    let userId = null;
    if (user !== undefined) { userId = user.id; }
    console.log(userId);
    const data = {
      company: {
        active: active,
        user_id: userId,
      }
    };

    updateCompany(company.id, data)
      .then((response) => {
        if (response.errors.length > 0) {
          this.setState({ errors: response.errors })
        } else {
          const c = this;
          c.setState(
            {
              message: "Company Updated"
            },
            () => setTimeout(function() { 
              c.setState({ message: '' }) },
              2000)
          );
        }
      });
  }

  statusMessage() {
    const { message } = this.state;
    if (message === '') { return null }
    return (
      <Row>
        <Col className="text-center">
          <span className="label label-info">
            {message}
          </span>
        </Col>
      </Row>
    );
  }

  

  render() {
    const { company, userList } = this.props;
    const { active, user, errors } = this.state;
    return (
      [
        <Error errors={errors} />,
        <Row>
          <Col md={4}>
            {company.name}
            {' '}
            <Link to={`/companies/${company.id}`}>
              <ArrowRightBoxIcon size={24} />
            </Link>
          </Col>
          <Col md={2}>
            {renderStars(company.stars)}
          </Col>
          <Col md={1} className="text-right">
            <span class="label label-primary">
              {company.as400_id}
            </span>
          </Col>
          <Col md={3}>
            <DropdownList
              data={userList}
              filter
              value={user}
              textField="name"
              onChange={value => this.updateCompanyData(value , 'user')} 
            />
          </Col>
          <Col md={2}>
            <DropdownList
              data={[true, false]}
              value={active}
              onChange={value => this.updateCompanyData(value, 'active')} 
            />
          </Col>
        </Row>,
        this.statusMessage(),
        <hr />,
      ]
    )
  }
}