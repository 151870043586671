import React from 'react';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import PageHeader from '../components/PageHeader';
import CardWrapper from '../components/CardWrapper';
import ErrorBoundary from '../components/ErrorBoundary';

import TaskList from './tasks/TaskList';

import api from '../api/init';
import { getSalesDataForRep } from '../api/report';
import { getCompanyActivityList } from '../api/company';
import { inValidRole } from '../libs/utils';

const years = () => {
  const startYear = 2019;
  const endYear = new Date().getFullYear();
  let list = new Array();

  for(let i = startYear; i <= endYear; i++) {
    list.push(i);
  }
  return list;
};

const options = {
  responsive: true,
  tooltips: {
    // mode: 'label',
    callbacks: {
      label: function (tooltipItem, data) {
        console.log(tooltipItem);
        var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(label);
      }
    }
  },
  elements: {
    line: {
      fill: false
    }
  }
};

const Chart = ({ data }) => {
  return (
    <Bar
      title="Sales YTD vs LYTD"
      height={140}
      data={data}
      options={options}
      redraw={false}
    />
  );
};

class Dashboard extends React.Component {
  constructor(props) {
    const userId = localStorage.getItem('uid');
    const role = localStorage.getItem('role');
    super(props);
    this.state = {
      chartData: null,
      userId,
      role,
      salesUsers: [],
      loadingChartData: false,
      customerListLoading: true,
      companies: [],
      startYear: null,
      endYear: null,
    };
  }

  componentDidMount() {
    const { role } = this.state;

    let date = new Date();
    date = date.getFullYear();

    this.setState({
      startYear: date - 1,
      endYear: date
    })

    if (this.canSeeDropDown()) {
      api
        .get('/api/v1/users.json?role=sales_rep&r=1', { withCredentials: true })
        .then(response => this.setState({
          salesUsers: response.data.users,
        }, () => this.selectAEI()))
        .catch((error) => {
          console.log(error);
        });
    } else {
      const uid = localStorage.getItem('uid');
      this.setState({ userId: uid }, () => this.loadChartData());

      this.loadCompanyActivityList();
    }
  }

  selectAEI() {
    try {
      console.log('looking for AEI account...');
      const { salesUsers } = this.state;
      const userId = salesUsers.filter(x => x.name === 'AEI')[0].id;
      this.setState({ userId }, () => this.loadChartData());
    } catch(e) {
      console.log('no user found');
      // nothing.
    }
  }

  loadCompanyActivityList() {
    getCompanyActivityList()
      .then(data => {
        this.setState({ companies: data, customerListLoading: false });
      });
  }

  loadChartData() {
    this.setState({ loadingChartData: true })
    const { userId, endYear, startYear } = this.state;

    getSalesDataForRep({ userId, endYear, startYear })
      .then((data) => {
        this.setState({
          chartData: data.chartData,
          loadingChartData: false,
        });
      });
  }

  updateRep = value => {
    this.setState({ userId: value.value.id }, () => this.loadChartData());
  }

  canSeeDropDown = () => {
    const { role } = this.state;
    return role === 'sales_manager' || role === 'admin' || role === 'sales_admin';
  }

  chartColumnSize = () => {
    const { role } = this.state;
    if (role === 'sales_rep') {
      return 8;
    }
    return 12;
  }

  renderCompanyActivityList() {
    const { customerListLoading, companies } = this.state;
    if (customerListLoading) {
      return (
        <span>
          Loading...
        </span>
      );
    } else {
      if (companies.length === 0) {
        return (
          <Row>
            <Col className="text-center">
              No Companies Assigned to you.
            </Col>
          </Row>
        );
      }
      return (
        companies.map(item => [
          <Row key={item.id}>
            <Col md={2}>
              <img src={item.logo} alt="company" className="img-circle img-responsive" style={{ width: 30, height: 30 }} />
            </Col>
            <Col xs={12} md={6}>
              <Link
                to={{
                  pathname: `/companies/${item.id}`,
                }}
              >
                {item.name}
              </Link>
            </Col>
            <Col xs={12} md={4}>
              <span className="label label-primary">
                {this.renderDateTimeDifference(item.last_activity)}
              </span>
            </Col>
          </Row>,
          <hr size={1} />,
        ])
      );
    }
  }

  renderDateTimeDifference(date) {
    var today = moment();
    var duration = moment.duration(today.diff(date));
    var years = duration.asYears();
    console.log(years);

    if (years >= 9) {
      return "Never Contacted";
    } else {
      return (
        <Moment fromNow>
          {date}
        </Moment>
      );
    }
  }

  updateChartYear = (value, field) => {
    console.log(`${value} => ${[field]}`);
    this.setState({
      [field]: value
    }, () => {
      this.loadChartData();
    })
  }

  renderChartContainer = () => {
    // const year = new Date().getFullYear();
    const { chartData, salesUsers, loadingChartData, startYear, endYear } = this.state;
    if (inValidRole(['csr', 'csr_manager'], localStorage)) {
      return null;
    }
    return (
      <Row>
        <Col md={this.chartColumnSize()}>
          <CardWrapper>
            <Row>
              <Col md={6}>
                <h3 className="card-title">Sales Overview</h3>
                <h6 className="card-subtitle">
                  <Row>
                    <Col md={3}>
                      <DropdownList
                        data={years()}
                        textField="startYear"
                        value={startYear}
                        onChange={value => this.updateChartYear(value, 'startYear')}
                      />
                    </Col>
                    <Col md={1} className="text-center">
                      vs.
                    </Col>
                    <Col md={3}>
                      <DropdownList
                        data={years()}
                        textField="endYear"
                        value={endYear}
                        onChange={value => this.updateChartYear(value, 'endYear')}
                      />
                    </Col>
                  </Row>
                  {/* Sales
                  {`${startYear} vs ${endYear} Sales`} */}
                </h6>
              </Col>
              {this.canSeeDropDown() && salesUsers.length > 0 && (
                <Col md={6}>
                  <DropdownList
                    data={salesUsers}
                    textField="name"
                    placeholder="AEI - Company Total"
                    onChange={value => this.updateRep({ value })}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                {loadingChartData && (
                  <p>Loading...</p>
                )}
                {!loadingChartData && chartData !== null && (
                  <Chart data={chartData} />
                )}
              </Col>
            </Row>
          </CardWrapper>
        </Col>
        {!this.canSeeDropDown() && (
          <Col md={4}>
            <CardWrapper>
              <h3>These customers need some appreciation!</h3>
              <p className="text-info">
                <small>
                  These customers haven't heard from recently.
                </small>
              </p>
              {this.renderCompanyActivityList()}              
            </CardWrapper>
          </Col>
        )}
      </Row>
    )
  }

  render() {
    return (
      <ErrorBoundary message="Item could not be rendered (Has been reported)">
        <PageHeader title="Dashboard" />
        {this.renderChartContainer()}
        <TaskList displayHeader={false} displayFilters={false} />
      </ErrorBoundary>
    );
  }
}

export default Dashboard;
