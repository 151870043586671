import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
// import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
// import DomainIcon from 'mdi-react/DomainIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import CheckBoxIcon from 'mdi-react/CheckBoxIcon';

const iconSize = 18;

export default class TimelineItem extends React.Component {

  renderIcon = (eventType) => {
    let icon = null;
    switch(eventType) {
      case "event": {
        icon = <CalendarIcon size={iconSize} />
        break;
      }
      case "activity": {
        icon = <FormatListBulletedIcon size={iconSize} />
        break;
      }
      case "task": {
        icon = <CheckBoxIcon size={iconSize} />
        break;
      }
      default: {
        icon = null
        break;
      }
    }
    return (
      icon
    );
  }

  render() {
    const { item } = this.props;

    return (
      <li>
        <time class="cbp_tmtime" datetime="2017-11-04T18:30">
          <span class="hidden">
            <Moment format="YYYY-MM-DD">
              {item.date}
            </Moment>
          </span>
          </time>
        <div class="cbp_tmicon">
          {this.renderIcon(item.type)}  
        </div>
        <div class="cbp_tmlabel empty">
          <span>
            <Link to={item.link}>
              {item.name}
            </Link>
          </span>
        </div>
      </li>
    );
  }
}
