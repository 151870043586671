import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

// icons
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import EyeIcon from "mdi-react/EyeIcon";

// components
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';

import UrlRow from '../../components/map/report/row';

// api
import { getMap, updateUrlSnapshot, exportRequest } from '../../api/map';

// css
import styles from './Report.module.scss'
import { Tab } from 'bootstrap';

const MapReport = () => {
  const { id } = useParams();

  const [map, setMap] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);

  const [snapshot, setSnapshot] = useState(null)

  const [activeTab, setActiveTab] = useState('1');

  let reportTitle = "Map Report"

  // lifecycle

  useEffect(() => {
    getMap({ id, extended: '1' }).then((res) => {
      console.log(res)
      setMap(res.map)
      reportTitle = res.map.name
      setLoading(true)
    })
  }, [id])

  // actions

  const toggle = () => setModal(!modal);

  const tabToggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const openModalForSnapshot = (snapshot) => {
    console.log(snapshot)
    setSnapshot(snapshot)
    toggle()
  }

  const updateScreenshot = (e, snapshot, status) => {
    const data = {
      mapId: map.id,
      urlSnapshot: { ...snapshot, status: status },
    }
    updateUrlSnapshot(data)
      .then((res) => {
        setMap({...map, urls: res.urls})
        toggle()
      })
  }

  const updateSnapshot = (e) => {
    const { value, name } = e.target
    setSnapshot({ ...snapshot, [name]: value })
  }

  const handleExportClick = (e, format) => {
    e.preventDefault()
    exportRequest({ mapId: map.id, format: format })
    .then((res) => {
        alert('Export Started...')
      })
  }

  // render

  const renderTableHeader = () => {
    return (
      <>
        <Row>
          <Col xs={12} md={2} className={styles.header}>
            SKU / URL
          </Col>
          <Col xs={12} md={10} className={styles.header}>
            History
          </Col>
        </Row>
        <hr />
      </>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        fullscreen={true}
      >
        {snapshot && (
          <>
            <ModalHeader toggle={toggle}>
              Webpage Title: {snapshot.page_title}
            </ModalHeader>
            <ModalBody>
              
              <div className={styles.imageContainer}>
                <img
                  src={snapshot.image_url}
                  className={styles.image}
                  alt="snapshot"
                />
              </div>
            
            </ModalBody>
            <ModalFooter>
              <Row className={styles.actionRow}>
                <Col xs={12} md={6}>
                  <label>
                    Notes:
                  </label>
                  <textarea
                    className="form-control"
                    onChange={e => updateSnapshot(e)}
                    name="notes"
                    disabled={snapshot.status === 'approved' || snapshot.status === 'rejected' || snapshot.status === 'restricted'}
                  >
                    {snapshot.notes}
                  </textarea>
                </Col>
                <Col xs={12} md={3}>
                  <label>
                    Price:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    step="0.01"
                    name="logged_price"
                    defaultValue={snapshot.logged_price}
                    onChange={e => updateSnapshot(e)}
                    disabled={snapshot.status === 'approved' || snapshot.status === 'rejected' || snapshot.status === 'restricted'}
                  />
                </Col>
                <Col xs={12} md={3}>
                  {(snapshot.status === 'approved' || snapshot.status === 'rejected' || snapshot.status === 'restricted') ? (
                    <button
                      onClick={toggle}
                      className="btn btn-secondary"
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        className={styles.buttonApproved}
                        onClick={(e) => updateScreenshot(e, snapshot, 'approved')}
                      >
                        <CheckIcon size={48} className={styles.approved} />
                        {' '}
                        Approve
                      </button>
                      {' '}
                      <button
                        className={styles.buttonReject}
                        onClick={(e) => updateScreenshot(e, snapshot, 'rejected')}
                        >
                        <CloseIcon size={48} className={styles.reject} />
                        {' '}
                        Reject
                      </button>
                      {' '}
                      <button
                        className={styles.buttonReject}
                        onClick={(e) => updateScreenshot(e, snapshot, 'restricted')}
                        >
                        <EyeIcon size={48} className={styles.reject} />
                        {' '}
                        Restricted Access
                      </button>
                    </>
                  )}
                </Col>
              </Row>
            </ModalFooter>
          </>
        )}
      </Modal>
    )
  }

  return (
    <>
      <PageHeader title={reportTitle} />
      <CardWrapper>
        {loading ? (
          <>
            <Row>
              <Col className="text-end">
                <small>
                  <em>
                    You will receive an email (Export 1 year of data)
                  </em>
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="text-end">
                <button
                  className="btn-primary btn"
                  onClick={e => handleExportClick(e, 'csv')}
                >
                  Export CSV
                </button>
                {' '}
                <button
                  className="btn-primary btn"
                  onClick={e => handleExportClick(e, 'excel')}
                >
                  Export Excel
                </button>
              </Col>
            </Row>

            {renderTableHeader()}
            {map.urls.map((url) => (
              <UrlRow
                key={url.id}
                data={url}
                modalClicked={openModalForSnapshot}
              />
            ))}
          </>
          ) : (
          <div>Loading...</div>
        )}
      </CardWrapper>
      {renderModal()}
    </>
  )
}

export default MapReport
