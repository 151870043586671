import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Row, Col } from 'reactstrap';

const colors = [
  "#8884d8",
  "#82ca9d",
  "#fc4b6c",
  "#82ca9d",
  "#8884d8",
  "#82ca9d",
  "#8884d8",
  "#82ca9d",
  "#8884d8",
  "#82ca9d"
];

class SalesHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      yearsToShow: [],
      data: [
        {
          name: "2014",
          jan: 4000,
          pv: 2400,
          amt: 2400
        },
      ],
      years: [],
      loadingChartData: false,
    };
  }

  componentWillMount = () => {
    const { salesHistories } = this.props;
    const years = salesHistories.map(x => x.year)
    let data = [];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    months.map((month, i) => {
      let dataItem = {
        name: month,
      };
      years.map((year, j) => {
        dataItem[year] = salesHistories.filter(x => x.year === year)[0].months[i].sales_total;
      });

      data.push(dataItem);
    });

    this.setState({
      data,
      years,
    });
  }

  renderBarList = () => {
    const { years } = this.state;
    return (
      years.map((year, i) => (
        <Bar dataKey={`${year}`} fill={colors[i]} />
      ))
    );
  }

  render() {

    const { loadingChartData, data } = this.state;

    return (
      <Row>
        <Col>
          <ResponsiveContainer aspect={16.0/9.0} width="100%">
            <BarChart
              height={450}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {this.renderBarList()}
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    );
  }
}

export default SalesHistory;
