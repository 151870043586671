import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

// components
import SalesGoal from '../../components/expandedDashboard/sales_goal';
import SalesOverview from '../../components/expandedDashboard/sales_overview';
import SalesBreakdown from '../../components/expandedDashboard/sales_breakdown';
import Upcoming from '../../components/expandedDashboard/upcoming';
import Company from '../../components/expandedDashboard/company';

import { inValidRole } from '../../libs/utils';

import { fullUserListByRole } from '../../api/user';
// import { getCumulativeSalesReport } from  '../../api/report';

export default function ExpandedDashboard() {
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [salesUsers, setSalesUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [segment, setSegment] = useState('All');
  const [loading, setLoading] = useState(true);

  const [goalLoading, setGoalLoading] = useState(true);
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [breakdownLoading, setBreakdownLoading] = useState(true);
  const [upcomingLoading, setUpcomingLoading] = useState(true);
  const [companyLoading, setCompanyLoading] = useState(true);

  useEffect(() => {
    fullUserListByRole('sales_rep')
      .then((users) => {
        const userList = expandUsers(users);
        setSalesUsers(userList);
        setTimeout(() => {
          if (inValidRole(['sales_rep'], localStorage)) {
            setSelectedSalesRep(localStorage.getItem('uid'));
            setUserId(localStorage.getItem('uid'));
          } else if (inValidRole(['admin', 'sales_manager'], localStorage)) {
            const id = userList[0].id;
            setSelectedSalesRep(id);
            setUserId(userList[0].userId);
            setSegment(userList[0].segment);
          }
        }, 500);
      });
  }, []);

  useEffect(() => {
    if (!goalLoading && !overviewLoading && !breakdownLoading && !upcomingLoading && !companyLoading) {
      setLoading(false);
    }
  }, [goalLoading, overviewLoading, breakdownLoading, upcomingLoading, companyLoading]);

  const expandUsers = (users) => {
    const list = [];
    let counter = 1;
    users.forEach((user) => {
      list.push({
        id: counter,
        userId: user.id,
        name: user.name,
        segment: 0,
      });
      counter += 1;

      if (user.as400_id.length > 1) {
        user.as400_id.forEach((id) => {
          list.push({
            id: counter,
            userId: user.id,
            name: `- ${user.name} (${id})`,
            segment: id,
          });
          counter += 1;
        });
      }
    });
    return list;
  };

  // useEffect(() => {
  //   if (selectedSalesRep !== null) {
  //     getCumulativeSalesReport(selectedSalesRep)
  //       .then((reportData) => {
  //         setData(reportData.data);
  //         setDisplay(reportData.month);
  //       });
  //   }
  // }, [selectedSalesRep]);

  const updateRep = (value) => {
    const id = value.value.id;
    const item = salesUsers.filter(user => user.id === id)[0];
    console.log(`${id} => ${item.userId} / ${item.segment}`);
    setGoalLoading(true);
    setOverviewLoading(true);
    setBreakdownLoading(true);
    setUpcomingLoading(true);
    setCompanyLoading(true);
    setSelectedSalesRep(id);
    setUserId(item.userId);
    setSegment(item.segment);
    setLoading(true);
  };

  const userDropdownList = () => {
    return (
      <Row>
        <Col md={2}>
          Select Rep:
        </Col>
        <Col md={10}>
          <DropdownList
            data={salesUsers}
            textField="name"
            placeholder="Select Sales Rep."
            onChange={value => updateRep({ value })}
            disabled={loading}
          />
        </Col>
      </Row>
    );
  };

  const renderDropdown = () => {
    if (inValidRole(['admin', 'sales_manager'], localStorage)) {
      return (
        <CardWrapper>
          {userDropdownList()}
        </CardWrapper>
      );
    }

    if (inValidRole(['sales_rep'], localStorage)) {
      const list = salesUsers.filter(user => user.userId === localStorage.getItem('uid'));
      if (list.length === 0) { return null; }
      return (
        <CardWrapper>
          <Row>
            <Col md={2}>
              Select Segment:
            </Col>
            <Col md={10}>
              <DropdownList
                data={list}
                textField="name"
                placeholder="Select Segment"
                onChange={value => updateRep({ value })}
                disabled={loading}
              />
            </Col>
          </Row>
        </CardWrapper>
      );
    }

    return null;
  };

  const updateLoadingStatus = (child, status) => {
    console.log(`${child} => ${status}`);
    switch (child) {
      case 'goal':
        setGoalLoading(status);
        break;
      case 'overview':
        setOverviewLoading(status);
        break;
      case 'breakdown':
        setBreakdownLoading(status);
        break;
      case 'upcoming':
        setUpcomingLoading(status);
        break;
      case 'company':
        setCompanyLoading(status);
        break;
      default:
        break;
    }
  };

  return (
    <ErrorBoundary message="Item could not be rendered (Has been reported)">
      <Helmet>
        <title>The Hub - Dashboard</title>
      </Helmet>
      <PageHeader title="Dashboard" />
      {renderDropdown()}
      {selectedSalesRep !== null && (
        <>
          <SalesGoal userID={userId} segment={segment} updateStatus={updateLoadingStatus} />
          <SalesOverview userId={userId} segment={segment} updateStatus={updateLoadingStatus} />
          <SalesBreakdown userId={userId} segment={segment} updateStatus={updateLoadingStatus} />
          <Company userId={userId} updateStatus={updateLoadingStatus} />
          <Upcoming userId={userId} updateStatus={updateLoadingStatus} />
        </>
      )}
    </ErrorBoundary>
  );
}
