import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AddIcon from 'mdi-react/AddIcon';
import { DatePicker, Multiselect, DropdownList } from 'react-widgets';
import { Row, Col , Alert } from 'reactstrap';
import Moment from 'moment';

import Error from '../../components/Error';
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';

import { fullUserList } from '../../api/user';
import { getEvent, updateEvent, getEventTypes } from '../../api/event';

const EventForm = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [errors, setErrors] = useState([])
  const [users, setUsers] = useState([])
  const [formData, setFormData] = useState({
    event_name: '',
    description: '',
    event_type: '',
    user_list: [],
    start_date: null,
    end_date: null,
    all_day: false,
    private_event: false,
  })
  const [statusMessage, setStatusMessage] = useState('')
  const [eventTypes, setEventTypes] = useState([])
  const [user, setUser] = useState(null)
  const [can_edit, setCanEdit] = useState(false)
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    loadListOfUsers()
    loadEventTypes()
    loadEvent()
  }, [id])

  const loadListOfUsers = () => {
    fullUserList()
      .then((res) => {
        setUsers(res)
      });
  }

  const loadEventTypes = () => {
    getEventTypes()
      .then((res) => {
        let list = res.event_types;
        list.unshift("-- SELECT --");
        setEventTypes(list)
      });
  }

  const loadEvent = () => {
    getEvent(id)
      .then((event) => {
        setFormData({...formData, ...event})
        setCanEdit(event.can_edit)
      });
  }

  const updateUserList = (value) => {
    console.log(value);
    setFormData({...formData, user_list: value})
  }

  const updateEventState = (name, value) => {
    setFormData({...formData, [name]: value})
  }

  const updateDate = (value, field) => {
    console.log(value);
    setFormData({...formData, [field]: value})
  }

  const updatePrivateEvent = () => {
    setFormData({...formData, private_event: !formData.private_event})
  }
  
  const updateAllDay = () => {
    setFormData({...formData, all_day: !formData.all_day})
  }

  const filterName = (user, value) => {
    let name = user.name.toLowerCase()
    let search = value.toLowerCase();
    return name.indexOf(search) === 0
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    updateEventState(name, value);
  }

  const updateEventType = value => {
    setFormData({...formData, event_type: value})
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      id,
      event: formData,
    };

    console.log(data);

    updateEvent(data)
      .then((response) => {
        if (response.errors.length === 0) {
          navigate('/events');
        } else {
          setErrors(response.errors);
        }
      })
  }

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  const goBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <div>
      <PageHeader title="Edit Event" />
      <CardWrapper>
        <div className="row m-t-20">
          <div className="col">
            <Error errors={errors} />
            {statusMessage}

            {user !== null && (
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Created by</label>
                    <div className="task-box">
                      {user.name}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {can_edit && (
              <form id="EventForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col col-md-4">
                    <label className="control-label" htmlFor="start_date">Start Date</label>
                    <DatePicker
                      placeholder="Start Date and Time..."
                      name="start_date"
                      includeTime
                      defaultValue={new Date(formData.start_date)}
                      min={new Date()}
                      onChange={value => updateDate(value, 'start_date')}
                    />
                  </div>
                  <div className="col col-md-4">
                    <label className="control-label" htmlFor="end_date">End Date</label>
                    <DatePicker
                      placeholder="End Date and Time..."
                      name="end_date"
                      includeTime
                      value={new Date(formData.end_date)}
                      min={new Date()}
                      onChange={value => updateDate(value, 'end_date')}
                    />
                  </div>
                  <div className="form-group col col-md-2">
                    <div className="alert alert-info">
                      <label className="control-label" htmlFor="private_event">All Day?</label>
                      <br />
                      <input type="checkbox" name="all_day" id="all_day" checked={formData.all_day} onClick={() => updateAllDay()} /> Yes!
                    </div>
                  </div>
                  <div className="form-group col col-md-2">
                    <div className="alert alert-info">
                      <label className="control-label" htmlFor="private_event">Private?</label>
                      <br />
                      <input type="checkbox" name="private_event" id="private_event" checked={formData.private_event} onClick={() => updatePrivateEvent()} /> Yes!
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label className="control-label" htmlFor="name">Event Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="control-label" htmlFor="event_type">Event Type</label>
                      <DropdownList
                        data={eventTypes}
                        value={formData.event_type}
                        onChange={value => updateEventType(value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label" htmlFor="description">Description/Information</label>
                      <textarea name="description" rows={5} className="form-control" id="description" value={formData.description} onChange={handleChange}></textarea>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="control-label" htmlFor="users">Who is this event for?</label>
                      <Multiselect
                        data={users}
                        textField='name'
                        value={formData.user_list}
                        caseSensitive={false}
                        minLength={2}
                        filter={filterName}
                        onChange={value => updateUserList(value)}
                      />
                    </div>
                  </Col>
                </Row>
                
                <Row>
                  
                </Row>
                
                <div className="row">
                  <div className="col text-center">
                    <br />
                    <input className="btn btn-primary" type="submit" value="Update Event" />
                    &nbsp;
                    <button
                      onClick={(e) => goBack(e)}                      
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}

            {!can_edit && (
              <div>
                {formData.private_event && (
                  <Row>
                    <Col>
                      <Alert color="info">
                        Private Event
                      </Alert>
                    </Col>
                  </Row>
                )}
                <Row>
                  <div className="form-group col col-md-6">
                    <label className="control-label" htmlFor="event_type">Event Name</label>
                    <div className="task-box">
                      {formData.event_type}
                    </div>
                  </div>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="control-label" htmlFor="users">Who is this event for?</label>
                      <div className="task-box">
                        {formData.user_list.map(item => (
                          <div>
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="row">
                  <div className="form-group col">
                    <label className="control-label" htmlFor="event_name">Event Name</label>
                    <div className="task-box">{formData.event_name}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label className="control-label" htmlFor="description">Description/Information</label>
                    <div className="task-box">{formData.description}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col col-md-6">
                    <label className="control-label" htmlFor="start_date">Start Date</label>
                    <div className="task-box">
                      {Moment(formData.start_date).format('MM/DD/YYYY h:mm A')}
                    </div>
                  </div>
                  <div className="col col-md-6">
                    <label className="control-label" htmlFor="end_date">End Date</label>
                    <div className="task-box">
                      {Moment(formData.end_date).format('MM/DD/YYYY h:mm A')}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col text-center">
                    <br />
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-secondary"
                    >
                      Back
                    </button>
                  </div>
                </div>
                
              </div>
            )}
          </div>
        </div>
      </CardWrapper>
    </div>
  )
}

export default EventForm