import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Moment from 'react-moment';
import HistoryIcon from 'mdi-react/HistoryIcon';

const History = ({history}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => setIsOpen(!isOpen);

  if (history === undefined || history === null || history.length === 0) return null;

  return (
    <>
      <button
        onClick={toggle}
        className='btn btn-primary btn-xs'
      >
        <HistoryIcon size={12} />
        {' '}
        Show History
      </button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>History</ModalHeader>
        <ModalBody>
          {history.map((item, index) => (
            <Row key={index}>
              <Col>
                <h4>
                  {item.status}
                </h4>
                {item.user}
                {' @ '}
                <Moment format="MM/DD/YYYY h:mm A">
                  {item.date}
                </Moment>
                <hr />
              </Col>
            </Row>
          ))}
        </ModalBody>
      </Modal>
    </>
  )
}

History.propTypes = {
  history: PropTypes.array.isRequired,
}

export default History;