import api from './init';
import returnError from './returnError';

export function getEventTypes() {
  const url = '/api/v1/events.json?show_event_types=1';
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getEvent(id) {
  const url = `/api/v1/events/${id}.json`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getEvents(data) {
  const url = `/api/v1/events.json?page=${data.page}&f=${data.userFilter}&past=${data.past}`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveEvent(data) {
  const url = '/api/v1/events.json';
  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function updateEvent(data) {
  const url = `/api/v1/events/${data.id}.json`;
  return api
    .patch(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteEvent(data) {
  const url = `/api/v1/events/${data.id}.json`;
  return api
    .delete(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}
