import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Badge,
} from 'reactstrap';

import LinkIcon from "mdi-react/LinkIcon";

import Moment from 'react-moment';

// css
import styles from './UrlRow.module.scss'

const UrlRow = ({ data, modalClicked }) => {
  const whatColor = status => {
    switch (status) {
      case 'approved':
        return 'success'
      case 'rejected':
        return 'danger'
      case 'restricted':
        return 'warning'
      default:
        return 'primary'
    }
  }

  return (
    <>
      <Row>
        <Col xs={12} md={2}>
          <div className={styles.bold}>{data.sku}</div>          
          <a href={data.url} target="_blank" rel="noreferrer">
            <LinkIcon size={16} />
            {' '}
            Url
          </a>
          <br />
          {data.status === "Active" ? <Badge color="success">Active</Badge> : <Badge color="danger">{data.status}</Badge>}
        </Col>
        {data.url_snapshots.map(item => (
          <Col xs={12} md={2} key={item.id}>
            <button
              className={styles.button}
              onClick={() => modalClicked(item)}
            >
              <Badge
                color={whatColor(item.status)}
                pill
                className={styles.badge}
              >
                {item.status}
              </Badge>
              <Moment
                format="MM/DD/YYYY"
                className={styles.date}
              >
                {item.date_capture}
              </Moment>
            </button>
          </Col>
        ))}
      </Row>
      <hr />
    </>
  )
}

export default UrlRow