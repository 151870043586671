import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  DropdownList,
  Multiselect
} from 'react-widgets'
import {
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Tooltip,
  UncontrolledTooltip,
  Badge
} from 'reactstrap'

// icons
import PlusIcon from 'mdi-react/PlusIcon'
import LoadingIcon from 'mdi-react/LoadingIcon'
import TrashCanIcon from 'mdi-react/TrashCanIcon'
import WrenchIcon from 'mdi-react/WrenchIcon'

// components

// api
import { getItems, itemPricingForCompany } from '../../api/item'

// utils
import { numberToCurrency, validAS400Id } from '../../libs/utils'

// css
import styles from './LineItems.module.scss'

// list
const qty = [...Array(101).keys()]

const LineItems = ({ companyId, updateData, clear, updateClear, lineItems, quoteStatus, as400Id }) => {
  const [loading, setLoading] = useState(true)
  // const [selectedProducts, setSelectedProducts] = useState([])
  const [products, setProducts] = useState([])
  const [items, setItems] = useState([])
  const [counter, setCounter] = useState(0)

  const [open, setOpen] = useState(false)
  const [itemIndex, setItemIndex] = useState(-1)
  const [item, setItem] = useState(null)
  const [discount, setDiscount] = useState(0)
  
  const [discountData, setDiscountData] = useState({
    discount: 0,
    discountOverride: 0,
    discountType: 'percent',
    confirmedOverride: false,
  })

  const [lineItemsUpdated, setLineItemsUpdated] = useState(false)

  const [selectedValue, setSelectedValue] = useState([])

  const [toolTipOpen, setToolTipOpen] = useState(false)

  useEffect(() => {
    getItems()
      .then(data => {
        setProducts(data.items)
        setLoading(false)
      })

    console.log('line items', lineItems.length)
    console.log('line items updated', lineItemsUpdated)
    if (lineItems.length > 0 && !lineItemsUpdated) {
      console.log('line items', lineItems)
      setItems(lineItems)
      setLineItemsUpdated(true)
    }
  }, [])

  useEffect(() => {
    if (clear) {
      console.log('clear line items') 
      setItems([])
      updateClear(false)
    }
  }, [clear])

  const showBlock = () => {
    if (quoteStatus === 'declined') { return false }
    if (quoteStatus === 'approved') { return false }
    return true
  }

  // actions

  const toggleToolTip = () => setToolTipOpen(!toolTipOpen)

  const updateDiscountData = (field, value) => {
    setDiscountData({...discountData, [field]: value })
  }

  const updateDiscountOverridePrice = (field, value) => {
    if (value === '') {
      setDiscountData({...discountData, [field]: item.original_price })
    } else if (isNaN(value)) {
      setDiscountData({...discountData, [field]: item.original_price })
    } else {
      setDiscountData({...discountData, [field]: parseFloat(value) })
    }
  }

  const showPriceAdjustmentModal = (event, index) => {
    event.preventDefault()
    console.log('showPriceAdjustmentModal', index)
    const obj = items[index]
    console.log('obj', obj)

    if (validAS400Id(as400Id)) {
      itemPricingForCompany({ companyId: companyId, itemId: obj.id })
        .then((resData) => {
          obj.original_price = resData.pricing.price
          setItem(obj)
        })
    } else {
      setItem(obj)
    }

    setItemIndex(index)
    // setDiscount(obj.discount)
    setDiscountData({
      discount: obj.discount_type === 'percent' ? obj.discount : 0,
      discountOverride: obj.discount_type === 'override' ? obj.price : obj.original_price,
      discountType: obj.discount_type ? obj.discount_type : 'percent',
      confirmedOverride: false,
    })
    toggle()
  }

  const toggle = () => {
    setOpen(!open)
  }

  const updateDiscountField = (event) => {
    let max = findMaxRange()
    let value = parseFloat(event.target.value)
    if (isNaN(value)) { value = 0 }
    if (value >= max) { value = max }
    // setDiscount(value)
    updateDiscountOverridePrice('discount', value)
  }

  const updateLineItemPrice = (event) => {
    event.preventDefault()

    console.log('item', item)
    console.log('discountType', discountData.discountType)

    let newPrice
    let discount = 0
    let discountType

    if (discountData.discountType === 'override') {
      newPrice = discountData.discountOverride
      discountType = discountData.discountType
    } else {
      const discountPrice = item.original_price * (discountData.discount / 100) 
      newPrice = item.original_price - discountPrice
      discount = discountData.discount
      discountType = "percent"
    }

    const qty = item.quantity

    console.log('newPrice', newPrice)
    console.log('discount', discount)

    items[itemIndex] = {
      ...items[itemIndex],
      price: newPrice,
      total_price: newPrice * qty,
      display_price: numberToCurrency(newPrice * qty),
      discount_applied: true,
      discount: discount,
      discount_type: discountType,
    }
    // console.log('items', items)
    setItems(items)
    setCounter(counter + 1)
    updateData(items)
    setItem(null)
    setItemIndex(-1)
    setDiscountData({
      discount: 0,
      discountOverride: 0,
      discountType: 'percent',
      confirmedOverride: false,
    })
    toggle()
  }

  const updateLineItemQuantity = (index, quantity) => {
    if (quantity === 0) {
      deleteLineItem(null, index)
      return
    }
    items[index] = {
      ...items[index], quantity: quantity,
      total_price: items[index].price * quantity,
      display_price: numberToCurrency(items[index].price * quantity)
    }
    // console.log('items', items)
    setItems(items)
    setCounter(counter + 1)
    updateData(items)
  }

  const deleteLineItem = (e, index) => {
    if (e !== null) {
      e.preventDefault()
    }
    console.log(`index:`, index)
    const list = items.slice(0, index).concat(items.slice(index + 1))
    setItems(list)
    setCounter(counter + 1)
    updateData(list)
  }

  const addSelectedProducts = (value) => {
    // setLoading(true)
    let list = items
    
    itemPricingForCompany({companyId: companyId, itemId: value[0].id})
      .then((resData) => {
        let item = { ...value[0], 
          quantity: 1,
          price: resData.pricing.price,
          total_price: resData.pricing.price,
          msrp: resData.pricing.msrp,
          discount: 0,
          original_price: resData.pricing.price,
          line_item_id: null,
        }
        list.push(item)
        setItems(list)
        // setLoading(false)
        setCounter(counter + 1)
        updateData(items)
      })
  }

  const findMaxRange = () => {
    if (validAS400Id(as400Id)) {
      return 5
    }
    return 48
  }

  // render
  const displayNewPrice = () => {
    if (item === null) { return null }

    const price = item.original_price
    const discountPrice = price * (discountData.discount / 100)
    const newPrice = numberToCurrency(price - discountPrice)
    return (
      <>
        <Col>
          {'- '}
          {numberToCurrency(discountPrice)}
        </Col>
        <Col>
          {'= '}
          <strong>
            {newPrice}
          </strong>
        </Col>
      </>
    )
  }
  
  const renderPriceAdjustmentModal = () => {
    if (item === null) { return null }
    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        size="lg"
        centered
        backdrop="static"
      >
        <ModalHeader>
          Price Adjustment
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} md={8}>
              <h4>{item.name}</h4>
            </Col>
            <Col xs={12} md={4}>
              <div className="text-end">
                <FormGroup
                  switch
                  inline
                >
                  <Input
                    type="switch"
                    checked={discountData.discountType === 'override'}
                    onChange={e => updateDiscountData('discountType', e.target.checked ? 'override' : 'percent')}
                  />
                  Override?
                </FormGroup>
                <span id="overrideToolTip" className={styles.tip}>?</span>
                <Tooltip
                  placement="top"
                  isOpen={toolTipOpen}
                  target="overrideToolTip"
                  toggle={toggleToolTip}
                >
                  This will allow for Price Override (Custom Price).
                </Tooltip>
              </div>
            </Col>
          </Row>
          <hr />
          {discountData.discountType === 'override' ? (
            <>
              <Row className="form-group">
                <Col xs={12} md={3}>Price:</Col>
                <Col xs={12} md={9}>
                  {numberToCurrency(item.original_price)}
                </Col>
              </Row>
              <Row className="form-group">
                <Col xs={12} md={3}>Price Override:</Col>
                <Col xs={12} md={4}>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      type="number"
                      min={0}
                      step={0.01}
                      className="form-control"
                      value={discountData.discountOverride}
                      onChange={e => updateDiscountOverridePrice('discountOverride', e.target.value)}
                      disabled={discountData.confirmedOverride}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} md={5} className='text-end'>
                  Difference:
                  {' '}
                  {numberToCurrency(discountData.discountOverride - item.original_price)}
                  {' '}
                  ({((discountData.discountOverride - item.original_price) / item.original_price * 100).toFixed(2)}%)
                </Col>
              </Row>
              {item.original_price !== discountData.discountOverride && (
                <Row>
                  <Col>
                    <Alert color="warning" className="mt-4">
                      <Row>
                        <Col xs={12} md={8}>
                          <FormGroup
                            check
                            inline
                          >
                            <Input
                              type="checkbox"
                              checked={discountData.confirmedOverride}
                              onClick={e => updateDiscountData('confirmedOverride', e.target.checked)}
                            />
                            <Label check>
                              You have reviewed the price adjustment?:
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <span className={styles.priceAdjustmentDisplay}>
                            {numberToCurrency(discountData.discountOverride)}
                          </span>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <Row>
                <Col xs={3}>Discount:</Col>
                <Col xs={5}>
                  0%
                  {' '}
                  <input
                    type="range"
                    min={0}
                    max={findMaxRange()}
                    step={0.25}
                    value={discountData.discount}
                    onChange={e => updateDiscountField(e)}
                  />
                  {' '}
                  {findMaxRange()}%
                </Col>
                <Col xs={2}>
                  <input
                    type="number"
                    name="discount_percent"
                    step={0.01}
                    className="form-control"
                    min={0}
                    max={findMaxRange()}
                    value={discountData.discount}
                    onChange={e => updateDiscountField(e)}
                  />
                </Col>
                {/* <Col xs={1}>%</Col> */}
              </Row>
              <hr />
              <h5>Adjusted Price</h5>
              <Row>
                <Col>{numberToCurrency(item.original_price)}</Col>
                {displayNewPrice()}
              </Row>
            </>
          )}
          
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={(e) => updateLineItemPrice(e)}
            disabled={( discountData.discountType === 'override' && !discountData.confirmedOverride)}
          >
            Set Price
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  const renderDiscount = (item) => {
    if (item.discount_applied) {
      return (
        <div className={styles.discount}>
          Adjustment Applied:
          {' '}
          {item.discount_type === 'override' ? (
            <>
              Override
            </>
          ) : (
            <>
              {item.discount}%
            </>
          )}
          {' '}
          ({numberToCurrency(item.original_price)})
        </div>
      )
    }
    return null
  }

  const renderMsrp = (item) => {
    if (item.price === item.msrp) { return null }
    return (
      <div>
        MSRP:
        {' '}
        <span className={styles.msrp}>
          {numberToCurrency(item.msrp)}
        </span>
      </div>
    )
  }

  const renderInventory = (item) => {
    if (quoteStatus !== 'open' && quoteStatus !== 'draft') { return null }

    if (products.length === 0) {
      return (
        <LoadingIcon
          size={16}
          className={styles.rotate}
        />
      )
    }

    try {    
      const { on_hand, allocated, available } = products.filter(p => p.id === item.id)[0].inventory
      const badgeColor = (available > 0) ? 'primary' : 'danger'

      return (
        <>
          <Badge
            color={badgeColor}
            pill
            id={`inventory-${item.id}`}
          >
            Inventory:
            {' '}
            {available}
          </Badge>
          <UncontrolledTooltip
            placement="right"
            target={`inventory-${item.id}`}
            trigger="hover"
          >
            <div className={styles.toolTipInventory}>
              <Row>
                <Col xs={7}>On Hand</Col>
                <Col xs={5}>{on_hand}</Col>
              </Row>
              <Row>
                <Col xs={7}>Allocated</Col>
                <Col xs={5}>{allocated}</Col>
              </Row>
              <Row className={styles.toolTipInventoryLine}>
                <Col xs={7}>Available</Col>
                <Col xs={5}>{available}</Col>
              </Row>
            </div>
          </UncontrolledTooltip>
        </>
      )
    } catch (e) {
      return null
    }
  }

  return (
    <>
      {items.map((item, idx) => 
        <div key={idx}>
          <Row className="mb-2">
            <Col xs={12} md={4}>
              {item.sku}
              {' - '}
              {item.name}
              <br />
              <br />
              {renderInventory(item)}
            </Col>
            <Col xs={12} md={2}>
              {showBlock() ? (
                <DropdownList
                  data={qty}
                  value={item.quantity}
                  onChange={value => updateLineItemQuantity(idx, value)}
                />
              ) : (
                <>
                  {item.quantity}
                </>
              )}
            </Col>
            <Col xs={12} md={3} className="text-end">
              {numberToCurrency(item.price)}
              {' '}
              {showBlock() && (
                <button
                  className={styles.button}
                  onClick={(e) => showPriceAdjustmentModal(e, idx)}
                >
                  <WrenchIcon size={12} />
                </button>
              )}
              {renderDiscount(item)}
              {renderMsrp(item)}
            </Col>
            <Col xs={12} md={2} className="text-end">{numberToCurrency(item.total_price)}</Col>
            <Col xs={12} md={1}>
              {showBlock() && (
                <button
                  onClick={e => deleteLineItem(e, idx)}
                  type="button"
                  className="btn btn-danger"
                >
                  <TrashCanIcon size={16} />
                </button>
              )}
            </Col>
          </Row>
          <hr />
        </div>
      )}
      {showBlock() && (
        <Row>
          <Col xs={11} md={5}>
            {loading && (
              <LoadingIcon
                size={16}
                className={styles.rotate}
              />
            )}
            <Multiselect
              dropUp
              disabled={loading}
              data={products}
              textField="full_name"
              dataKey="id"
              filter="contains"
              value={selectedValue}
              groupBy="item_class"
              placeholder='+ Select Products (SKU or Name)'
              onChange={value => addSelectedProducts(value)}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={5}>&nbsp;</Col>
        <Col xs={12} md={2}>&nbsp;</Col>
        <Col xs={12} md={2} className="text-end">Total:</Col>
        <Col xs={12} md={2} className="text-end">
          {numberToCurrency(items.reduce((total, item) => total + item.total_price, 0))}
        </Col>
      </Row>
      {renderPriceAdjustmentModal()}
    </>
  )
}

PropTypes.propTypes = {
  lineItems: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  updateClear: PropTypes.func.isRequired
}

export default LineItems
