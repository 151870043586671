import api from './init';
import returnError from './returnError';

// import Cookies  from 'universal-cookie';
// const cookies = new Cookies();

export function getAlerts(data) {
  let url = '/api/v1/alerts';
  if (data !== null) {
    url += `?page=${data.currentPage}`;
  }

  return api
    .get(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteAlert() {
  return null;
}
