import api from './init';
import returnError from './returnError';

export function listClassifications(data) {
  const url = '/api/v1/customer_classifications.json';
  return api
    .get(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}