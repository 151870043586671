// add/edit quote
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import {
  DropdownList,
  Multiselect
} from 'react-widgets'

// icons
import PlusIcon from 'mdi-react/PlusIcon'

// components
import PageHeader from '../../components/PageHeader'
import PageSubHeader from '../../components/PageSubHeader'
import CardWrapper from '../../components/CardWrapper'
import AlertBlock from '../../components/AlertBlock'

// quote components
import LineItemsReadOnly from '../../components/quote/LineItemsReadOnly'
import Address from '../../components/quote/Address'

// utils
import {
  paymentOptionsList,
  splitLines,
  numberToCurrency,
  currentId,
  inValidRole
} from '../../libs/utils'

// styles
import styles from './Quote.module.scss'

// api
import { getCompany } from '../../api/company'
import { getItems } from '../../api/item'
import { fullUserListByRole } from '../../api/user'
import { listCompaniesForDropDown } from '../../api/company';
import {
  getQuote,
  cloneQuote
} from '../../api/quote';

const ExpiredQuote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = 'Expired'

  // state
  const [loading, setLoading] = useState(true)
  const [quote, setQuote] = useState({
    company: null,
    contact: null,
    status: 'draft',
    salesRep: null,
    segment: null,
    createdBy: null,
    linesItems: [],
    billingAddress: {},
    shippingAddress: {},
    messages: [],
    message: "",
    shippingTotal: 0,
    willcall: false,
    taxTotal: 0,
    grandTotal: 0,
    quote_number: null,
    doCalculate: 0
  })
  const [steps, setSteps] = useState({
    company: true,
    contact: true,
    salesRep: true,
    lineItems: true,
    payment: true,
  })
  const [company, setCompany] = useState(null)
  const [addresses, setAddresses] = useState([])
  // const [contacts, setContacts] = useState([])
  const [contact, setContact] = useState(null)
  // const [salesReps, setSalesReps] = useState([])
  const [salesRep, setSalesRep] = useState(null)
  const [lineItems, setLineItems] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [message, setMessage] = useState("")

  const [companies, setCompanies] = useState([])
  const [relatedCompany, setRelatedCompany] = useState(null)

  const [clearLineItems, setClearLineItems] = useState(false)
  const [forceUpdateOfLineItems, setForceUpdateOfLineItems] = useState(false)
  const [valid, setValid] = useState(false)

  const [isQuoteReviewOpen, setIsQuoteReviewOpen] = useState(false)

  const [c, setC] = useState(0)

  useEffect(() => {
    console.log('id', id)

    if (id !== undefined) {

      getQuote(id)
        .then(d => {
          console.log('quote', d.quote)
          setQuote(prevQuote => ({
            ...prevQuote,
            status: d.quote.status,
            quote_number: d.quote.quote_number,
            billingAddress: d.quote.billingAddress,
            shippingAddress: d.quote.shippingAddress,
            messages: d.quote.messages,
            message: d.quote.message,
            history: d.quote.history,
            shippingTotal: d.quote.shippingTotal,
            taxTotal: d.quote.taxTotal,
            willcall: d.quote.willcall,
          }))
          // setCompany(d.quote.company)
          setContact(d.quote.contact)
          setSalesRep(d.quote.sales_rep)
          setLineItems(d.quote.line_items)

          getCompany(d.quote.company.id)
            .then(d1 => {
              setCompany(d1)
              setAddresses(d1.addresses)
              setPaymentOptions(parsePaymentOptions(d.quote.payment_options, d1))
              setLoading(false)
            })
      })
    }
  }, [id])

  const parsePaymentOptions = (list, comp) => {
    const options = paymentOptionsList(comp)
    console.log('options', options)
    const data = list.map((item) => {
      return options.find((option) => option.value === item)
    })
    console.log('data', data)
    return data
  }

  // actions
  const loadCompanyData = (companyId) => {
    const d = Promise.all([getCompany(companyId)])

    setCompany(d)
    setAddresses(d.addresses)
    
    setPaymentOptions(parsePaymentOptions(quote.payment_options))

    // getCompany(companyId)
    //   .then(d => {
    //     // setContacts(d.contacts)
    //     Promise.all([
    //       setCompany(d),
    //       setAddresses(d.addresses)
    //     ])
    //     setPaymentOptions(parsePaymentOptions(quote.payment_options))
    //   })
  }

  const cloneQuoteAction = () => {
    cloneQuote(id)
      .then(d => {
        navigate(`/quote/${d.quoteId}`)
      })
  }

  // renders
  const renderCompanyBlock = () => {
    return (
      <CardWrapper
        key="companyWrapper"
        id="companyWrapper"
        header="Company"
      >
        {renderCompanyInfo()}
        {renderRelatedCompanyBlock()}
      </CardWrapper>
    )
  }

  const renderCompanyInfo = () => {
    if (company === null) { return null }
    return (
      <>
        <p>
          <strong>{company.name}</strong>
          {(company.as400_id !== '' && company.as400_id !== null && company.as400_id !== '-1') && ` (${company.as400_id})`}
          <br />
          {company.address}
          <br />
          {company.city},
          {' '}
          {company.state}
          {' '}
          {company.zip_code}
          <br />
          {company.telephone}
        </p>
      </>
    )
  }

  const renderContactBlock = () => {
    return (
      <CardWrapper
        key="contactWrapper"
        id="contactWrapper"
        header="Contact"
        className={renderClassForSection('contact')}
      >
        {renderContactInfo()}
      </CardWrapper>
    )
  }

  const renderContactInfo = () => {
    if (!steps.contact || contact === null) { return null }

    return (
      <p>
        <strong>
          {contact.first_name}
          {' '}
          {contact.last_name}
        </strong>
        <br />
        {contact.title}
        <br />
        {contact.email}
        <br />
        {contact.telephone}
        {' '}
        {contact.telephone_ext}
      </p>
    )
  }

  const renderSalesRepBlock = () => {
    return (
      <CardWrapper
        key="salesRepWrapper"
        id="salesRepWrapper"
        header="Account Executive"
        className={renderClassForSection('salesRep')}
      >
        {renderSalesRep()}
      </CardWrapper>
    )
  }

  const renderSalesRep = () => {
    if (company === null) { return null }
    if (salesRep !== null) {
      return (
        <p>
          <strong>{salesRep.name}</strong>
          <br />
          {salesRep.email}
        </p>
      )
    }
  }

  // related company for those w/o AS400 ID value (Cash Accounts mainly)
  const renderRelatedCompanyBlock = () => {
    if (company === null) { return null }
    if (company.as400_id !== null || company.as400_id === '-1') { return null }
    if (company.as400_id > 0) { return null }
    return null
  }

  const renderClassForSection = (section) => {
    if (steps[section]) {
      return styles.active
    }
    return styles.inactive
  }

  const selectStatusColor = () => {
    switch (quote.status) {
      case 'draft':
        return 'warning'
      case 'approved':
        return 'success'
      case 'declined':
        return 'danger'
      case 'expired':
        return 'warning'
      default:
        return 'info'
    }
  }

  return (
    <>
      <PageHeader title={`Quote: ${pageTitle}`} />

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.quoteFormBackButton}>

            <button
              className="btn btn-danger"
              onClick={() => cloneQuoteAction()}
            >
              Clone this Expired Quote
            </button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Link to="/quote" className="btn btn-primary">
              Back to Quotes
            </Link>
          </div>
          {/* <Notice /> */}
          <Row>
            <Col>
              <Alert color={selectStatusColor()}>
                <Row>
                  <Col md={6}>
                    Quote Status:
                    {' '}
                    {quote.status.toUpperCase()}
                  </Col>
                  <Col md={6} className='text-end'>
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md={6}>{renderCompanyBlock()}</Col>
            <Col md={6}>
              {renderContactBlock()}
              {renderSalesRepBlock()}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <CardWrapper header="Billing Address">
                <Address
                  address={quote.billingAddress}
                />
              </CardWrapper>
            </Col>
            <Col md={6}>
              <CardWrapper header="Shipping Address">
                <Address
                  address={quote.shippingAddress}
                />
              </CardWrapper>
            </Col>
          </Row>

          <CardWrapper
            key="lineItemsWrapper"
            id="lineItemsWrapper"
            header="Product/Line Items"
            className={renderClassForSection('lineItems')}
          >
            {company !== null && (
              <LineItemsReadOnly
                companyId={company.id}
                lineItems={lineItems}
              />
            )}
            <Row>
              <Col xs={12}><hr /></Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>&nbsp;</Col>
              <Col xs={12} md={2}>&nbsp;</Col>
              <Col xs={12} md={2} className="text-end">Shipping:</Col>
              <Col xs={12} md={2} className="text-end">
                {numberToCurrency(quote.shippingTotal)}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>&nbsp;</Col>
              <Col xs={12} md={2}>&nbsp;</Col>
              <Col xs={12} md={2} className="text-end">Tax:</Col>
              <Col xs={12} md={2} className="text-end">
                {numberToCurrency(quote.taxTotal)}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>&nbsp;</Col>
              <Col xs={12} md={2}>&nbsp;</Col>
              <Col xs={12} md={2} className="text-end">Grand Total:</Col>
              <Col xs={12} md={2} className="text-end">
                {numberToCurrency(quote.grandTotal)}
              </Col>
            </Row>
          </CardWrapper>

          <Row>
            <Col md={8}>
              <CardWrapper
                key="paymentWrapper"
                id="paymentWrapper"
                header="Payment & Message"
                className={renderClassForSection('payment')}
              >
                {steps.salesRep && (
                  <Row>
                    <Col md={2}>
                      Payment
                    </Col>
                    <Col md={4}>
                      <p className={styles.terms}>
                        {paymentOptions.map(option => (
                          <>
                            {option.label}
                            <br />
                          </>
                        ))}
                      </p>
                    </Col>
                    <Col md={6}>
                      {quote.message !== undefined && (
                        <>
                          <h5>Message</h5>
                          {splitLines(quote.message)}
                        </>
                      )}
                    </Col>
                  </Row>
                )}
              </CardWrapper>
            </Col>
            <Col md={4} className="text-end">
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ExpiredQuote;