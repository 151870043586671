import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Users from './list';
import UserForm from './form';

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="new" element={<UserForm />} />
      <Route path=":id" element={<UserForm />} />
      <Route path="" element={<Users />} />
    </Routes>
  );
}

export default UserRoutes;
