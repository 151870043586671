import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';

// controls
import CardWrapper from '../../../components/CardWrapper';
import PageHeader from '../../../components/PageHeader';
import Item from '../../../components/admin/taskTemplates/item';

// api
import { getTaskTemplates, saveTaskTemplate } from '../../../api/callLog';

export default function TaskTemplates() {
  const [toggle, setToggle] = useState(false);
  const [list, setList] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getTaskTemplates()
      .then((res) => {
        console.log(res);
        setList(res.task_templates);
      });
  }, []);

  useEffect(() => {
    if (!toggle) {
      setName('');
      setDescription('');
      setId(null);
      setErrors([]);
    }
  }, [toggle]);

  const add = (event) => {
    setToggle(!toggle);
  };

  const editItem = (taskTemplateId) => {
    let ele = document.getElementById(taskTemplateId);
    setName(ele.childNodes[0].innerHTML);
    setDescription(ele.childNodes[1].innerHTML);
    setId(taskTemplateId);
    setToggle(!toggle);
  };

  const save = () => {
    const data = {
      task_template: {
        name,
        description,
      },
    };
    saveTaskTemplate(id, data)
      .then((res) => {
        if (res.errors.length === 0) {
          setList(res.task_templates);
          setToggle(!toggle);
        } else {
          setErrors(res.errors);
        }
      });
  };

  const updateFormFields = (event) => {
    const { target } = event;
    const { value } = target;
    if (target.name === 'name') {
      setName(value);
    } else {
      setDescription(value);
    }
  };

  const renderList = () => {
    console.log('rendering the list');
    return list.map(item => (
      <Item
        taskTemplate={item}
        doEdit={editItem}
        key={item.id}
      />
    ));
  };

  const renderErrors = () => {
    if (errors.length === 0) { return null; }
    return (
      <Alert color="danger">
        <p>Please correct the following errors:</p>
        {errors.map(item => (
          <ul>
            <li>{item}</li>
          </ul>
        ))}
      </Alert>
    );
  };

  return (
    <>
      <PageHeader title="Task Templates" />
      <Row className="mb-2">
        <Col className="text-right">
          <button
            type="button"
            className="btn btn-primary"
            onClick={e => add(e)}
          >
            Add
          </button>
        </Col>
      </Row>
      <CardWrapper>
        <Row>
          <Col md={3}><strong>Name</strong></Col>
          <Col md={5}><strong>Description</strong></Col>
          <Col md={3}><strong>Last Updated</strong></Col>
          <Col md={1}><strong>Edit</strong></Col>
        </Row>
        <hr />
        {renderList()}
      </CardWrapper>
      <Modal isOpen={toggle}>
        <ModalHeader onClick={e => add(e)}>Add/Edit Task Template</ModalHeader>
        <ModalBody>
          {renderErrors()}
          <Row>
            <Col className="form-group">
              <input
                name="name"
                className="form-control"
                type="text"
                value={name}
                placeholder="Name"
                onChange={(event) => updateFormFields(event)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="form-group">
              <textarea
                name="description"
                className="form-control"
                placeholder="Text Description..."
                onChange={(event) => updateFormFields(event)}
                rows={8}
              >
                {description}
              </textarea>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => save(e)}
              >
                Save
              </button>
              &nbsp;
              <button
                type="button"
                onClick={e => add(e)}
                className="btn btn-secondary"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
}
