import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Row, Col } from 'reactstrap';

class ExampleBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      message: 'Error occured, has been reported.',
    };
  }

  componentDidMount() {
    if (this.props.message !== undefined) {
      this.setState({
        message: this.props.message
      });
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { message } = this.state;

    if (this.state.hasError) {
      return (
        <Row>
          <Col>
            <span className="label label-danger">
              {message}
            </span>
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}

export default ExampleBoundary
