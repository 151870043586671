import api from './init';
import returnError from './returnError';

export function search(data) {
  const url = `/api/v1/search/${data}`;
  
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function searchCompany(data) {
  return null;
}