import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';

import TaskForm from './TaskForm';
import TaskList from './TaskList';

const Task = () => {
  return (
    <>
      <Helmet>
        <title>The Hub - Tasks</title>
      </Helmet>
      <Routes>
        <Route path="new" element={<TaskForm isNew={true} />} />
        <Route path=":id" element={<TaskForm isNew={false} />} />
        <Route path="" element={<TaskList />} />
      </Routes>
    </>
  );
}

export default Task;
