import React from 'react';
import { Row, Col } from 'reactstrap';

export default function SalesHistoryTable({report}) {


  if (report === undefined) {
    return null;
  }

  const productRow = (product) => {
    return (
      <tr>
        <td>
          <strong>{product.sku}</strong>
          {' '}
          {product.item}
        </td>
        {report.requestedYears.map(year => (
          productInfo(product, year)
        ))}
      </tr>
    );
  };

  const productInfo = (product, year) => {
    try {
      const y = report.years.filter(j => j.year === year)[0];
      const p = y.products.filter(x => x.productId === product.productId)[0];
      return (
        <td>{p.yearSalesDisplay} ({p.yearQuantityTotal})</td>
      )
    }
    catch (e) {
      console.log(e);
      return <td>$0.00</td>;
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h3>Year</h3>
        </Col>
      </Row>      
      <table className="table table-striped">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {report.requestedYears.map(year => (<th>{year}</th>))}
          </tr>
        </thead>
        <tbody>
          {report.products.map(item => (
            productRow(item)
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </>
  );
}
