import api from './init';
import returnError from './returnError';

export function getMapList({ page, company }) {
  const url = `/api/v1/maps?page=${page}&company_id=${company === null ? '' : company.id}`
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function companyList() {
  const url = `/api/v1/maps/companies.json`
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getMap({ id, extended = 0 }) {
  const url = `/api/v1/maps/${id}?extended=${extended}`
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveMap({ id, data }) {
  let url = `/api/v1/maps`
  let method = "post"
  if (id !== null) {
    url = `/api/v1/maps/${id}`
    method = "put"
  }

  return api
    [method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteMap({ id }) {
  const url = `/api/v1/maps/${id}`
  return api.delete(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// get the list of items that need to be reviewed based on the map id
export function getReviews({ id }) {
  const url = `/api/v1/maps/${id}/reviews`
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// get the REPORT for the Map ID
export function getREport({ id }) {
  const url = `/api/v1/maps/${id}/report`
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteUrl({ id, urlId }) {
  const url = `/api/v1/maps/${id}/urls/${urlId}`
  return api.delete(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function importFile({ id, data }) {
  const url = `/api/v1/maps/${id}/import_urls?upload=file`;

  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function importUrls({ id, data }) {
  const url = `/api/v1/maps/${id}/import_urls`;

  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function updateUrlSnapshot({ mapId, urlSnapshot }) {
  const url = `/api/v1/maps/${mapId}/url_snapshots/${urlSnapshot.id}`;

  return api
    .put(url, { url_snapshot: urlSnapshot }, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function exportRequest({ mapId, format }) {
  const url = `/api/v1/maps/${mapId}/export?report_type=${format}`
  return api
    .post(url, {}, { withCredentials: true })
    .then((res) => {
        return res.data;
      })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}