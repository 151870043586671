import React from 'react';
import StarIcon from 'mdi-react/StarIcon';

export function renderUserIcon(user) {
  if (user) {
    return (
      <img className="img-circle" src={user.avatar} alt={user.name} />
    );
  }
  return null;
}

export function stars() {
  return [
    { value: 1, name: '⋆ Prospect' },
    { value: 2, name: '⋆ ⋆ Customer' },
    { value: 3, name: '⋆ ⋆ ⋆ Top Customer' },
  ];
}

export function renderStars(stars) {
  if (stars === 0) { return null; }
  const a = new Array();
  for (let i = 0; i < stars; i++) {
    a.push(i);
  }
  let starHtml = a.map((item, idx) => (
    <StarIcon size={24} key={idx} />
  ));
  return (
    [
      // <br />,
      starHtml,
    ]
  );
}
