import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// screen
import MapForm from './form'
import MapList from './list'
import MapReport from './report'
import MapReview from './review'

const Map = () => {
  return (
    <>
      <Helmet>
        <title>The Hub - MAP</title>
      </Helmet>
      <Routes>
        <Route path="new" element={<MapForm isNew={true} />} />
        <Route path=":id" element={<MapForm isNew={false} />} />
        <Route path=":id/report" element={<MapReport />} />
        <Route path=":id/review" element={<MapReview />} />
        <Route path="" element={<MapList />} />
      </Routes>
    </>
  )
}

export default Map
