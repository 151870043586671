import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import CardWrapper from '../CardWrapper';
import Loading from '../Loading';

// api
import { getSalesGoal } from '../../api/report';

// styles
import styles from './salesGoal.module.scss';

const SalesGoal = ({ userID, segment, updateStatus }) => {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(true);

  const [barPercentage, setBarPercentage] = useState(0);
  const [goal, setGoal] = useState(0);
  const [sales, setSales] = useState(0);
  const [display, setDisplay] = useState({ goal: '$0', actual: '$0' });

  const getBarPercentage = () => {
    if (userID === undefined && userID === null) { return null; }

    getSalesGoal(userID, segment).then((res) => {
      setBarPercentage(res.data.percentage);
      setSales(res.data.actual);
      setGoal(res.data.goal);
      setDisplay(res.data.display);
      setLoading(false);
      updateStatus('goal', false);
    });
  };

  useEffect(() => {
    if (userID === undefined && userID === null) { return null; }

    getBarPercentage();
  }, [userID, segment]);

  return (
    <CardWrapper>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col>
            <h4 className="card-title">Sales Goal for {currentYear}</h4>
            <Row>
              <Col xs={12} md={10}>
                <div className="progress m-t-20">
                  <div className="progress-bar bg-info progress-bar-striped" role="progressbar" style={{ width: barPercentage, height: '28px' }} aria-valuenow={barPercentage} aria-valuemin="0" aria-valuemax="100">
                  </div>
                  <span className={styles.salesGoalText}>
                    {display.actual}
                  </span>
                </div>
              </Col>
              <Col xs={12} md={2}>
                Goal:
                <br />
                <strong>
                  {display.goal}
                </strong>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </CardWrapper>
  );
};

SalesGoal.propTypes = {
  userID: PropTypes.string,
  segment: PropTypes.any,
  updateStatus: PropTypes.func,
};

export default SalesGoal;
