import React from 'react';
import { Alert } from "reactstrap";

const Loading = () => {
  return (
    <Alert color="success">
      Loading
    </Alert>
  )
}

export default Loading