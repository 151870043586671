import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { DropdownList } from 'react-widgets';

import PageHeader from '../../../components/PageHeader';
// import PageSubHeader from '../shared/PageSubHeader';
import CardWrapper from '../../../components/CardWrapper';
import Error from '../../../components/Error';


import api from '../../../api/init';
import { listUserRoles, listUsers } from '../../../api/user';

import User from './user';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({ name: 'All', id: ''});

  useEffect(() => {
    listUsers({ currentPage, list: 'list', role: role.id })
      .then((data) => {
        setUsers(data.users);
        setCurrentPage(data.details.current_page);
        setTotalPages(data.details.total_pages);
        setTotalRecords(data.details.total_records);
      });
  }, [currentPage, role]);

  useEffect(() => {
    listUserRoles()
      .then((data) => {
        setRoles(data);
      })
  }, [])

  const renderUserList = () => {
    return users.map(user => (
      <User user={user} />
    ));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPager = () => {
    return (
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={20}
        totalItemsCount={totalRecords}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        innerClass={'pagination'}
        itemClass={'paginate_button page-item'}
        linkClass={'page-link'}
      />
    );
  };

  return (
    <>
      <PageHeader title="Users" />
      <CardWrapper>
        <Row>
          <Col>
            Filter By Role:
            <DropdownList
              data={roles}
              textField="name"
              valueField="id"
              defaultValue="All"
              value={role}
              onChange={value => setRole(value)}
            />
          </Col>
          <Col className="text-right">
            <Link to="/admin/users/new" className="btn btn-primary">New User</Link>
          </Col>
        </Row>
        <hr />
        <Row className="mb-4">
          <Col md={2}>&nbsp;</Col>
          <Col md={4}>Name</Col>
          <Col md={4}>Role</Col>
          <Col md={2}>Actions</Col>
        </Row>
        {renderUserList()}
        {renderPager()}
      </CardWrapper>
    </>
  );
}
