import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Alert
} from 'reactstrap'
import { DropdownList } from 'react-widgets'

// api
import { getCompanySources } from '../../api/company'

// styles
import styles from './CompanySourceDropdown.module.scss'

const CompanySourceDropdown = ({ updateCompanySourceField }) => {
  const [companySources, setCompanySources] = useState([])
  const [source, setSource] = useState('')
  const [other, setOther] = useState('')
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    getCompanySources()
      .then((res) => {
        setCompanySources(addOther(res.company_sources));
      })
  }, []);

  const addOther = (list) => {
    list.push({ id: 'other', name: 'Other' })
    return list
  }

  // action

  const handleSourceChange = (value) => {
    setOther('')
    setSource(value)
    
    if (value.id === 'other') {
      setTimeout(() => {
        updateCompanySourceField({id: value.id, name: ''})
        document.getElementById('other').focus()
      }, 500)
    } else {
      updateCompanySourceField({id: value.id, name: value.name})
      setShowMessage(false)
    }
  }

  const handleOtherChange = (e) => {
    setOther(e.target.value)
    updateCompanySourceField({ id: 'other', name: e.target.value })
  }

  const handleOtherBlur = (e) => {
    if (e.target.value === '') {
      setShowMessage(true)
    }
  }

  return (
    <Alert color="secondary">
      {showMessage && (
        <Badge color="danger">
          If OTHER is selected, you must input a value.
        </Badge>
      )}
      <Row>
        <Col>
          <div className="form-group">
            <label forHtml="company_source_id" className="control-label">
              Source
              <span className={styles.info} id="company_source_tip">
                ?
              </span>
              <UncontrolledTooltip
                placement="right"
                target="company_source_tip"
                trigger="hover"
              >
                Where you found the company.
              </UncontrolledTooltip>
            </label>
            <DropdownList
              data={companySources}
              textField="name"
              valueField="id"
              value={source}
              onChange={(value) => handleSourceChange(value)}
            />
          </div>
        </Col>
      </Row>
      {source.id === 'other' && (
        <Row>
          <Col>
            <div className="form-group">
              <input
                type="text"
                name="other"
                id="other"
                value={other}
                onChange={(e) => handleOtherChange(e)}
                onBlur={(e) => handleOtherBlur(e)}
                className="form-control"
                placeholder="Other..." 
              />
            </div>
          </Col>
        </Row>
      )}
    </Alert>
  )
}

export default CompanySourceDropdown