import React from 'react';
import { Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { DropdownList } from 'react-widgets';

import CardWrapper from '../../../components/CardWrapper';
import PageHeader from '../../../components/PageHeader';
import RefreshIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';

import { getActivityLogs } from '../../../api/activity_log';

import ActivityLogItem from './activity_log_item';

export default class ActivityLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      totalRecords: 0,
      logs: [],
      loading: true,
      users: [],
      selectedUser: {
        id: '',
        name: 'All',
      },
    };
  }

  componentDidMount() {
    this.loadLogs();
  }

  loadLogs() {
    this.setState({
      loading: true
    });
    const { currentPage, selectedUser } = this.state;
    let userId = null;
    if (selectedUser !== null) {
      userId = selectedUser.id;
    }
    getActivityLogs({ page: currentPage, user: userId })
      .then((data) => {
        console.log(data);
        let users = data.user_list;
        users.unshift({ id: '', name: 'ALL' });
        this.setState({
          logs: data.activities,
          users: users,
          loading: false,
          totalRecords: data.details.total_records,
          currentPage: data.details.current_page,
        });
      });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.loadLogs();
    });
    window.scrollTo({ top: 100, behavior: 'smooth' });
  }

  renderActivityLogItems() {
    const { logs, loading } = this.state;
    if (loading) {
      return (
        <p>
          <LoadingIcon size={16} className="mdi-spin" />
          {' '}
          Loading...
        </p>
      );
    }
    if (logs.length === 0) {
      return (
        <Row>
          <Col className="text-center">
            No Logs Found.
          </Col>
        </Row>
      )
    }
    return logs.map(item => (
      <ActivityLogItem activity_log={item} key={item.id} />
    ));
  }
  
  updateFilters = (value) => {
    this.setState({
      selectedUser: value,
      currentPage: 1,
    }, () => this.loadLogs());
  }

  render() {
    const { currentPage, totalRecords, users, selectedUser } = this.state;
    return (
      [
        <PageHeader title="Activity Log" />,

        <CardWrapper>
          <Row>
            <Col sm={10}>
              <label>Filter by User:</label>
              <DropdownList
                textField="name"
                valueField="id"
                data={users}
                value={selectedUser}
                onChange={value => this.updateFilters(value)}
                placeholder="Select User"
              />
            </Col>
            <Col sm={2}>
              <label>
                &nbsp;
              </label>
              <br />
              <button className="btn btn-primary" onClick={() => this.loadLogs()}>
                <RefreshIcon size={16} />
                {' '}
                Refresh
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr size={1} />
              {this.renderActivityLogItems()}
              <br />
              <br />
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={30}
                totalItemsCount={totalRecords}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
                innerClass={'pagination'}
                itemClass={'paginate_button page-item'}
                linkClass={'page-link'}
              />
            
            </Col>
          </Row>
        </CardWrapper>,
      ]
    );
  }
}