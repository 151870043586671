import React from 'react';
import { Row, Col } from 'reactstrap';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';

import Error from '../Error';
import { saveNote } from '../../api/note';

class NoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      objectName: this.props.objectName,
      saving: false,
      errors: [],
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({ note: value });
  }

  saveNote = (event) => {
    console.log(event);
    event.preventDefault();
    this.setState({ errors: [] });
    const { note, objectName } = this.state;
    const { objectId } = this.props;

    this.setState({ saving: true });

    const data = {
      note: {
        note: note,
        object_name: objectName,
        object_id: objectId,
      },
    };

    saveNote(data)
      .then((response) => {
        this.setState({ saving: false });
        if (response.errors.length === 0) {
          this.props.updateNoteList();
          this.resetForm();
        } else {
          this.setState({ errors: response.errors });
        }
      });
  }

  renderSaveButton() {
    const { saving } = this.state;
    if (saving) {
      return (
        <span>
          Saving
        </span>
      );
    }
    return (
      <button type="submit" className="btn btn-primary btn-block">
        <ContentSaveIcon size={16} />
        &nbsp;
        Add Note
      </button>
    );
  }

  resetForm() {
    this.setState({ note: '' });
  }

  renderErrors = () => {
    const { errors } = this.state;
    if (errors.length === 0) { return null; }
    return (
      <Error errors={errors} />
    );
  }

  render() {
    const { note } = this.state;
    return(
      <form onSubmit={this.saveNote}>
        {this.renderErrors()}
        <Row>
          <Col>
            <div className="form-group">
              <textarea
                name="note"
                id="note"
                className="form-control"
                rows={5}
                onChange={(event) => this.handleChange(event)}
                value={note}
                placeholder="Notes..."
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.renderSaveButton()}
          </Col>
        </Row>
      </form>
    );
  }
}

export default NoteForm;

