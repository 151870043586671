import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import Pagination from 'react-js-pagination';
import LoadingIcon from 'mdi-react/LoadingIcon';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import Form from './Form';
import EventItem from './EventItem';

import { getEvents, deleteEvent } from '../../api/event';

const EventList = (props) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [event, setEvent] = useState({});
  const [pastEvents, setPastEvents] = useState(0);

  useEffect(() => {
    loadEvents()
  }, [currentPage, pastEvents])

  const loadEvents = () => {
    setLoading(true);
    getEvents({ page: currentPage, userFilter: 1, past: pastEvents })
      .then((res) => {
        setEvents(res.events);
        setLoading(false);
      });
  }

  const reloadEvents = () => {
    console.log('reloading events...');
    loadEvents();
  }

  const doDeleteEvent = (event, event_id) => {
    event.preventDefault();
    if (window.confirm('Are you sure?')) {
      console.log("Delete Event...");
      console.log(event);
      deleteEvent({ id: event_id })
        .then((res) => {
          loadEvents();
        });
    }
  }

  const editEvent = (e, event_id) => {
    e.preventDefault();
    console.log("edit event");
    console.log(event_id);
    const url = `/events/${event_id}`;
    console.log(url);
    navigate(url);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // loadEvents();
  }

  const renderEvents = () => {
    return events.map(item => <EventItem event={item} key={item.id} deleteEvent={doDeleteEvent} editEvent={editEvent} />);
  }

  const activeStatus = (item) => {
    if (pastEvents == item) { return 'active'; }
    return '';
  }

  const updateFilter = (value) => {
    setPastEvents(value)
  }

  const renderHeader = () => {
    return (
      [
        <Row>
          <Col className="text-right">
            <ButtonGroup>
              <Button className={activeStatus(0)} onClick={() => updateFilter(0)}>Future</Button>
              <Button className={activeStatus(1)} onClick={() => updateFilter(1)}>Past</Button>
            </ButtonGroup>
          </Col>
        </Row>,
        <Row className="p-2">
          <Col md={2}>
            Type
          </Col>
          <Col md={4}>
            Name / Start / End
          </Col>
          <Col md={5}>
            Users
          </Col>
        </Row>,
      ]
    );
  }

  return (
    <div>
      <PageHeader title="Events" />
      <CardWrapper>
        <Form doRefresh={reloadEvents} />
        {loading && (
          <Row>
            <Col className="text-center">
              <LoadingIcon
                title="Loading"
                size={16}
                spin
              />
              Loading...
            </Col>
          </Row>
        )}
        {renderHeader()}
        {renderEvents()}
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={20}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={5}
          onChange={(e) => handlePageChange(e)}
          innerClass={'pagination'}
          itemClass={'paginate_button page-item'}
          linkClass={'page-link'}
        />
      </CardWrapper>
    </div>
  );
}

export default EventList;
