import React, { useEffect, useState } from 'react';
import { 
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// components
import CardWrapper from '../CardWrapper';

import styles from './company.module.scss';

// api
import { listCompanies } from '../../api/company';
import Moment from 'react-moment';

const Company = ({ userId, updateStatus }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const [prospects, setProspects] = useState([]);
  const [stales, setStales] = useState([]);

  useEffect(() => {
    if (userId == null) return;

    const data = {
      currentPage: 1,
      user: userId,
      limit: 4,
      search: '',
      all: '',
    };
    listCompanies({ stars: 1, ...data }).then((items) => {
      setProspects(items.companies);
    });

    listCompanies({ stars: 2, ...data }).then((items) => {
      setStales(items.companies);
    });
    setLoading(false);
    updateStatus('company', false);
  }, [userId]);

  return (
    <CardWrapper>
      {loading ? (
        <p>loading...</p>
      ) : (
        <>
          <Nav>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? styles.active : ''}
                onClick={() => setActiveTab('1')}
              >
                Prospects
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? styles.active : ''}
                onClick={() => setActiveTab('2')}
              >
                Stale
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                {prospects.map(company => (
                  <Col col={12} md={3}>
                    <Card key={company.id}>
                      <CardBody>
                        {company.name}
                        <br />
                        <Badge color="primary" pill>
                          Last Touched:
                          {' '}
                          <Moment format="MM/DD/YYYY">
                            {company.updated_at}
                          </Moment>
                        </Badge>
                        <div className="text-right">
                          <Link
                            to={{
                              pathname: `/companies/${company.id}`,
                            }}
                          >
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                {stales.map(company => (
                  <Col col={12} md={3}>
                    <Card key={company.id}>
                      <CardBody>
                        {company.name}
                        <br />
                        <Badge color="primary" pill>
                          Last Touched:
                          {' '}
                          <Moment format="MM/DD/YYYY">
                            {company.updated_at}
                          </Moment>
                        </Badge>
                        <div className="text-right">
                          <Link
                            to={{
                              pathname: `/companies/${company.id}`,
                            }}
                          >
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </TabPane>
          </TabContent>
        </>
      )}
    </CardWrapper>
  )
}


export default Company;
