import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';

import CompanyList from './CompanyList';
import CompanyForm from './CompanyForm';
import CompanyNewForm from './CompanyNewForm';
import ContactForm from './ContactForm';
import Prospects from './prospects';

const Company = () => (
  <>
    <Helmet>
      <title>The Hub - Companies</title>
    </Helmet>
    <Routes>
      <Route path=":cid/contacts/new" element={<ContactForm isNew={true} />} />
      <Route path=":cid/contacts/:id" element={<ContactForm isNew={false} />} />
      <Route path="prospects" element={<Prospects />} />
      <Route path="new" element={<CompanyNewForm />} />
      <Route path=":id" element={<CompanyForm />} />
      <Route path="" element={<CompanyList />} />
    </Routes>
  </>
);

export default Company;
