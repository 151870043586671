import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { signIn } from '../../api/auth';

import '../../styles/Main.scss';

const cookies = new Cookies();

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      errors: '',
      message: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const status = params.get('status');
    let message = '';

    console.log(status);
    switch (status) {
      case '1':
        message = 'You will receive an email with password reset instructions.';
        break;
      case '2':
        message = 'Your password has been reset.';
        break;
      default:
        break;
    }

    console.log(message);

    this.setState({ message });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.setState({
      [name]: value,
    });
  }

  submitLogin(event) {
    const { login, password } = this.state;
    event.preventDefault();
    const data = {
      email: login,
      password,
    };
    signIn(data).then((res) => {
      if (res.success) {
        // sessionStorage.setItem('jwt', res.jwt);
        localStorage.setItem('uid', res.user);
        localStorage.setItem('role', res.role);
        localStorage.setItem('a', res.abilities);
        localStorage.removeItem('timeline:filters');
        localStorage.removeItem('company:filters');
        window.location = '/dashboard';
      } else {
        console.log(res);
        this.setState({ errors: res.errors });
      }
    });
  }

  renderMessage() {
    const { message } = this.state;
    if (message !== '') {
      return (
        <div className="alert alert-success">
          {message}
        </div>
      );
    }
    return null;
  }

  renderErrors() {
    const { errors } = this.state;
    if (errors.length === 0) {
      return null;
    }
    return (
      <div className="errors">
        <h3>The following errors prohibited login:</h3>
        <ul>
          {errors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    return (
      [
        <div className="login" />,
        <section id="wrapper">
          <div className="login-register">
            <div className="login-box card">
              <div className="card-body">
                <form name="loginForm" className="form-horizontal form-material" onSubmit={this.submitLogin}>
                  <h3 className="box-title m-b-20">Sign In</h3>
                  <p>Please contact an administrator for access.</p>
                  {this.renderErrors()}
                  {this.renderMessage()}
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <input className="form-control" type="text" required="" placeholder="Username" name="login" id="login" onChange={this.handleInputChange} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <input className="form-control" type="password" name="password" id="password" placeholder="**********" onChange={this.handleInputChange} />
                    </div>
                  </div>
                  <div className="form-group text-center m-t-20">
                    <div className="col-xs-12">
                      <button className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Log In</button>
                    </div>
                  </div>
                  <div className="col text-center">
                    <Link to="/forgot">
                      Forgot Password?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>,
      ]
    );
  }
}

export default Login;
