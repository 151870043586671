import React from 'react';
import PropTypes from 'prop-types';

const SalesBox = ({ title, icon, value }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-row">
          <div className="round round-lg align-self-center round-info">{icon}</div>
          <div className="m-l-10 align-self-center">
            <h3 className="m-b-0 font-light">{value}</h3>
            <h5 className="text-muted m-b-0">{title}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

SalesBox.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default SalesBox;
