import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import Moment from 'react-moment';

class AlertItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    const { alert } = this.props;
    return [
      <Row className="p-2">
        <Col md={2}>
          <Moment format="MM/DD/YYYY h:mm A">
            {alert.created_at}
          </Moment>
        </Col>
        <Col md={1}>
          <label className="label label-primary">
            {alert.alert_type}
          </label>
        </Col>
        <Col md={8}>
          {alert.message}
        </Col>
        <Col md={1}>
          <Link
            className="btn btn-primary"
            to={{
              pathname: `${alert.link}`,
            }}
          >
            View
            &nbsp;
            <ArrowRightIcon size={14} />
          </Link>
        </Col>
      </Row>,
      <hr />,
    ];
  }
}

export default AlertItem;
