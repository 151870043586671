import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default function SalesHistoryTableGroupYear({ year }) {
  const [showRows, setShowRows] = useState(false);

  if (year === undefined) {
    return null;
  }

  const expandRowsButton = () => {
    setShowRows(!showRows);
  };

  const renderRow = (data) => {
    if (!showRows) {
      return null;
    }
    return (
      <tr>
        <td>
          <strong>
            {data.sku}
          </strong>
          <br />
          {data.name}
        </td>
        {data.months.map(month => (
          <td>
            {month.salesTotal}
            {' '}
            ({month.orders})
          </td>
        ))}
        <td className="bg-info text-white">
          {data.totalSalesDisplay}
          {' '}
          ({data.totalOrders})
        </td>
      </tr>
    );
  };

  const renderYear = () => {
    return (
      <>
        <tr>
          <td>{year.year}</td>
          {year.months.map(month => (
            <td>
              {month.salesTotal}
              {' '}
              ({month.orders})
            </td>
          ))}
          <td className="bg-info text-white">
            {year.totalSalesDisplay}
            {' '}
            ({year.totalOrders})
          </td>
        </tr>
        <tr>
          <td colSpan={14}>
            <Button
              color="primary"
              size="small"
              onClick={() => expandRowsButton()}
            >
              {showRows ? 'Hide Products' : 'Show Products'}
            </Button>
          </td>
        </tr>
        {year.products.map(item => renderRow(item))}
      </>
    );
  };

  const renderMonths = () => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (
      months.map(month => (<th>{month}</th>))
    );
  };

  return (
    renderYear()
  );
}
