import React from 'react';
import { Link } from 'react-router-dom';

import { resetPassword } from '../../api/auth';

import '../../styles/Main.scss';

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      password: '',
      passwordConfirm: '',
      errors: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ id: params.id });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.setState({
      [name]: value,
    });
  }

  submitLogin(event) {
    const { id, password, passwordConfirm } = this.state;
    event.preventDefault();
    const data = {
      user: {
        reset_password_token: id,
        password,
        password_confirmation: passwordConfirm,
      },
    };
  
    resetPassword(data).then((res) => {
      if (res.success) {
        window.location = '/login?status=2';
      } else {
        console.log(res);
        this.setState({ errors: res.errors });
      }
    });
  }

  renderErrors() {
    const { errors } = this.state;
    if (errors.length === 0) {
      return null;
    }
    return (
      <div className="errors">
        <h3>The following errors prohibited login:</h3>
        <ul>
          {errors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <section id="wrapper">
        <div className="login-register">
          <div className="login-box card">
            <div className="card-body">
              <form name="loginForm" className="form-horizontal form-material" onSubmit={this.submitLogin}>
                <h3 className="box-title m-b-20">Change Password?</h3>
                {this.renderErrors()}
                <div className="form-group">
                  <div className="col-xs-12">
                    <input className="form-control" type="password" name="password" id="password" placeholder="**********" onChange={this.handleInputChange} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-12">
                    <input className="form-control" type="password" name="passwordConfirm" id="passwordConfirm" placeholder="**********" onChange={this.handleInputChange} />
                  </div>
                </div>
                <div className="form-group text-center m-t-20">
                  <div className="col-xs-12">
                    <button className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset?</button>
                  </div>
                </div>
                <div className="col text-center">
                  <Link to="/login">
                    Login?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Password;
