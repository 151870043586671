import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import SalesHistoryTableGroupYear from './TableGroupYear';

export default function SalesHistoryTableGroup({ report }) {

  if (report === undefined) {
    return null;
  }

  const renderYear = (yearData) => {
    return (
      <SalesHistoryTableGroupYear year={yearData} />
    );
  };

  const renderMonths = () => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (
      months.map(month => (<th>{month}</th>))
    );
  };

  return (
    report.item_group.map(item_group => (
      <>
        <Row>
          <Col>
            <h3>{item_group.item_group_name}</h3>
          </Col>
        </Row>      
        <table className="table table-striped">
          <thead>
            <tr>
              <th>&nbsp;</th>
              {renderMonths()}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {item_group.years.map(year => renderYear(year))}
          </tbody>
          <tfoot></tfoot>
        </table>
      </>
    ))
  );
}
