import api from './init';
import returnError from './returnError';

export function taskComments(id) {
  const url = `/api/v1/tasks/${id}/comments.json`
  return api
      .get(url, { withCredentials: true })
      .then(response => { return response.data })
      .catch((error) => {
        console.log(error);
        return returnError();
      })
}

export function saveComment(id, comment) {
  const url = `/api/v1/tasks/${id}/comments.json`
  api.post(url, comment, { withCredentials: true })
    .then(response => { return response.data })
    .catch((error) => {
      console.log(error);
      return returnError();
    })
}