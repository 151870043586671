import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Badge
} from 'reactstrap';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

const UrlRow = ({ url, deleteUrl }) => {
  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          {url.sku}
        </Col>
        <Col xs={12} md={2}>
          {url.status === "Active" ? <Badge color="success">Active</Badge> : <Badge color="danger">{url.status}</Badge>}
        </Col>
        <Col xs={12} md={3}>
          {url.updatedAt}
        </Col>
        <Col xs={12} md={2}>
        </Col>
        <Col xs={12} md={2}>
          <button
            className="btn btn-danger"
            onClick={e => deleteUrl(e, url.id)}
          >
           Delete
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <a href={url.url} target="_blank" rel="noreferrer">
            {url.url}
            {' '}
            <OpenInNewIcon size={12} />
          </a>
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default UrlRow
