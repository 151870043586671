import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import MoneyIcon from 'mdi-react/MoneyIcon';

import SalesBox from './SalesBox';

const SalesInfo = (props) => {
  const { salesData } = props;
  if (salesData === null) { return null; }
  return (
    <Row>
      <Col xs={12}>
        <SalesBox
          title="Sale Month to Date"
          value={salesData.sales_month_to_date}
          icon={<MoneyIcon size={30} />}
        />
      </Col>
      <Col xs={12}>
        <SalesBox
          title="Sales Year to Date"
          value={salesData.sales_year_to_date}
          icon={<MoneyIcon size={30} />}
        />
      </Col>
      <Col xs={12}>
        <SalesBox
          title="Sales Total Last Year"
          value={salesData.sales_last_year}
          icon={<MoneyIcon size={30} />}
        />
      </Col>
    </Row>
  );
};

SalesInfo.propTypes = {
  salesData: PropTypes.shape({
    sales_month_to_date: PropTypes.string.isRequired,
    sales_year_to_date: PropTypes.string.isRequired,
    sales_last_year_to_date: PropTypes.string.isRequired,
    sales_year_difference: PropTypes.string.isRequired,
  }).isRequired,
};

export default SalesInfo;
