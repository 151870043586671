import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Alert } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { DropdownList } from 'react-widgets';

import PageHeader from '../../../components/PageHeader';
// import PageSubHeader from '../../components/PageSubHeader';
import CardWrapper from '../../../components/CardWrapper';
import Error from '../../../components/Error';
import Loading from '../../../components/Loading';

import SegmentRow from '../../../components/admin/segments/segmentRow';

import { loadSegments, saveSegment } from '../../../api/segment';

export default function Segment() {
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [updateId, setUpdateId] = useState(null);

  useEffect(() => {
    setLoading(true);
    loadSegments(year)
      .then((data) => {
        setSegments(data);
        setLoading(false);
      });
  }, [year]);

  const years = () => {
    const startYear = 2018;
    const endYear = new Date().getFullYear() + 1;
    const yearList = [];
    for (let i = startYear; i <= endYear; i++) {
      yearList.push(i);
    }
    return yearList;
  };

  const saveGoal = (id, goal) => {
    setSaving(true);
    saveSegment({id, goal})
      .then((data) => {
        if (data.error) {
          setErrors(data.errors);
        } else {
          setErrors([]);
          setUpdateId(id);
        }
        setSaving(false);
      });
  };

  const renderYear = () => {
    return (
      <>
        <PageHeader title="Segments" />
        <CardWrapper>
          <DropdownList
            data={years()}
            value={year}
            placeholder="Select Year"
            onChange={value => setYear(value)}
          />
        </CardWrapper>
      </>
    );
  };

  const renderZeroSegments = () => {
    if (segments.length > 0) { return null; }

    return (
      <Alert
        color="info"
      >
        No segments found for this year.
      </Alert>
    );
  };

  return (
    <>
      {loading ? <Loading /> : (
        <>
          {renderYear()}
          {errors.length > 0 && <Error errors={errors} />}
          <CardWrapper>
            {renderZeroSegments()}
            {segments.map(data => <SegmentRow data={data} save={saveGoal} saving={saving} key={data.id} isUpdated={updateId === data.id} />)}
          </CardWrapper>
        </>
      )}
    </>
  );
}
