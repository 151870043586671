import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'reactstrap';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { DropdownList } from 'react-widgets';

// components
import CardWrapper from '../CardWrapper';
import Loading from '../Loading';
import ErrorBoundary from '../ErrorBoundary';

// api
import { getChartData } from '../../api/report';

// stylesheet
import styles from './salesBreakdown.module.scss';

// list
import { salesBreakdownList } from '../../libs/list';

const SalesBreakdown = ({ userId, segment, updateStatus }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear - 1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [yearTwo, setYearTwo] = useState(currentYear);
  const [dataTwo, setDataTwo] = useState([]);
  const [loadingTwo, setLoadingTwo] = useState(true);

  const [timePeriod, setTimePeriod] = useState({ name: "Full Year", value: 0, type: "" });

  const COLORS = ['#fc4b6c', '#26c6da', '#ffb22b', '#7460ee', '#1e88e5'];

  useEffect(() => {
    setTimePeriod({ name: "Full Year", value: 0, type: "" })
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    getChartData('pie', [year], null, userId, segment, timePeriod.value)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [userId, year, segment, timePeriod]);

  useEffect(() => {
    setLoadingTwo(true);
    getChartData('pie', [yearTwo], null, userId, segment, timePeriod.value)
      .then((res) => {
        setDataTwo(res.data);
        setLoadingTwo(false);
      });
  }, [userId, yearTwo, segment, timePeriod]);

  useEffect(() => {
    if (loading && loadingTwo) {
      updateStatus('breakdown', true);
    } else {
      updateStatus('breakdown', false);
    }
  }, [loading, loadingTwo]);

  const updateTimePeriod = (value) => {
    setTimePeriod(value)
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', color: '#000', padding: '10px' }}>
          <p>{payload[0].name}</p>
          <p>{payload[0].payload.valueDisplay}</p>
        </div>
      );
    }
  };

  const renderDataTable = (d) => {
    return d.map((entry, index) => (
      <Row className={styles.dataTable}>
        <Col xs={12} sm={6}>{entry.name}</Col>
        <Col xs={12} sm={6}>{entry.valueDisplay}</Col>
      </Row>
    ));
  };

  const renderDropdowns = () => {
    return (
      <DropdownList
        data={salesBreakdownList()}
        valueField="value"
        textField="name"
        groupBy="type"
        value={timePeriod}
        disabled={loading && loadingTwo}

        onChange={(value) => updateTimePeriod(value)}
      />
    )
  }

  const renderYear = (y) => {
    if (timePeriod.type === '') {
      return y;
    }
    return `${timePeriod.name} - ${y}`
  }

  return (
    <CardWrapper>
      <Row>
        <Col xs={12} sm={9} md={10}>
          <h4>Sales Breakdown</h4>
        </Col>
        <Col xs={12} sm={3} md={2}>
          {renderDropdowns()}
        </Col>
      </Row>
      {loading && loadingTwo ? (
        <Loading />
      ) : (
        <Row>
          <Col xs={12} md={6}>
            <ErrorBoundary message="Error loading Pie Chart">
              <ResponsiveContainer width="100%" height={500}>
                <PieChart width={500} height={500}>
                  <Pie
                    data={data}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={200}
                    fill="#7460ee"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip content={CustomTooltip} />
                </PieChart>
              </ResponsiveContainer>
              <div className={styles.year}>
                {renderYear(year)}
              </div>
              {renderDataTable(data)}
            </ErrorBoundary>            
          </Col>
          <Col xs={12} md={6}>
            <ErrorBoundary message="Error loading Pie Chart">
              <ResponsiveContainer width="100%" height={500}>
                <PieChart width={500} height={500}>
                  <Pie
                    data={dataTwo}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={200}
                    fill="#7460ee"
                  >
                    {dataTwo.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip content={CustomTooltip} />
                </PieChart>
              </ResponsiveContainer>
              <div className={styles.year}>
                {renderYear(yearTwo)}
              </div>
              {renderDataTable(dataTwo)}
            </ErrorBoundary>
          </Col>
        </Row>
        
      )}
    </CardWrapper>
  );
};


SalesBreakdown.propTypes = {
  userId: PropTypes.string,
  segment: PropTypes.any,
  updateStatus: PropTypes.func,
};

export default SalesBreakdown;
