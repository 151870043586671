import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';

// components
import CardWrapper from '../CardWrapper';

// api
import { getEventList } from '../../api/calendar';

// class
const Upcoming = ({ userId, updateStatus }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const startDate = moment().startOf('week');
  const endDate = moment().startOf('week').add(7, 'days');

  useEffect(() => {
    if (userId == null) return;

    getEventList({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      userId,
      showTasks: true,
      showEvents: true,
    })
      .then((data) => {
        setEvents(data.events);
        setLoading(false);
        updateStatus('upcoming', false);
      });
  }, [userId]);


  const renderDays = () => {
    const days = [];
    for (let i = 1; i < 6; i++) {
      const day = moment().startOf('week').add(i, 'days');
      const dayEvents = events.filter((event) => {
        return moment(event.due_date).isSame(day, 'day');
      });

      days.push(
        <Col key={i} className="text-center">
          <Card>
            <CardBody>
              <h5>
                <Moment format="ddd">
                  {day}
                </Moment>
              </h5>
              <h5>
                <Moment format="MM/DD">
                  {day}
                </Moment>
              </h5>
              {dayEvents.length === 0 && (
                <p>No events</p>
              )}
              {dayEvents.map(event => (
                <p key={event.id}>
                  {event.title}
                </p>
              ))}
            </CardBody>
          </Card>
        </Col>
      );
    }

    return days;
  };

  return (
    <CardWrapper>
      <Row>
        <Col>
          <h4>
            This Week:
            {' '}
            <Moment format="MM/DD/YYYY">
              {startDate}
            </Moment>
            {' - '}
            <Moment format="MM/DD/YYYY">
              {endDate}
            </Moment>
          </h4>
        </Col>
      </Row>
      {loading ? (
        <>
          <p>Loading</p>
        </>
      ) : (
        <Row>
          {renderDays()}
        </Row>
      )}
    </CardWrapper>
  );
};

export default Upcoming;
