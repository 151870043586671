import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';

import { inValidRole } from '../../libs/utils';
import ReportList from './ReportList';
import Timeline from '../timeline';
import ActivityLog from './activityLog';

const Reports = () => (
  <>
    <Helmet>
      <title>The Hub - Reports</title>
    </Helmet>
    <Routes>
      <Route
        path="timeline"
        element={<Timeline />}
      />
      {inValidRole(['admin', 'sales_manager', 'csr_manager'], localStorage) && (
        <Route
          path="activity_log"
          element={<ActivityLog/>}
        />
      )}
      <Route
        path=""
        element={<ReportList/>}
      />
    </Routes>
  </>
);

export default Reports;
