import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Pagination from 'react-js-pagination';
import TransferRightIcon from 'mdi-react/TransferRightIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';

import TaskItem from './TaskItem';
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';

import { getTaskList, deleteTask } from '../../api/task';
import { fullUserListByRole } from '../../api/user';
import { priorityColor } from '../../libs/utils';

let PriorityListItem = ({ item }) => (
  <label className={`text-${priorityColor(item)}`}>
    {item}
  </label>
);

const defaultFilters = {
  paging: {
    page: 1,
    totalRecords: 0
  },
  filters: {
    status: 'open',
    priority: 'all',
    user: {id: '', name: null},
  }
}


const useFilterStorage = (storageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultFilters
  )

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}


const TaskList = ({skipFilters, displayHeader, displayFilters}) => {
  const [counter, setCounter] = useState(0)
  const [tasks, setTasks] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [taskFilters, setTaskFilters] = useFilterStorage('task:filters')

  useEffect(() => {
    loadUserList()
  }, [])

  useEffect(() => {
    loadData()
  }, [taskFilters])


  // load data
  const loadData = () => {
    setLoading(true)

    let data = { 
      currentPage: taskFilters.paging.page,
      statusFilter: taskFilters.filters.status,
      priorityFilter: taskFilters.filters.priority,
      userFilter: taskFilters.filters.user.id,
      companyId: ""
    }
    if (skipFilters !== undefined && skipFilters) {
      data = { 
        currentPage: 1,
        statusFilter: "open",
        priorityFilter: "",
        userFilter: "",
        companyId: this.props.filters.companyId 
      }
    }
    getTaskList(data)
      .then((response) => {
        setTasks(response.tasks)
        setTotalRecords(response.details.total_records)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const loadUserList = () => {    
    const role = ['admin', 'sales_rep', 'sales_admin', 'sales_manager','csr_manager', 'csr'];

    if (role !== '') {
      fullUserListByRole(role)
        .then((data) => {
          data.unshift({ name: '-- All --', id: '' });
          setUserList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const refresh = (e) => {
    e.preventDefault()
    setTaskFilters(defaultFilters)
  }

  const handlePageChange = (pageNumber) => {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    const val = {...taskFilters.paging, page: pageNumber }
    setTaskFilters({ ...taskFilters, paging: val })
  }

  const onDelete = (id) => {
    if (window.confirm('Are you sure?')) {
      deleteTask(id)
        .then(response => {
          setCounter(prevState => prevState + 1)
        });
    }
  }
  
  const setFilter = (value, name) => {
    setTaskFilters({
      ...taskFilters,
      filters: { ...taskFilters.filters, [name]: value },
      paging: { ...taskFilters.paging, page: 1 }
    })
  }


  // render
  const renderTasks = () => {
    if (tasks.length === 0) {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            No Tasks at this time.
          </td>
        </tr>
      );
    }
    return tasks.map(task => (
      <TaskItem task={task} key={`t-${task.id}`} onDelete={onDelete} />
    ));
  }

  const renderLoading = () => {
    if (!loading) {
      return null;
    }
    return (
      <tr>
        <td colSpan={8} className="text-center">
          <LoadingIcon
            title="Loading"
            size={16}
            spin
          />
          Loading...
        </td>
      </tr>
    )
  }

  return (
    <>
      {displayHeader &&
        <PageHeader title="Tasks" />
      }
      <CardWrapper>
        {displayFilters && (
          <Row>
            <Col md={4}>
              <Link to="/tasks/new" className="btn btn-primary">New Task</Link>
            </Col>
            <Col md={1}>
              <button
                href="#"
                className="btn btn-primary"
                onClick={(e) => refresh(e)}
              >
                <RefreshIcon size={16} />
              </button>
            </Col>
            <Col md={3}>
              {userList.length > 0 && (
                [
                  <label html="">User:</label>,
                  <DropdownList
                    filter
                    value={taskFilters.filters.user}
                    data={userList}
                    placeholder="Users"
                    onChange={value => setFilter(value, 'user')}
                    textField="name"
                    valueField="id"
                  />,
                ]
              )}
            </Col>
            <Col md={2}>
              <label htmlFor="">Priority:</label>
              <DropdownList
                value={taskFilters.filters.priority}
                data={['all', 'none', 'low', 'high', 'urgent']}
                placeholder="Priority"
                onChange={value => setFilter(value, 'priority')}
                itemComponent={PriorityListItem}
              />
            </Col>
            <Col md={2}>
              <label htmlFor="">Status:</label>
              <DropdownList
                value={taskFilters.filters.status}
                data={['all', 'open', 'complete']}
                placeholder="Status"
                onChange={value => setFilter(value, 'status')}
              />
            </Col>
          </Row>
        )}
        <div className="table-responsive m-t-20">
          <table className="table stylish-table">
            <thead>
              <tr>
                <th width={2} className="cell-no-padding"></th>
                <th>&nbsp;</th>
                <th>Created</th>
                <th>
                  <TransferRightIcon size={24} />
                </th>
                <th>Assigned</th>
                <th>Task/Company</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {renderLoading()}
              {renderTasks()}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="5">
                  <Pagination
                    activePage={taskFilters.paging.page}
                    itemsCountPerPage={20}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={5}
                    onChange={(value) => handlePageChange(value)}
                    innerClass={'pagination'}
                    itemClass={'paginate_button page-item'}
                    linkClass={'page-link'}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </CardWrapper>
    </>
  );
}

TaskList.defaultProps = {
  displayHeader: true,
  displayNewButton: true,
  displayFilters: true,
}

export default TaskList
