import React from 'react';
import Pagination from 'react-js-pagination';
import { Row, Col } from 'reactstrap';
import SearchIcon from 'mdi-react/SearchIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';

import NoteForm from './NoteForm';
import Note from './Note';
import { getNotes } from '../../api/note';

class NoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalRecords: 0,
      search: '',
      notes: [],
      loading: false,
      objectId: '',
      objectName: '',
    };
  }

  componentDidMount() {
    this.loadNotes();
  }

  componentWillReceiveProps(newProps) {
    const { objectId, objectName } = this.state;
    if (newProps.objectId !== objectId) {
      this.setState({
        objectId: newProps.objectId,
        objectName: newProps.objectName,
        currentPage: 1,
      }, () => this.loadNotes());
    }
  }

  loadNotes = () => {
    this.setState({ loading: true });
    const { currentPage, search } = this.state;
    const { objectId, objectName } = this.props;
    this.setState({
      objectId,
      objectName
    });
    const filters = { currentPage, objectId, objectName, search };

    getNotes(filters)
      .then((data) => {
        this.setState({
          notes: data.notes,
          currentPage: data.details.current_page,
          totalRecords: data.details.total_records,
          loading: false,
        });
      });
  }

  handlePageChange(pageNumber) {
    this.setState({ currentPage: pageNumber }, () => {
      this.loadNotes();
    });
  }

  renderNotes = () => {
    const { notes, loading } = this.state;
    if (loading) {
      return (
        <Row>
          <Col className="text-center">
            <LoadingIcon size={24} />
            &nbsp;
            Loading
          </Col>
        </Row>
      )
    }
    return notes.map(note => (
      <Note note={note} user={note.user} key={note.id} />
    ));
  }

  renderPaging() {
    const { notes, currentPage, totalRecords } = this.state;
    if (notes.length === 0) {
      return null;
    }
    return (
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={10}
        totalItemsCount={totalRecords}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange}
        innerClass={'pagination'}
        itemClass={'paginate_button page-item'}
        linkClass={'page-link'}
      />
    );
  }

  updateSearchText = (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({ search: value });
  }

  doSearch = (event) => {
    event.preventDefault();
    this.setState({ currentPage: 1 }, () => this.loadNotes());
  }

  clearSearch = (event) => {
    event.preventDefault();
    this.setState({ search: '', currentPage: 1 }, () => this.loadNotes());
  }

  render() {
    const { search } = this.state;
    const { objectId, objectName} = this.props;
    return (
      <div className="card-body">
        <div className="profiletimeline">
          <div className="sl-item">
            <div className="sl-right">
              <NoteForm objectId={objectId} objectName={objectName} updateNoteList={this.loadNotes} />
            </div>
          </div>
          <hr />
          <div className="sl-item">
            <div className="sl-right">
              <Row className="alert alert-secondary">
                <Col md={8}>
                  <input
                    type="search"
                    name="search"
                    onChange={(event) => this.updateSearchText(event)}
                    value={search}
                    className="form-control"
                    placeholder="search..."
                  />
                </Col>
                <Col md={2}>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={(event) => this.doSearch(event)}
                    title="Search"
                  >
                    <SearchIcon size={18} />
                  </button>
                </Col>
                <Col md={2}>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={(event) => this.clearSearch(event)}
                    title="Reset Search"
                  >
                    <RefreshIcon size={18} />
                  </button>
                </Col>
              </Row>
            </div>
          </div>
          <hr />
          {this.renderNotes()}
          {this.renderPaging()}
        </div>
      </div>
    )
  }
}

export default NoteList;