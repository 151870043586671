import axios from 'axios';

const api = axios.create({
  //baseURL: process.env.API_SERVER_URL
  // baseURL: process.env.REACT_APP_API_ENDPOINT
});

api.interceptors.response.use((response) => {
  // Do something with response data
  return response;
}, (error) => {
  if (/ 401/.test(error.message)) {
    window.location = '/login';
  }
  return Promise.reject(error);
});

export default api;