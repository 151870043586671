import React from 'react';
import AddIcon from 'mdi-react/AddIcon';
import { DatePicker, Multiselect, DropdownList } from 'react-widgets';
import { Row, Col } from 'reactstrap';

import Error from '../../components/Error';

import { fullUserList } from '../../api/user';
import { saveEvent, getEventTypes } from '../../api/event';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      users: [],
      showForm: false,
      event_name: '',
      description: '',
      event_type: '',
      user_list: [],
      start_date: null,
      end_date: null,
      all_day: false,
      private_event: false,
      statusMessage: '',
      eventTypes: [],
    };
  }

  componentDidMount() {
    this.loadListOfUsers();
    this.loadEventTypes();
  }

  loadListOfUsers() {
    fullUserList()
      .then((res) => {
        this.setState({ users: res });
      });
  }

  loadEventTypes() {
    getEventTypes()
      .then((res) => {
        let list = res.event_types;
        list.unshift("-- SELECT --");
        this.setState({ eventTypes: list });
      });
  }

  updateUserList(value) {
    this.updateEvent('user_list', value);
  }

  updateEvent = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  updatePrivateEvent = () => {
    const { private_event } = this.state;
    this.updateEvent('private_event', !private_event);
  }
  
  updateAllDay = () => {
    const { all_day } = this.state;
    this.updateEvent('all_day', !all_day);
  }

  updateEventType = value => {
    this.updateEvent('event_type', value);
  }

  updateDate(value, field) {
    console.log(value);
    this.setState({ [field]: value });
  }

  filterName(user, value) {
    let name = user.name.toLowerCase()
    let search = value.toLowerCase();
    return name.indexOf(search) === 0
  }

  handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.updateEvent(name, value);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { event_name, event_type, start_date, all_day, end_date, user_list, private_event, description } = this.state;
    let data = {
      event: {
        name: event_name,
        description,
        event_type,
        start_date,
        end_date,
        all_day,
        private_event,
        user_list: user_list.map(item => ({ id: item.id }))
      }
    };

    console.log(data);

    saveEvent(data)
      .then((response) => {
        if (response.errors.length === 0) {
          this.setState({
            statusMessage: response.message,
            errors: response.errors,
          });
          this.resetForm();
          this.props.doRefresh();
          setTimeout(() => {            
            this.setState({ statusMessage: '' })
          }, 2500);
        } else {
          this.setState({ errors: response.errors });
        }
      })
  }

  resetForm() {
    this.setState({
      event_name: '',
      event_type: '',
      user_list: [],
      private_event: false,
      all_day: false,
      start_date: null,
      end_date: null,
      description: '',
    })
  }

  toggleForm = () => {
    const { showForm } = this.state;
    this.setState({
      showForm: !showForm
    });
  }


  render() {
    const { errors, eventTypes, showForm, users, user_list, statusMessage, start_date, end_date, all_day, event_name, description, event_type, private_event } = this.state;
    return (
      <div>
        <div className="row m-t-20">
          <div className="col">
            <button className="btn btn-primary" onClick={this.toggleForm}>
              <AddIcon size={14} />
              Add an Event
              </button>
          </div>
        </div>
        { showForm && (
          <div className="row m-t-20">
            <div className="col">
              <Error errors={errors} />
              {statusMessage !== '' && (
                <div className="alert alert-info">
                  {statusMessage}
                </div>
              )}
              <form id="EventForm" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col col-md-4">
                    <label className="control-label" htmlFor="start_date">Start Date</label>
                    <DatePicker
                      placeholder="Start Date and Time..."
                      name="start_date"
                      includeTime
                      value={start_date}
                      min={new Date()}
                      onChange={value => this.updateDate(value, 'start_date')}
                    />
                  </div>
                  <div className="col col-md-4">
                    <label className="control-label" htmlFor="end_date">End Date</label>
                    <DatePicker
                      placeholder="End Date and Time..."
                      name="end_date"
                      includeTime
                      value={end_date}
                      min={new Date()}
                      onChange={value => this.updateDate(value, 'end_date')}
                    />
                  </div>
                  <div className="form-group col col-md-2">
                      <div className="alert alert-info">
                        <label className="control-label" htmlFor="private_event">All Day?</label>
                        <br />
                        <input type="checkbox" name="all_day" id="all_day" checked={all_day} onClick={() => this.updateAllDay()} /> Yes!
                      </div>
                    </div>
                  <div className="form-group col col-md-2">
                    <label className="control-label" htmlFor="private_event">Private?</label>
                    <br />
                    <input type="checkbox" name="private_event" id="private_event" checked={private_event} onClick={() => this.updatePrivateEvent()} /> Yes!
                    </div>
                </div>
                <Row>
                  <div className="form-group col col-md-6">
                    <label className="control-label" htmlFor="event_type">Event Type</label>
                    <DropdownList
                      data={eventTypes}
                      value={event_type}
                      onChange={value => this.updateEventType(value)}
                    />
                  </div>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="control-label" htmlFor="users">Who is this event for?</label>
                      <Multiselect
                        data={users}
                        textField='name'
                        value={user_list}
                        caseSensitive={false}
                        minLength={2}
                        filter={this.filterName}
                        onChange={value => this.updateUserList(value)}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="row">
                  <div className="form-group col">
                    <label className="control-label" htmlFor="event_name">Event Name</label>
                    <input type="text" className="form-control" id="event_name" name="event_name" rows="5" value={event_name} onChange={this.handleChange} />
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="description">Description/Information</label>
                      <textarea name="description" rows={5} className="form-control" id="description" value={description} onChange={this.handleChange}></textarea>
                    </div>
                  </Col>
                </Row>
                
                <div className="row">
                  <div className="col text-center">
                    <br />
                    <input className="btn btn-primary" type="submit" value="Add Event" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <hr />
      </div>
    )
  }
}