import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

// components

// utils
import { numberToCurrency } from '../../libs/utils'

// css
import styles from './LineItems.module.scss'


const LineItemsReadOnly = ({ companyId, lineItems }) => {

  // actions

  // render  

  const renderDiscount = (item) => {
    if (item.discount_applied) {
      return (
        <div className={styles.discount}>
          Adjustment Applied:
          {' '}
          {item.discount}%
          {' '}
          ({numberToCurrency(item.original_price)})
        </div>
      )
    }
    return null
  }

  const renderMsrp = (item) => {
    if (item.price === item.msrp) { return null }
    return (
      <div className={styles.msrp}>
        {numberToCurrency(item.msrp)}
      </div>
    )
  }

  return (
    <>
      {lineItems.map((item, idx) => 
        <div key={idx}>
          <Row className="mb-2">
            <Col xs={12} md={4}>
              {item.sku}
              {' - '}
              {item.name}
            </Col>
            <Col xs={12} md={2}>
              {item.quantity}
            </Col>
            <Col xs={12} md={3} className="text-end">
              {numberToCurrency(item.price)}
              {renderDiscount(item)}
              {renderMsrp(item)}
            </Col>
            <Col xs={12} md={2} className="text-end">{numberToCurrency(item.total_price)}</Col>
          </Row>
          <hr />
        </div>
      )}
      <Row>
        <Col xs={12} md={5}>&nbsp;</Col>
        <Col xs={12} md={2}>&nbsp;</Col>
        <Col xs={12} md={2} className="text-end">Total:</Col>
        <Col xs={12} md={2} className="text-end">
          {numberToCurrency(lineItems.reduce((total, item) => total + item.total_price, 0))}
        </Col>
      </Row>
    </>
  )
}

PropTypes.propTypes = {
  lineItems: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  updateClear: PropTypes.func.isRequired
}

export default LineItemsReadOnly
