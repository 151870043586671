import api from './init';
import returnError from './returnError';

export function getEventList(data) {
  const url = `/api/v1/tasks/events.json?start_date=${data.startDate}&end_date=${data.endDate}&u=${data.user ? data.user.id : ''}&show_tasks=${data.showTasks}&show_events=${data.showEvents}`;
  return api
    .get(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getEventTypes() {
  const url = '/api/v1/...';
  return api
    .get(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}
