import {
  Row,
  Col,
  Card
} from 'reactstrap'

// components
import CardWrapper from '../CardWrapper'

// utils
import { numberToCurrency, inValidRole } from '../../libs/utils'

// css
import styles from './ReportData.module.scss'

const ReportData = ({ status, reportData }) => {
  return (
    <CardWrapper>
      <div className="text-center">
        <h4 className={styles.title}>{status}</h4>
        <div className={styles.totalQuotes}>
          {reportData.total_quotes}
          <span>Quotes</span>
        </div>
        <div className={styles.total}>
          {numberToCurrency(reportData.total_amount)}
          <span>Total Amount</span>
        </div>
        <div className={styles.average}>
          {numberToCurrency(reportData.average_amount)}
          <span>Average Amount</span>
        </div>
        <div className={styles.rate}>
          {reportData.rate}
          <span>are {status}</span>
        </div>
        {inValidRole(['admin', 'manager'], localStorage) && reportData.ae && (
          <>
            <div className={styles.ae}>
              {reportData.ae.percentage}
              {' '}
              ({reportData.ae.total})
              <span>Entered by an AE</span>
            </div>
            <div className={styles.touch}>
              {reportData.touch}
              <span>Touched by an AE</span>
            </div>
          </>
        )}
      </div>
    </CardWrapper>
  )
}


export default ReportData