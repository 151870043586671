import React, { useState } from 'react'
import {
  Row,
  Col,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import WrenchIcon from 'mdi-react/WrenchIcon'

// utils
import { numberToCurrency } from '../../libs/utils'


const Shipping = ({ updateData, shippingCost, willcall, quoteStatus }) => {
  const [shippingTotal, setShippingTotal] = useState(shippingCost)
  const [freeShipping, setFreeShipping] = useState(false)
  const [isWillcall, setIsWillcall] = useState(willcall || false)
  const [isOpen, setIsOpen] = useState(false)

  let finalPrice = shippingCost

  const showBlock = () => {
    if (quoteStatus === 'declined') { return false }
    if (quoteStatus === 'approved') { return false }
    return true
  }

  const handleChange = (e) => {
    setShippingTotal(e.target.value)
  }

  const toggle = () => setIsOpen(!isOpen)
  const handleFocus = (e) => e.target.select()

  const close = () => {
    finalPrice = shippingTotal
    updateData({
      shipping: parseFloat(shippingTotal),
      freeShipping,
    })
    toggle()
  }

  return (
    <>
      <Row className='mt-2'>
        <Col xs={12} md={5}>&nbsp;</Col>
        <Col xs={12} md={2}>&nbsp;</Col>
        <Col xs={12} md={2} className="text-end">
          {showBlock() ? (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={toggle}
            >
              <WrenchIcon size={12} />
              {' '}
              Shipping
            </button>
          ) : (
            <>
              Shipping:
            </>
          )}
        </Col>
        <Col xs={12} md={2} className="text-end">
          {numberToCurrency(finalPrice)}
          {isWillcall && <div className="text-muted"> (Will Call)</div>}
        </Col>
        <Col xs={12} md={1}>
          
        </Col>
      </Row>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
      >
        <ModalHeader toggle={toggle}>
          Shipping
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col class="form-group">
              <label> 
                Shipping Cost
              </label>
              <input
                type="number"
                onChange={e => handleChange(e)}
                onFocus={e => handleFocus(e)}
                step={0.01}
                min={0}
                name="shippingTotal"
                id="shippingTotal"
                value={shippingTotal}
                class="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col class="form-group">
              <br />
              <label>
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={freeShipping}
                    onClick={() => setFreeShipping(!freeShipping)}
                  />
                  {' '}
                  Free Shipping?
                </FormGroup>
                {/* <input
                  type="checkbox"
                  name="freeShipping"
                  id="freeShipping"
                  onClick={e => setFreeShipping(e.target.checked)}
                  checked={freeShipping}
                /> */}
              </label>

            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col class="form-group">
              <Alert color="success">
                <label>
                  <input
                    checked={isWillcall}
                    type="checkbox"
                    name="willcall"
                    id="willcall"
                    onClick={(e) => setIsWillcall(e.target.checked)}
                  />
                  {' '}
                  Will Call?
                </label>
                <p>Customer will come pick up this order.</p>
              </Alert>
            </Col>
          </Row> */}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={close}
          >
            Update
          </button>
          {' '}
          <button
            className="btn btn-secondary"
            onClick={toggle}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}


export default Shipping
