import api from './init';
import returnError from './returnError';

export function getSalesData(data) {
  const url = `/api/v1/report_stores?month=${data.month}&year=${data.year}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveSalesData(data) {
  const url = '/api/v1/report_stores';
  return api.post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getSalesDataForRep(data) {
  const url = `/api/v1/report_stores?startYear=${data.startYear}&endYear=${data.endYear}&user_id=${data.userId}&segment=${data.segment}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getUserActivityData(data) {
  const url = `/api/v1/report_stores/timeline/${data.startDate}/${data.endDate}/${data.userId}`;
  console.log(url);
  return api.get(url, { withCredentials: true })
    .then((res) =>{
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getChartData(chartType, years, companyId, userId, segment, timePeriod) {
  const url = '/api/v1/report_stores/item_classes';
  const data = {
    chart_type: chartType,
    years,
    company_id: companyId,
    user_id: userId,
    segment,
    time_period: timePeriod,
  };

  return api.post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getCumulativeSalesReport(salesRep) {
  const url = `/api/v1/report_stores/cumulative?user=${salesRep}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getSalesGoal(salesRep, segment) {
  const url = `/api/v1/report_stores/goal?user_id=${salesRep}&segment=${segment}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getQuotesReport({ startDate, endDate, userId, dateOption}) {
  const url = `/api/v1/report_stores/quotes?`;
  const data = {
    start_date: startDate,
    end_date: endDate,
    user_id: userId,
    date_option: dateOption
  }
  
  return api.post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}