import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';

export default function Item({ taskTemplate, doEdit }) {
  return (
    <>
      <Row id={taskTemplate.id}>
        <Col md={3} className="taskTemplateName">{taskTemplate.name}</Col>
        <Col md={5} className="taskTemplateDescription">{taskTemplate.description}</Col>
        <Col md={3}>
          <Moment format="MM/DD/YYYY h:mm A">
            {taskTemplate.updated_at}
          </Moment>
        </Col>
        <Col md={1}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => doEdit(taskTemplate.id)}
          >
            Edit
          </button>
        </Col>
      </Row>
      <hr />
    </>
  );
}

Item.propTypes = {
  taskTemplate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  doEdit: PropTypes.func.isRequired,
};
