import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { DropdownList, DatePicker } from 'react-widgets';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';

import TaskEntry from './TaskEntry';

const maxDate = Date().toString();

export default function CallLogItem({ 
  callLog,
  companies,
  activityTypes,
  followUpTypes,
  users,
  whenInWeeks,
  index,
  addCompany,
  sendData,
  resetVerified
}) {
  const [tasks, setTasks] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [show, setShow] = useState(true);
  const [details, setDetails] = useState('');
  const [company, setCompany] = useState(null);
  const [activityType, setActivityType] = useState(null);
  const [callLogDate, setCallLogDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const list = useMemo(() => renderRows(), [tasks.length]);

  useEffect(() => {
    pushData();
  });

  function sendChildData(idx, data) {
    taskData[idx] = {
      delete: data.delete,
      details: data.details,
      followUpType: data.followUpType,
      assignedUsers: data.assignedUsers,
      weeks: data.weeks,
    };
    setTaskData(taskData);
  }

  function pushData() {
    sendData(index, {
      company,
      activityType,
      callLogDate,
      details,
      selectedFile,
      deleted: !show,
      taskEntries: taskData,
    });
  }

  function renderRows() {
    return tasks.map((item, idx) => (
      <TaskEntry
        task={item}
        key={`taskEntry${idx}`}
        users={users}
        whenInWeeks={whenInWeeks}
        followUpTypes={followUpTypes}
        index={idx}
        sendData={sendChildData}
      />
    ));
  }

  const addTask = () => {
    tasks.push({ item: 1 });
    setTasks(tasks);
    setCounter(counter => counter + 1);
    resetVerified();
  };

  const updateDetails = (event) => {
    setDetails(event.target.value);
  };

  function deleteItem() {
    setShow(false);
  }

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const removeFileHandler = () => {
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="callLogItem" id={`callLogItem${index}`}>
      <Row>
        <Col md={4}>
          <DropdownList
            placeholder="Select Company"
            data={companies}
            textField="name"
            valueField="id"
            value={company}
            onChange={nextValue => setCompany(nextValue)}
            caseSensitive={false}
            filter
          />
          <br />
          <DropdownList
            placeholder="Select Activity Type"
            data={activityTypes}
            textField="name"
            valueField="value"
            value={activityType}
            onChange={nextValue => setActivityType(nextValue)}
            caseSensitive={false}
            filter
          />
          <br />
          <DatePicker
            placeholder="mm/dd/yyyy"
            value={callLogDate}
            onChange={nextValue => setCallLogDate(nextValue)}
          />
        </Col>
        <Col md={1}>
          <button
            type="button"
            className="btn btn-primary"
            title="Add New Company"
            onClick={addCompany}
          >
            +
          </button>
        </Col>
        <Col md={7}>
          <textarea
            className="form-control"
            rows={6}
            placeholder="Description/Discussion..."
            value={details}
            onChange={event => updateDetails(event)}
          />
          <Row className="mt-2">
            <Col md={3}>
              <div className="form-group">
                {isFilePicked ? (
                  <button
                    type="button"
                    onClick={e => removeFileHandler(e)}
                    className="btn btn-danger"
                  >
                    Remove File
                  </button>
                ) : (
                
                  <label
                    for={`singleFile${index}`}
                    className="control-label btn-primary btn"
                  >
                    <FileDocumentIcon size={21} />
                    &nbsp;
                    Upload File
                    <input
                      type="file"
                      name={`singleFile${index}`}
                      id={`singleFile${index}`}
                      style={{
                        width: 0.1,
                        height: 0.1,
                        opacity: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={e => changeHandler(e)}
                      />
                    </label>
                )}
              </div>
            </Col>
            <Col md={9}>
                {isFilePicked ? (
                  <div>
                    <p>Filename: {selectedFile.name}</p>
                    <p>Filetype: {selectedFile.type}</p>
                    <p>Size in bytes: {selectedFile.size}</p>
                  </div>
                ) : (
                  <p>Select a file</p>
                )}
            </Col>
          </Row>
          
        </Col>
      </Row>
      <fieldset>
        <Row className="mt-4">
          <Col>
            <h6>Create Follow-Ups for this Call Log entry.</h6>
          </Col>
        </Row>
        {list}
        <Row className="mb-4">
          <Col>
            <button
              type="button"
              onClick={addTask}
              className="btn btn-secondary"
            >
              Add Task Follow-Up
            </button>
          </Col>
        </Row>
      </fieldset>
      <Row>
        <Col className="text-right">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem()}
          >
            Delete
          </button>
        </Col>
      </Row>
      <hr size="1" />
    </div>
  );
}

CallLogItem.propTypes = {
  callLog: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  activityTypes: PropTypes.array.isRequired,
  followUpTypes: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  whenInWeeks: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  deleteCallLog: PropTypes.func,
  addCompany: PropTypes.func,
};
