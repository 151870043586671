import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  FormGroup,
  Input,
  Label,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Toast,
} from 'reactstrap';
import {
  DropdownList,
} from 'react-widgets';
import { ToastContainer, toast } from 'react-toastify';

// components
import Error from '../../components/Error';
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import UrlRow from '../../components/map/urlRow';

// api
import { getMap, saveMap, deleteUrl, deleteMap, importFile } from '../../api/map';
import { listCompaniesForDropDown } from '../../api/company';

// css
import 'react-toastify/dist/ReactToastify.css'

const MapForm = ({ isNew }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [map, setMap] = useState({
    id: null,
    active: true,
    company: {
      name: '-- Select A Company --',
      id: null,
    },
    urls: [],
  });
  const [companies, setCompanies] = useState([])
  const [errors, setErrors] = useState([]);
  const [importResults, setImportResults] = useState(null)

  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  }

  const [pageTitle, setPageTitle] = useState('Map Monitoring Configuration New');

  useEffect(() => {
    if (id === undefined || id === null) {
      listCompaniesForDropDown()
        .then(data => {
          setCompanies(data.companies)
        })
      } else {
        getMap({ id })
          .then(res => {
            setMap(res.map)
          })
      }
  }, [id])

  useEffect(() => {
    if (!isNew) {
      setPageTitle('Map Monitoring Configuration Edit');
    }
  }, [isNew])

  // actions
  const handleCompanyChange = (data) => {
    setMap({...map, company: { name: data.name, id: data.id }})
  }

  const handleSaveMap = (e, stay) => {
    setErrors([])
    if (map.company.id === null) {
      setErrors(["Must select a company"])
    } else {
      saveMap({ id: map.id, data: { map }})
        .then(res => {
          if (res.errors.length > 0) {
            setErrors(res.errors)
          } else if (stay) {
            toast("Data Saved.")
            navigate(`/map/${res.map.id}`)
          } else {
            navigate('/map')
          }
        })
    }
  }

  const handleDeleteMap = (e) => {
    setErrors([])
    if (window.confirm("Are you sure you want to delete this?\n\nCannot be undone!")) {
      deleteMap({ id })
        .then(res => {
          if (res.errors.length > 0) {
            setErrors(res.errors)
          } else {
            toast("Map deleted successfully")
            setTimeout(() => {
              navigate('/map')
            }, 2000)
          }
        })
    }
  }

  const handleImportFile = (e) => {
    setErrors([])
    const file = document.getElementById('csvFile').files[0];
    if (file === undefined) {
      setErrors(["Must provide a file to import."])
      return;
    }
    
    let formData = new FormData();
    formData.append("map_id", id)
    formData.append('file', file, file.name);
    console.log(formData);
    
    importFile({ id, data: formData })
    .then(res => {
      setMap({...map, urls: res.map.urls})
      setImportResults(res.results.data)
      toast("File Import Completed.")
    })
  }

  const handleDeleteUrl = (e, urlId) => {
    if (window.confirm("Are you sure you want to delete this URL?")) {
      const data = {
        url_id: urlId,
      }
      console.log(data);
      
      deleteUrl({ id, urlId })
      .then(res => {
        setMap({...map, urls: res.map.urls})
        toast("URL deleted successfully")
      })
    }
  }

  // const handleImportUrls = (e) => {
  //   setErrors([])
  //   const urls = document.getElementById('urls').value.split('\n');
  //   if (urls.length === 0 || urls[0] === "") {
  //     setErrors(["Must provide at least one URL to import."])
  //     return;
  //   }
    
  //   const data = {
  //     urls,
  //   }
  //   console.log(data);
    
  //   importUrls({ id, data })
  //   .then(res => {
  //     setMap({...map, urls: res.map.urls})
  //     setImportResults(res.results)
  //     const text = res.results.error_messages.map(item => {
  //       if (item.errors[0] !== "Url is already taken") {
  //         return item.url
  //       }
  //     }).filter(u => u !== undefined).join('\n')

  //     document.getElementById('urls').value = text;
  //   })
  // }

  // renders

  const renderForm = () => {
    return (
      <>
        <Error errors={errors} />
        {isNew ? (
          <FormGroup>
            <DropdownList
              placeholder='Company'
              data={companies}
              textField='name'
              valueField='id'
              value={map.company}
              onChange={value => handleCompanyChange(value)}
            />
          </FormGroup>
        ) : (
          <>
            {map.company.name}
          </>
        )}
        <FormGroup floating>
          <Input
            type="text"
            placeholder="Name"
            value={map.name}
            onChange={e => setMap({...map, name: e.target.value})}
          />
          <Label
            for="name"
          >
            Map - Name
          </Label>
        </FormGroup>
        <FormGroup switch>
          <Input
            type="switch"
            checked={map.active}
            onChange={e => setMap({...map, active: e.target.checked})}
          />
          <Label check>Active?</Label>
        </FormGroup>

        <Row>
          <Col className="text-end">
            <button
              className="btn btn-primary me-4"
              onClick={e => handleSaveMap(e, true)}
              >
              Save & Stay
            </button>
    
            <button
              className="btn btn-secondary me-4"
              onClick={e => handleSaveMap(e, false)}
            >
              Save
            </button>

            <button
              className="btn btn-secondary"
              onClick={() => navigate('/map')}
            >
              Back
            </button>
          </Col>
        </Row>
      </>
    )
  }

  const renderUrlForm = () => {
    if (id === undefined || id === null) {
      return (
        <Alert color="warning">
          Please select a company and save before importing URLs.
        </Alert>
      )
    }

    return (
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">Import</AccordionHeader>
          <AccordionBody accordionId='1'>
            <FormGroup>
              <Input
                type="file"
                id="csvFile"
                name="csvFile"
              />
            </FormGroup>
            <Row className="mb-4">
              <Col className="text-end">
                <button
                  className="btn btn-primary"
                  onClick={e => handleImportFile(e)}
                >
                  Import
                </button>
              </Col>
            </Row>
            {importResults !== null && (
              <Alert color="success">
                <Row>
                  <Col>
                    <h4>Results:</h4>
                    <hr />
                    Imported Urls: {importResults.imported}
                    <hr />
                    Urls not imported: {importResults.issues}
                    <br />
                    <br />
                    {importResults.issues > 0 && (
                      <ol>
                        {importResults.issue_log.map((error, index) => (
                          <li key={index}>
                            <strong>{error.sku}</strong>
                            {' '}
                            <u>{error.url}</u>
                            <br />
                            {error.issue}
                          </li>
                        ))}
                      </ol>
                    )}
                  </Col>
                </Row>
              </Alert>
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    )
  }

  const renderUrls = () => {
    if (map.urls.length === 0) {
      return (
        <Alert color="info">
          No Urls found.
        </Alert>
      )
    }

    return map.urls.map((url) => (
      <UrlRow
        url={url}
        key={url.id}
        deleteUrl={handleDeleteUrl}
      />
    ))
  }

  return (
    <>
      <PageHeader title={pageTitle} />
      <div class="text-end mb-2">
        <button
          className="btn btn-danger btn-xs"
          onClick={e => handleDeleteMap(e)}
        >
          Delete?
        </button>
      </div>
      <CardWrapper header="Company Information">
        {renderForm()}
        <hr />
        {renderUrlForm()}
      </CardWrapper>
      <CardWrapper header="Map Urls">
        {renderUrls()}
      </CardWrapper>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  )
}

export default MapForm