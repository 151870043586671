import api from './init';
import returnError from './returnError';

export function getTaskList(filters) {
  const url = `/api/v1/tasks.json?page=${filters.currentPage}&priority=${filters.priorityFilter}&status=${filters.statusFilter}&user=${filters.userFilter}`;
  console.log(url);
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTask(id) {
  const url = `/api/v1/tasks/${id}.json`;
  console.log(url);
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function deleteTask(id) {
  const url = `/api/v1/tasks/${id}.json`;
  return api
    .delete(url)
    .then((response) => {
      return response.data.action;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getDocList(data) {
  const url = `/api/v1/tasks/${data.id}/docs.json`;
  return api
    .get(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteDoc(data) {
  const url = `/api/v1/tasks/${data.id}/docs/${data.docId}.json`;
  return api
    .delete(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveTask(id, task) {
  let url = `/api/v1/tasks.json`;
  let method = 'post';
  if (id !== undefined && id !== null) {
    url = `/api/v1/tasks/${id}.json`;
    method = 'put';
  }

  return api[method](url, task).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error);
    return returnError();
  })
}
