import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from 'reactstrap';

import MenuIcon from 'mdi-react/MenuIcon';
import MessageIcon from 'mdi-react/MessageIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import PowerIcon from 'mdi-react/PowerIcon';
import ArrowRightBoldIcon from 'mdi-react/ArrowRightBoldIcon'

import Search from './Search';

import { signOut } from '../api/auth';

import logo from '../img/aei-white.png';

import styles from './Header.module.scss';

const Header = (props) => {
  const navigate = useNavigate();

  // state
  const [showNotifications, setShowNotifications] = useState(false);
  const [userOffCanvas, setUserOffCanvas] = useState(false);

  const renderAlerts = () => {
    const { alerts } = props;
    let html = [];
    if (alerts.length === 0) {
      html.push(
        <div className="main-content text-center p-2">
          <span>
            No New Notifications
          </span>
        </div>
      );
    } else {
      html.push(
        alerts.map(alert => (
          <Link to={alert.link}>
            <div className="main-content">
              <span>
                {alert.message}
              </span>
            </div>
          </Link>
        ))
      );
    }
    html.push(
      <Link to='/alerts'>
        <div className="main-content">
          <span>
            View All
            &nbsp;
            <ArrowRightBoldIcon size={16} />
          </span>
        </div>
      </Link>
    );
    
    return html;
  }

  const goHome = (event) => {
    event.preventDefault();
    navigate('/dashboard');
  }

  const renderNotificationsOffCanvas = () => {
    return (
      <Offcanvas
        isOpen={showNotifications}
        direction="end"
        scrollable
        toggle={() => setShowNotifications(!showNotifications)}
      >
        <OffcanvasHeader
          toggle={() => setShowNotifications(!showNotifications)}
        >
          Noficiations
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="slimScrollDiv">
            <div className="message-center">
              {renderAlerts()}
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    )
  }

  const renderUserOffCanvas = () => {
    return (
      <Offcanvas
        isOpen={userOffCanvas}
        direction='end'
        scrollable
        toggle={() => setUserOffCanvas(!userOffCanvas)}
        >
        <OffcanvasHeader
          toggle={() => setUserOffCanvas(!userOffCanvas)}
        >
          User Actions
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="text-center">
            {props.user !== null &&
              <img src={props.user.avatar} alt='User' className="profile-pic" />
            }
          </div>
          <hr />
          <Link to="/profile">
            <AccountIcon size={16} />
            <span className="p-l-10">
              My Profile
            </span>
          </Link>
          <hr />
          <Link to="/logout" onClick={signOut}>
            <PowerIcon size={16} />
            <span className="p-l-10">
              Logout
            </span>
          </Link>
        </OffcanvasBody>
      </Offcanvas>
    )
  }

  return (
    <>
      <header className="topbar is_stuck">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header">
            <a
              href
              className="navbar-brand"
              onClick={(event) => goHome(event)}
            >
              <span className="text-white the-hub-name">The Hub</span>
              <b>
                <img
                  src={logo}
                  alt="AEI Hub"
                  className="light-logo"
                  height="35"
                />
              </b>
            </a>
          </div>

          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item">
                <a
                  href
                  type="button"
                  onClick={props.updateSidebar}
                  className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                >
                  <MenuIcon />
                </a>
              </li>
              <li className="nav-item">
                <a
                  href
                  onClick={props.updateSidebar}
                  className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"
                >
                  <i className="ti-menu" />
                  <MenuIcon />
                </a>
              </li>
              <Search />
            </ul>
          </div>
          <ul className="navbar-nav my-lg-0">
            <li className="nav-item">
              <button
                type="button"
                className={['nav-link', styles.clearButton].join(' ')}
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <MessageIcon />
                <div className="notify">
                  {props.hasNew && (
                    [
                      <span className="heartbit"></span>,
                      <span className="point"></span>
                    ]
                  )}
                </div>
              </button>
            </li>

            <li className="nav-item">
              <button
                type="button"
                className={['nav-link', styles.clearButton].join(' ')}
                onClick={() => setUserOffCanvas(!userOffCanvas)}
              >
                {props.user !== null &&
                  <img src={props.user.avatar} alt='User' className="profile-pic" />
                }
              </button>
            </li>
          
            {/* USER */}
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link to="/profile">
                    <AccountIcon size={16} />
                    <span className="p-l-10">
                      My Profile
                    </span>
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/logout" onClick={signOut}>
                    <PowerIcon size={16} />
                    <span className="p-l-10">
                      Logout
                    </span>
                    </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </ul>
        </nav>
      </header>
      <div className="headerPad"></div>
      {renderNotificationsOffCanvas()}
      {renderUserOffCanvas()}
    </>
  )
}

export default Header;
