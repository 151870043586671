import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Input
} from 'reactstrap';
import CheckBoldIcon from 'mdi-react/CheckBoldIcon';
import CloseThickIcon from 'mdi-react/CloseThickIcon';

// components
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';

// api
import {
  getReviews,
  updateUrlSnapshot
} from '../../api/map';

// styles

const MapReview = () => {
  const { id } = useParams();

  const [urlSnapshots, setUrlSnapshots] = useState([]);
  const [urlSnapshot, setUrlSnapshot] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    getReviews({ id })
    .then(res => {
      setUrlSnapshots(res.urlSnapshots)
    })
  }, [id])
  
  // actions
  const toggle = () => setIsOpen(!isOpen);

  const handleUpdateSnapshot = (status) => {
    updateUrlSnapshot({
      mapId: id,
      urlSnapshot: {
        ...urlSnapshot,
        status
      }
    })
  }


  // renders

  const renderSnapshots = () => {
    if (urlSnapshots.length === 0) {
      <Row>
        <Col xs={12} md={12}>
          ...
        </Col>
      </Row>
    }
  }

  return (
    <>
      <PageHeader title="Map Url Snapshot Review" />
      <CardWrapper>

      </CardWrapper>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        fullscreen={true}
        scrollable={true}
      >
        <ModalHeader toggle={toggle}>
          Review
        </ModalHeader>
        <ModalBody>
          {urlSnapshot !== null && (
            <>
              <img
                src={urlSnapshot.imageUrl}
                alt=""
              />
              <Row className="mt-4">
                <Col xs={12} md={12}>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4} md={4} className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={toggle}
                  >
                    <CloseThickIcon size={24} />
                  </button>
                </Col>
                <Col xs={4} md={4}></Col>
                <Col xs={4} md={4} className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={toggle}
                  >
                    <CheckBoldIcon size={24} />
                  </button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>Product</Col>
                <Col xs={12} md={8}>{urlSnapshot.sku}</Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>URL</Col>
                <Col xs={12} md={8}>{urlSnapshot.url}</Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>Date</Col>
                <Col xs={12} md={8}>{urlSnapshot.createdAt}</Col>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default MapReview
