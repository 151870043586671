import React from 'react';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Pagination from 'react-js-pagination';

import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';

import PageHeader from '../../../components/PageHeader';
import CardWrapper from '../../../components/CardWrapper';
import CompanyItem from '../../../components/admin/companies/CompanyItem';

import { listCompanies } from '../../../api/company';
import { fullUserListByRole, fullUserList } from '../../../api/user';

export default class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      companies: [],
      users: [],
      stars: '',
      userFilter: null,
      loading: false,
    };
  }

  componentWillMount() {
    this.userList();
    this.companies();
  }

  companies() {
    this.setState({ loading: true });
    const { stars, currentPage, search, userFilter } = this.state;
    const data = {
      currentPage,
      search,
      all: 1,
      stars,
      user: userFilter,
    };
    listCompanies(data)
      .then((response) => {
        this.setState({
          companies: response.companies,
          currentPage: response.details.current_page,
          totalPages: response.details.total_pages,
          totalRecords: response.details.total_records,
          loading: false,
        });
      });
  }

  userList() {
    fullUserListByRole('sales_rep')
      .then((users) => {
        users.unshift({id: null, name: '-- NONE --'});
        this.setState({
          users
        });
      });
  }

  updateSearch = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.setState({
      search: value,
      currentPage: 1,
    }, () => this.companies());
  }

  updateStars = (stars) => {
    this.setState({
      stars,
      currentPage: 1
    }, () => this.companies());
  }

  isActiveStars = num => {
    const { stars } = this.state;
    if (stars === num) {
      return "btn btn-primary active";
    }
    return "btn btn-primary";
  }

  setUserFilter(value, name) {
    this.setState(
      {
        [name]: value.id
      },
      () => this.companies()
    );
  }

  renderHeader = () => {
    const { userFilter, users } = this.state;
    return (
      [
        <Row>
          <Col md={3}>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="search by name"
              className="form-control" onChange={(event) => this.updateSearch(event)} />
          </Col>
           <Col md={3}>
              <button
                className={this.isActiveStars("")}
                onClick={() => this.updateStars("")}
                title="Show All"
              >
                <StarOutlineIcon size={16} />
                All
              </button>
              &nbsp;
              <button
                className={this.isActiveStars(1)}
                onClick={() => this.updateStars(1)}
                title="Prospect"
              >
                <StarIcon size={16} />
              </button>
              &nbsp;
              <button
                className={this.isActiveStars(2)}
                onClick={() => this.updateStars(2)}
                title="Customer"
              >
                <StarIcon size={16} />
                <StarIcon size={16} />
              </button>
              &nbsp;
              <button
                className={this.isActiveStars(3)}
                onClick={() => this.updateStars(3)}
                title="Top Customer"
              >
                <StarIcon size={16} />
                <StarIcon size={16} />
                <StarIcon size={16} />
              </button>
            </Col>
            <Col md={3}>
              <DropdownList
                filter
                value={userFilter}
                data={users}
                placeholder="Users"
                onChange={value => this.setUserFilter(value, 'userFilter')}
                textField="name"
                valueField="id"
              />
            </Col>
        </Row>,
        <hr />,
        <Row>
          <Col md={4}><strong>Name</strong></Col>
          <Col md={2}><strong>Stars</strong></Col>
          <Col md={1}><strong>AS400</strong></Col>
          <Col md={3}><strong>Sales Rep.</strong></Col>
          <Col md={2}><strong>Active</strong></Col>
        </Row>,
        <hr />
      ]
    )
  }

  renderCompanies() {
    const { companies, users, loading } = this.state;
    if (loading) { return null; }
    if (companies.length == 0) {
      return (
        <Row>
          <Col className="text-center">
            No Companies Found
          </Col>
        </Row>
      );
    }
    return (
      companies.map(item => (
        <CompanyItem company={item} userList={users} key={item.id} />
      ))
    );
  }

  handlePageChange = (page) => {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    this.setState({
      currentPage: page
    }, () => this.companies())
  }

  renderLoading() {
    const { loading } = this.state;
    if (loading) {
      return (
        <Row>
          <Col className="text-center">
            Loading...
          </Col>
        </Row>
      );
    }
    return null;
  }

  render() {
    const { currentPage, totalRecords } = this.state;
    return (
      [
        <PageHeader title="Admin : Company" />,
        <CardWrapper>
          {this.renderHeader()}
          {this.renderLoading()}
          {this.renderCompanies()}
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={18}
            totalItemsCount={totalRecords}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
            innerClass={'pagination'}
            itemClass={'paginate_button page-item'}
            linkClass={'page-link'}
          />
        </CardWrapper>,
      ]
    );
  }
}