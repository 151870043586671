import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Entry from './screens/Entry';
import Login from './screens/login/Login';
import Forgot from './screens/login/Forgot';
import Password from './screens/login/Password';

// css 
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-widgets/styles.css";

// const VERSION = '1.0.0';

const App = () => (
  <div>
    <Helmet>
      <title>The Hub</title>
    </Helmet>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/password/:id" element={<Password />} />
      <Route path="/*" element={<Entry />} />
    </Routes>
  </div>
);

export default App;
