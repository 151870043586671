import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { DropdownList } from 'react-widgets';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// components
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';

// api
import { getEventList } from '../../api/calendar';
import { fullUserListByRole } from '../../api/user';
import { priorityColor, inValidRole } from '../../libs/utils';

// css
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map(k => Views[k])

// filters
const defaultFilters = { filters: {
    startDate: new moment().startOf('month').format("YYYY-MM-DD 00:00:00"),
    endDate: new moment().endOf("month").format("YYYY-MM-DD 23:59:59"),
    user: null,
    showEvents: true,
    showTasks: true,
    currentEventDate: new Date(),
    currentView: 'month',
  }
}

const useCalendarFilterStorage = (storageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultFilters
  )

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

// let calendarFilters = getCalendarFilters();

const CalendarView = () => {
  const navigate = useNavigate();
  // const [counter, setCounter] = useState(0)
  const [events, setEvents] = useState([]);
  const pageTitle = 'Calendar';
  const [errorMessage, setErrorMessage] = useState('');
  const [userList, setUserList] = useState([]);

  // filters
  const [calendarFilters, setCalendarFilters] = useCalendarFilterStorage('calendar:filters')

  useEffect(() => {
    // loadEvents()
    loadUserList()
  }, [])

  useEffect(() => {
    loadEvents()
  }, [calendarFilters])

  const loadEvents = () => {
    console.log('filters', calendarFilters.filters)
    getEventList(calendarFilters.filters)
      .then((data) => {
        console.log(data);
        if (data.errors) {
          setErrorMessage(data.errors[0]);
        } else {
          const events = fixDates(data.events);
          setEvents(events);
          setErrorMessage('');
        }
      });
  }

  const fixDates = (events) => {
    events.map((event) => {
      event.start = new moment(event.start).toDate();
      event.end = new moment(event.end).toDate();
    });
    return events;
  }

  const loadUserList = () => {
    let role = '';
    if (inValidRole(['admin', 'sales_manager', 'sales_admin'], localStorage)) {
      role = ['admin', 'sales_rep', 'sales_admin', 'sales_manager', 'csr_manager', 'csr'];
    } else if (inValidRole(['csr_manager'], localStorage)) {
      role = ['csr_manager', 'csr'];
    }

    if (role !== '') {
      fullUserListByRole(role)
        .then((data) => {
          data.unshift({ name: '-- All --', id: '' });
          setUserList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const updateCalendarView = (event) => {
    let startDate = null;
    let endDate = null;

    if (calendarFilters.filters.currentView === 'day') {
      startDate = new moment(calendarFilters.filters.currentEventDate).format("YYYY-MM-DD 00:00:00");
      endDate = new moment(calendarFilters.filters.currentEventDate).format("YYYY-MM-DD 23:59:59");
    } else if (calendarFilters.filters.currentView === 'month' || calendarFilters.filters.currentView === 'agenda') {
      startDate = new moment(event).startOf('month').format("YYYY-MM-DD 00:00:00");
      endDate = new moment(event).endOf("month").format("YYYY-MM-DD 23:59:59");
    }

    console.log(`Date: ${startDate} - ${endDate}`)
    setCalendarFilters({
      ...calendarFilters,
      filters: {
        ...calendarFilters.filters,
        startDate: startDate,
        endDate: endDate,
        currentEventDate: event
      }
    })
  }

  const changeRange = (event) => {
    console.log("Event", event)
    // calendarFilters.filters.currentEventDate = event;
    updateCalendarView(event);
  }

  const updateView = (event) => {
    calendarFilters.filters.currentView = event;
    updateCalendarView(calendarFilters.filters.currentEventDate);
  }

  const redirectToTaskOrEvent = event => {
    let url = event.calendar_type === 'event' ? `/events/${event.id}` : `/tasks/${event.id}`;
    navigate(url);
  }

  const renderEvent = event => {
    const { id, title, priority, assigned_to, event_type, calendar_type } = event.event;

    return (
      [
        <span>
          <label className="label label-grey">
            {assigned_to.join(', ')}
          </label>
          {title}
        </span>,
      ]
    );
  }

  const updateShowSettings = event => {
    const { target } = event;
    const { name } = target;

    if (name === "showEvents") {
      const showEvents = !calendarFilters.filters.showEvents;
      console.log(showEvents)
      const v = { ...calendarFilters.filters, showEvents }
      setCalendarFilters({...calendarFilters, filters: v});
    }
    else if (name === "showTasks") {
      const showTasks = !calendarFilters.filters.showTasks;
      const v = { ...calendarFilters.filters, showTasks }
      setCalendarFilters({...calendarFilters, filters: v });
    }
  }

  const updateUserFilter = (value, name) => {
    const val = { ...calendarFilters.filters, user: value };
    setCalendarFilters({...calendarFilters, filters: val});
  }

  const eventStyleGetter = (event) => {
    const color = event.calendar_type === "task" ? '#8ea235' : '#6352ce';
    return {
      style: {
        backgroundColor: color,
        color: '#ffffff'
      },
    };
  }

  return (
    [
      <Helmet>
        <title>The Hub - Calendar</title>
      </Helmet>,
      <PageHeader title={pageTitle} />,
      <CardWrapper>
        {errorMessage !== '' && (
          <div>
            {errorMessage}
          </div>
        )}
        <Row>
          <Col md={2}>
            Filters
          </Col>
          <Col md={3}>
            <div className="form-group">
              <input
                type="checkbox"
                value="1"
                name="showTasks"
                id="showTasks"
                checked={calendarFilters.filters.showTasks}
                onClick={(e) => updateShowSettings(e)}
              />
              {' '}
              <label forHtml="showTasks">Show Tasks</label>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <input
                type="checkbox"
                value="1"
                name="showEvents"
                id="showEvents"
                checked={calendarFilters.filters.showEvents}
                onClick={(e) => updateShowSettings(e)}
              />
              {' '}
              <label forHtml="showEvents">
                Show Events
              </label>
            </div>
          </Col>
          <Col md={4}>
            {userList.length > 0 && (
              <DropdownList
                filter
                value={calendarFilters.filters.user}
                data={userList}
                placeholder="Users"
                onChange={value => updateUserFilter(value, 'userFilter')}
                textField="name"
                valueField="id"
              />
            )}
          </Col>
        </Row>
        <hr />
        <Calendar
          localizer={localizer}
          defaultDate={calendarFilters.filters.currentEventDate}
          defaultView="month"
          views={["month", "agenda", "day"]}
          events={events}
          style={{ height: "150vh" }}
          onNavigate={event => changeRange(event, 'navigate')}
          onSelectEvent={event => redirectToTaskOrEvent(event)}
          onView={event => updateView(event, 'view')}
          components={{
            event: (event) => renderEvent(event)
          }}
          eventPropGetter={eventStyleGetter}
        />
      </CardWrapper>,
      <Row>
        <Col>&nbsp;</Col>
      </Row>,
    ]
  );
}

export default CalendarView;


// onView={event => this.updateView(event, 'view')}