import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Quote from './Quote';
import QuoteEdit from './QuoteEdit';
import ExpiredQuote from './ExpiredQuote';
import Report from './Report';
import Pending from './Pending';

const QuoteRoutes = () => {
  return (
    <>
      <Helmet>
        <title>The Hub - Quotes</title>
      </Helmet>
      <Routes>
        <Route path="pending" element={<Pending />} />
        <Route path="report" element={<Report />} />
        <Route path="new" element={<Quote />} />
        <Route path=":id" element={<QuoteEdit />} />
        <Route path="expired/:id" element={<ExpiredQuote />} />
        <Route path="" element={<List />} />
      </Routes>
    </>
  );
}

export default QuoteRoutes;
