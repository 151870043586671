import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';

const Address = ({ address }) => {
  const field = (value) => {
    if (value === null || value === '') {
      return null;
    }
    return (
      <>
        {value}
        <br />
      </>
    )
  }

  return (
    <Row>
      <Col>
        <strong>{address.name}</strong>
        <br />
        {field(address.attn)}
        {address.street_address}
        <br />
        {field(address.street_address_2)}
        {address.city}
        {', '}
        {address.state}
        {' '}
        {address.zip_code}
        <br />
        {field(address.country)}
        {field(address.telephone)}

      </Col>
    </Row>
  )
}

Address.propTypes = {
  address: PropTypes.object.isRequired
}

// Address.propTypes = {
//   address: PropTypes.shape({
//     name: PropTypes.string,
//     attn: PropTypes.string,
//     street_address: PropTypes.string,
//     street_address_2: PropTypes.string,
//     city: PropTypes.string,
//     state: PropTypes.string,
//     zip_code: PropTypes.string,
//     country: PropTypes.string,
//     telephone: PropTypes.string,
//   })
// }

export default Address;