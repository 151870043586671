import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { LoadingIcon } from 'mdi-react/LoadingIcon';
import Moment from 'react-moment';
import moment from 'moment';

import { renderStars } from '../../components/helpers';

class CompanyItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      data: [],
    };
  }

  componentDidMount() {
    this.setState({ 
      id: this.props.company.id,
      data: this.props.company.sales_data,
    });
  }

  renderTelephone() {
    const { company } = this.props;
    if (company.telephone !== '' && company.telephone !== null) {
      return (
        <React.Fragment>
          <abbr title="Phone">P:</abbr>
          &nbsp;
          {company.telephone}
        </React.Fragment>
      );
    }
    return null;
  }

  renderAddressInfo() {
    const { company } = this.props;
    const address = [company.address, company.city, company.state, company.zip_code].filter(x => x !== '').join(' ');
    if (address !== '') {
      return (
        <span>
          {address}
        </span>
      );
    }
    return null;
  }

  renderClassification = (company) => {
    if (company.classification !== undefined) {
      const { name } = company.classification;
      return [
        <span className="label label-purple">
          {name}
        </span>,
        <br />,
      ];
    }
    return null;
  }

  renderCompanyAS400ID = company => {
    if (company.as400_id === undefined || company.as400_id === null || company.as400_id === '' || company.as400_id === 0) { return null; }

    return (
      <span>
        &nbsp;
        (
          {company.as400_id}
        )
      </span>
    );
  }

  layout = () => {
    const { layout } = this.props;
    if (layout === "card") {
      return "col-md-4 col-lg-4";
    }
    return "col-12";
  }

  render() {
    const { company } = this.props;
    const { data, chartLoading } = this.state;

    return [
      <Row data-id={company.id}>
        <Col md={2} className="text-center">
          <Link
            to={{
              pathname: `/companies/${company.id}`
            }}
          >
            <img
              src={company.logo}
              alt={company.name}
              className="img-circle img-responsive"
              style={{ width: 60, height: 60 }}
            />
          </Link>
        </Col>
        <Col md={5}>
          {renderStars(company.stars)}{" "}
          <Link
            to={{
              pathname: `/companies/${company.id}`
            }}
          >
            {company.name}
            {this.renderCompanyAS400ID(company)}
          </Link>
          <br />
          {this.renderClassification(company)}
          <span className="label label-primary">
            Contacts: {company.contacts}
          </span>{" "}
          <span className="label label-primary">
            Activities: {company.activities}
          </span>
          <br />
          <small>
            Created:
            {' '}
            <Moment format="MM/DD/YYYY h:mm A">
              {company.created_at}
            </Moment>
          </small>
        </Col>
        <Col md={5}>
          <address>
            {this.renderAddressInfo()}
            <br />
            <br />
            {this.renderTelephone()}
          </address>
        </Col>
      </Row>,
      <hr />
    ];
  }
}

CompanyItem.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

CompanyItem.defaultProps = {
  company: null,
};

export default CompanyItem;
