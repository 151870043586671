import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Alert, TabContent, TabPane, NavItem, NavLink, Nav } from 'reactstrap';
import { Multiselect } from 'react-widgets';
import classnames from 'classnames';

import WrenchIcon from 'mdi-react/WrenchIcon';

import Loading from '../Loading';
import SalesHistoryTable from './Table';
import SalesHistoryTableGroup from './TableGroup';
import SalesHistoryTableMonth from './TableMonthly';

import { getSalesItemsAndYears, getSalesHistory } from '../../api/company';

export default function SalesHistories({ companyId }) {
  const [products, setProducts] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductGroups, setSelectedProductGroups] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('item');
  const [activeReportView, setActiveReportView] = useState('');

  useEffect(() => {
    console.log(`Company Id: ${companyId}`);
    if (companyId !== undefined && companyId !== null && companyId !== '') {
      getSalesItemsAndYears(companyId)
        .then((data) => {
          setProducts(data.products);
          setProductGroups(data.productGroups);
          setYears(data.years);
        });
    }
  }, [companyId]);

  const buildReport = () => {
    setLoading(true);
    setShowReport(false);
    setActiveReportView(activeTab);

    const dataSet = activeTab === 'item' ? selectedProducts.map(item => item.id) : selectedProductGroups.map(item => item.id);

    getSalesHistory(companyId, dataSet, selectedYears, activeTab)
      .then((data) => {
        setReport(data);
        setLoading(false);
        setShowReport(true);
      });
  };

  const buildTabs = () => {
    return (
      <Nav tabs className="profile-tab">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 'item'
            })}
            onClick={() => {
              setActiveTab('item');
            }}
          >
            Products (Items)
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 'group'
            })}
            onClick={() => {
              setActiveTab('group');
            }}
          >
            By Item Class
          </NavLink>
        </NavItem>
      </Nav>
    )
  };

  return (
    <>
      <Row>
        <Col>
          <Alert color="info">
            More products & years selected, the longer the report will take to build.
          </Alert>
        </Col>
      </Row>
      {buildTabs()}
      <Row className="mt-4">
        <Col xs={12} md={6} className="form-group">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="item">
              Product:
              {' '}
              <em>(Enter SKU or Name)</em>
              <Multiselect
                data={products}
                textField="fullName"
                dataKey="id"
                filter="contains"
                value={selectedProducts}
                onChange={value => setSelectedProducts(value)}
              />
            </TabPane>
            <TabPane tabId="group">
              Product Groups:
              <Multiselect
                data={productGroups}
                textField="name"
                dataKey="id"
                value={selectedProductGroups}
                onChange={value => setSelectedProductGroups(value)}
              />
            </TabPane>
          </TabContent>          
        </Col>
        <Col xs={12} md={6} className="form-group">
          Years:
          <Multiselect
            data={years}
            onChange={value => setSelectedYears(value)}
          />          
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Button
            color="primary"
            onClick={() => buildReport()}
          >
            <WrenchIcon size={16} />
            {' '}
            Build Report
          </Button>
          <hr />
        </Col>
      </Row>
      {loading && (
        <Loading />
      )}
      {showReport && report !== null && (
        <>
          {activeReportView === 'item' ? (
            <>
              <SalesHistoryTable report={report} />
              <SalesHistoryTableMonth report={report} />
            </>
          ) : (
            <>
              <SalesHistoryTableGroup report={report} />
            </>
          )}
        </>
      )}
    </>
  );
}
