import React, { useState, useEffect, forwardRef, sueRef, useImperativeHandle } from 'react'
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import WrenchIcon from 'mdi-react/WrenchIcon'
import CalculatorIcon from 'mdi-react/CalculatorIcon'

// utils
import { numberToCurrency, validAS400Id } from '../../libs/utils'

// api
import { calculateTax, saveTaxInfo } from '../../api/quote'

const Tax = forwardRef(({ quoteId, quote, company, updateData }, ref) => {
  // const [taxTotal, setTaxTotal] = useState(quote.taxTotal)
  const [taxData, setTaxData] = useState({
    total_tax: quote.taxTotal,
    tax_region: quote.taxRegion,
    tax_rate: quote.taxRate,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const [taxInfo, setTaxInfo] = useState({
    taxExemptNumber: '',
    taxState: '',
  })

  let finalTax = taxData.total_tax

  useImperativeHandle(ref, () => ({
    verifyTax() {
      handleCalculateTax()
    },
  }))

  useEffect(() => {
    if (company.tax === undefined) { return }
    setTaxInfo({
      taxExemptNumber: company.tax.tax_exempt ? company.tax.tax_notes : '',
      taxState: company.tax.tax_state_code,
    })
    // setTaxData({
    //   total_tax: 0,
    //   tax_region: '',
    //   tax_rate: 0,
    // })
  }, [company])

  // useEffect(() => {
  //   finalTax = taxData.total_tax
  // }, [taxData])

  const toggle = () => setIsOpen(!isOpen)

  const handleChange = (e) => {
    const { name, value } = e.target
    setTaxInfo({ ...taxInfo, [name]: value })
  }

  const handleSaveTaxInfo = (e) => {
    const data = {
      tax_cert_or_tax: taxInfo.taxExemptNumber,
      tax_state_code: taxInfo.taxState,
    }
    saveTaxInfo({ id: company.id, tax_info: data })
      .then(res => {
        if (res.success) {
          setErrors([])
          updateData({ action: "company" })
          toggle()
        } else {
          setErrors(res.errors)
        }
      })
  }

  const handleCalculateTax = () => {
    // do the call to calculate the tax based on the info provided.
    const data = {
      id: quoteId,
      company_id: company.id,
      total: quote.total,
      shipping_total: quote.shippingTotal,
      address: quote.shippingAddress.id,
    }

    console.log('data tax', data)

    calculateTax(data)
      .then(res => {
        console.log("response", res)
        if (res.errors.length === 0) {
          setTaxData(res.tax)
          updateData({...res.tax, action: "tax" })
        } else {
          setErrors(res.errors)
        }
      })
  }

  const showBlock = () => {
    if (quote.status === 'declined') { return false }
    if (quote.status === 'approved') { return false }
    return true
  }

  const renderExtraTaxInfo = () => {
    if (company.tax === undefined) { return }
    return (
      <>
        <Row>
          <Col xs={6}>Taxable:</Col>
          <Col xs={6}>{company.tax.taxable ? "Yes" : "No"}</Col>
        </Row>
        <Row>
          <Col xs={6}>Tax Exempt:</Col>
          <Col xs={6}>{company.tax.tax_exempt ? "Yes" : "No"}</Col>
        </Row>
      </>
    )
  }

  const renderTaxInfo = () => {
    if (!validAS400Id(company.as400_id) || company.tax === undefined) {
      return (
        <>
          <Row>
            <Col>
              <p>Must input a valid Tax Exempt Number for the quote to not include tax.</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>Tax Exempt Number:</Col>
            <Col xs={12} className="form-group">
              <input
                placeholder="Tax Exempt Number"
                className="form-control"
                name="taxExemptNumber"
                id="taxExemptNumber"
                onChange={e => handleChange(e)}
                value={taxInfo.taxExemptNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>Tax State:</Col>
            <Col xs={12} className="form-group">
              <input
                placeholder="State (2 letter code)"
                className="form-control"
                name="taxState"
                id="taxState"
                maxLength={2}
                onChange={e => handleChange(e)}
                value={taxInfo.taxState}
              />
            </Col>
          </Row>
          <hr />
          {renderExtraTaxInfo()}
        </>
      )
    }

    return (
      <>
        {renderExtraTaxInfo()}
        <Row>
          <Col xs={6}>Tax State Code:</Col>
          <Col xs={6}>{company.tax.tax_state_code}</Col>
        </Row>
        <Row>
          <Col xs={6}>Notes:</Col>
          <Col xs={6}>{company.tax.tax_notes}</Col>
        </Row>
      </>
    )
  }

  if (company === null || quote === null) { return null }

  return (
    <>
      <Row className="mt-2">
        <Col xs={12} md={5}>&nbsp;</Col>
        <Col xs={12} md={2}>&nbsp;</Col>
        <Col xs={12} md={2} className="text-end">
          {showBlock() ? (
            <>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={toggle}
              >
                <WrenchIcon size={12} />
                {' '}
                Tax
              </button>
              {' '}
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={e => handleCalculateTax(e)}
              >
                <CalculatorIcon size={12} />
                {' '}
                Calculate
              </button>
            </>
          ) : (
            <>
              Tax:
            </>
          )}
        </Col>
        <Col xs={12} md={2} className="text-end">
          {numberToCurrency(taxData.total_tax)}
        </Col>
        <Col xs={12} md={1}>
          {taxData.tax_rate === 0 ? '' : (
            <>
              {taxData.tax_rate * 100}%
              {' '}
              ({taxData.tax_region})
            </>
          )}
          {taxData.tax_region === "Exempt" && (
            <>
              Exempt
            </>
          )}
        </Col>
      </Row>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Tax</ModalHeader>
        <ModalBody>
          {renderTaxInfo()}
        </ModalBody>
        <ModalFooter>
          {(!validAS400Id(company.as400_id) || company.tax === undefined) && (
            <button
              className="btn btn-sm btn-primary"
              onClick={(e) => handleSaveTaxInfo(e)}
            >
              Save
            </button>
          )}
          <button className="btn btn-sm btn-outline-secondary" onClick={toggle}>Close</button>
        </ModalFooter>
      </Modal>
    </>
  )
})

export default Tax