import React, { useState, useMemo } from 'react';
import { Row, Col } from 'reactstrap';

export default function SalesGoal({salesGoal, updateSalesGoal}) {
  const [counter, setCounter] = useState(0);

  useMemo(() => {
  }, [counter]);

  const updateGoal = (e) => {
    updateSalesGoal(salesGoal.year, e.target.value);
  };

  return (
    <Row className="pb-2 pt-2 border border-top-0 border-left-0 border-right-0">
      <Col md={4} className="text-right">{salesGoal.year}</Col>
      <Col md={4}>
        <input
          type="number"
          value={salesGoal.goal}
          name="goal"
          className="form-control"
          onChange={e => updateGoal(e)}
        />
      </Col>
    </Row>
  );
}
