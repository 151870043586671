export function quoteStatusList() {
  return [
    // { name: "All", value: "" },
    { name: "Draft", value: "draft" },
    { name: "Open", value: "open" },
    // { name: "Open (Viewed by customer)", value: "open_viewed" },
    // { name: "Open (Comment)", value: "open_comment" },
    { name: "Approved", value: "approved" },
    { name: "Declined", value: "declined" },
    { name: "Expired", value: "expired" }
  ]
}

export function quoteOrderByList() {
  return [
    { name: "Newest First", value: "created_at,desc" },
    { name: "Oldest First", value: "created_at,asc" },
  ]
}

export function addressTypeList() {
  return [
    { name: "Billing", value: "billing" },
    { name: "Shipping", value: "shipping" },
    { name: "Office", value: "office" },
    { name: "Other", value: "other" },
  ]
}

export function salesBreakdownList() {
  return [
    { name: "Full Year", value: 0, type: "" },
    { name: "January", value: 1, type: "Month" },
    { name: "February", value: 2, type: "Month" },
    { name: "March", value: 3, type: "Month" },
    { name: "April", value: 4, type: "Month" },
    { name: "May", value: 5, type: "Month" },
    { name: "June", value: 6, type: "Month" },
    { name: "July", value: 7, type: "Month" },
    { name: "August", value: 8, type: "Month" },
    { name: "September", value: 9, type: "Month" },
    { name: "October", value: 10, type: "Month" },
    { name: "November", value: 11, type: "Month" },
    { name: "December", value: 12, type: "Month" },
    { name: "Quarter 1", value: 100, type: "Quarter" },
    { name: "Quarter 2", value: 200, type: "Quarter" },
    { name: "Quarter 3", value: 300, type: "Quarter" },
    { name: "Quarter 4", value: 400, type: "Quarter" },
  ]
}


export function stateProvinceList() {
  return [
    { name: ' ', abbreviation: ' ', country: '' },
    { name: 'ALABAMA', abbreviation: 'AL', country: "UNITED STATES" },
    { name: 'ALASKA', abbreviation: 'AK', country: "UNITED STATES" },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS', country: "UNITED STATES" },
    { name: 'ARIZONA', abbreviation: 'AZ', country: "UNITED STATES" },
    { name: 'ARKANSAS', abbreviation: 'AR', country: "UNITED STATES" },
    { name: 'CALIFORNIA', abbreviation: 'CA', country: "UNITED STATES" },
    { name: 'COLORADO', abbreviation: 'CO', country: "UNITED STATES" },
    { name: 'CONNECTICUT', abbreviation: 'CT', country: "UNITED STATES" },
    { name: 'DELAWARE', abbreviation: 'DE', country: "UNITED STATES" },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC', country: "UNITED STATES" },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM', country: "UNITED STATES" },
    { name: 'FLORIDA', abbreviation: 'FL', country: "UNITED STATES" },
    { name: 'GEORGIA', abbreviation: 'GA', country: "UNITED STATES" },
    { name: 'GUAM', abbreviation: 'GU', country: "UNITED STATES" },
    { name: 'HAWAII', abbreviation: 'HI', country: "UNITED STATES" },
    { name: 'IDAHO', abbreviation: 'ID', country: "UNITED STATES" },
    { name: 'ILLINOIS', abbreviation: 'IL', country: "UNITED STATES" },
    { name: 'INDIANA', abbreviation: 'IN', country: "UNITED STATES" },
    { name: 'IOWA', abbreviation: 'IA', country: "UNITED STATES" },
    { name: 'KANSAS', abbreviation: 'KS', country: "UNITED STATES" },
    { name: 'KENTUCKY', abbreviation: 'KY', country: "UNITED STATES" },
    { name: 'LOUISIANA', abbreviation: 'LA', country: "UNITED STATES" },
    { name: 'MAINE', abbreviation: 'ME', country: "UNITED STATES" },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH', country: "UNITED STATES" },
    { name: 'MARYLAND', abbreviation: 'MD', country: "UNITED STATES" },
    { name: 'MASSACHUSETTS', abbreviation: 'MA', country: "UNITED STATES" },
    { name: 'MICHIGAN', abbreviation: 'MI', country: "UNITED STATES" },
    { name: 'MINNESOTA', abbreviation: 'MN', country: "UNITED STATES" },
    { name: 'MISSISSIPPI', abbreviation: 'MS', country: "UNITED STATES" },
    { name: 'MISSOURI', abbreviation: 'MO', country: "UNITED STATES" },
    { name: 'MONTANA', abbreviation: 'MT', country: "UNITED STATES" },
    { name: 'NEBRASKA', abbreviation: 'NE', country: "UNITED STATES" },
    { name: 'NEVADA', abbreviation: 'NV', country: "UNITED STATES" },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH', country: "UNITED STATES" },
    { name: 'NEW JERSEY', abbreviation: 'NJ', country: "UNITED STATES" },
    { name: 'NEW MEXICO', abbreviation: 'NM', country: "UNITED STATES" },
    { name: 'NEW YORK', abbreviation: 'NY', country: "UNITED STATES" },
    { name: 'NORTH CAROLINA', abbreviation: 'NC', country: "UNITED STATES" },
    { name: 'NORTH DAKOTA', abbreviation: 'ND', country: "UNITED STATES" },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP', country: "UNITED STATES" },
    { name: 'OHIO', abbreviation: 'OH', country: "UNITED STATES" },
    { name: 'OKLAHOMA', abbreviation: 'OK', country: "UNITED STATES" },
    { name: 'OREGON', abbreviation: 'OR', country: "UNITED STATES" },
    { name: 'PENNSYLVANIA', abbreviation: 'PA', country: "UNITED STATES" },
    { name: 'PUERTO RICO', abbreviation: 'PR', country: "UNITED STATES" },
    { name: 'RHODE ISLAND', abbreviation: 'RI', country: "UNITED STATES" },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC', country: "UNITED STATES" },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD', country: "UNITED STATES" },
    { name: 'TENNESSEE', abbreviation: 'TN', country: "UNITED STATES" },
    { name: 'TEXAS', abbreviation: 'TX', country: "UNITED STATES" },
    { name: 'UTAH', abbreviation: 'UT', country: "UNITED STATES" },
    { name: 'VERMONT', abbreviation: 'VT', country: "UNITED STATES" },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI', country: "UNITED STATES" },
    { name: 'VIRGINIA', abbreviation: 'VA', country: "UNITED STATES" },
    { name: 'WASHINGTON', abbreviation: 'WA', country: "UNITED STATES" },
    { name: 'WEST VIRGINIA', abbreviation: 'WV', country: "UNITED STATES" },
    { name: 'WISCONSIN', abbreviation: 'WI', country: "UNITED STATES" },
    { name: 'WYOMING', abbreviation: 'WY', country: "UNITED STATES" },
    
    { name: 'ALBERTA', abbreviation: 'AB', country: "CANADA" },
    { name: 'BRITISH COLUMBIA', abbreviation: 'BC', country: "CANADA" },
    { name: 'MANITOBA', abbreviation: 'MB', country: "CANADA" },
    { name: 'NEW BRUNSWICK', abbreviation: 'NB', country: "CANADA" },
    { name: 'NEWFOUNDLAND AND LABRADOR', abbreviation: 'NL', country: "CANADA" },
    { name: 'NOVA SCOTIA', abbreviation: 'NS', country: "CANADA" },
    { name: 'ONTARIO', abbreviation: 'ON', country: "CANADA" },
    { name: 'PRINCE EDWARD ISLAND', abbreviation: 'PE', country: "CANADA" },
    { name: 'QUEBEC', abbreviation: 'QC', country: "CANADA" },
    { name: 'SASKATCHEWAN', abbreviation: 'SK', country: "CANADA" },
    
    { name: 'AGUASCALIENTES', abbreviation: 'AGS.', country: 'MEXICO' },
    { name: 'BAJA CALIFORNIA', abbreviation: 'B.C.', country: 'MEXICO' },
    { name: 'BAJA CALIFORNIA SUR', abbreviation: 'B.C.S.', country: 'MEXICO' },
    { name: 'CAMPECHE', abbreviation: 'CAMP.', country: 'MEXICO' },
    { name: 'CHIAPAS', abbreviation: 'CHIS.', country: 'MEXICO' },
    { name: 'CHIHUAHUA', abbreviation: 'CHIH.', country: 'MEXICO' },
    { name: 'COAHUILA', abbreviation: 'COAH.', country: 'MEXICO' },
    { name: 'COLIMA', abbreviation: 'COL.', country: 'MEXICO' },
    { name: 'MEXICO CITY', abbreviation: 'CDMX', country: 'MEXICO' },
    { name: 'DURANGO', abbreviation: 'DGO.', country: 'MEXICO' },
    { name: 'GUANAJUATO', abbreviation: 'GTO.', country: 'MEXICO' },
    { name: 'GUERRERO', abbreviation: 'GRO.', country: 'MEXICO' },
    { name: 'HIDALGO', abbreviation: 'HGO.', country: 'MEXICO' },
    { name: 'JALISCO', abbreviation: 'JAL.', country: 'MEXICO' },
    { name: 'MÉXICO', abbreviation: 'MÉX.', country: 'MEXICO' },
    { name: 'MICHOACÁN', abbreviation: 'MICH.', country: 'MEXICO' },
    { name: 'MORELOS', abbreviation: 'MOR.', country: 'MEXICO' },
    { name: 'NAYARIT', abbreviation: 'NAY.', country: 'MEXICO' },
    { name: 'NUEVO LEÓN', abbreviation: 'N.L.', country: 'MEXICO' },
    { name: 'OAXACA', abbreviation: 'OAX.', country: 'MEXICO' },
    { name: 'PUEBLA', abbreviation: 'PUE.', country: 'MEXICO' },
    { name: 'QUERÉTARO ', abbreviation: '	QRO.', country: 'MEXICO' },
    { name: 'QUINTANA ROO', abbreviation: 'Q.R.', country: 'MEXICO' },
    { name: 'SAN LUIS POTOSÍ', abbreviation: 'S.L.P.', country: 'MEXICO' },
    { name: 'SINALOA', abbreviation: 'SIN.', country: 'MEXICO' },
    { name: 'SONORA', abbreviation: 'SON.', country: 'MEXICO' },
    { name: 'TABASCO', abbreviation: 'TAB.', country: 'MEXICO' },
    { name: 'TAMAULIPAS', abbreviation: 'TAMPS.', country: 'MEXICO' },
    { name: 'TLAXCALA', abbreviation: 'TLAX.', country: 'MEXICO' },
    { name: 'VERACRUZ', abbreviation: 'VER.', country: 'MEXICO' },
    { name: 'YUCATÁN', abbreviation: 'YUC.', country: 'MEXICO' },
    { name: 'ZACATECAS', abbreviation: 'ZAC.', country: 'MEXICO' },
  ];
}

export function countryList() {
  return [
    { name: 'UNITED STATES', abbreviation: 'US' },
    { name: 'CANADA', abbreviation: 'CA' },
    { name: 'MEXICO', abbreviation: 'MX' },
  ];
}

export function dateDropDownList() {
  return  [
    { name: 'Current Month', value: 'current_month' },
    { name: 'Last Month', value: 'last_month' },
    { name: 'Current Year', value: 'current_year' },
    { name: 'Last Year', value: 'last_year' },
    { name: 'Last 30 Days', value: 'last_30_days' },
    { name: 'Last 60 Days', value: 'last_60_days' },
    { name: 'Last 90 Days', value: 'last_90_days' },
    { name: 'Custom', value: 'range' },
  ]
}
