import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
// import renderHTML from 'react-render-html';
// import SimpleFormat from '@16g/react-simple-format';
import parse from 'html-react-parser';
// import ReactHtmlParser from 'react-html-parser';
import ErrorBoundary from '../ErrorBoundary';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import MessageTextIcon from 'mdi-react/MessageTextIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import FaceIcon from 'mdi-react/FaceIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import WrenchIcon from 'mdi-react/WrenchIcon';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import AlarmLightIcon from 'mdi-react/AlarmLightIcon';
import ArmFlexIcon from 'mdi-react/ArmFlexIcon';
import PatioHeaterIcon from 'mdi-react/PatioHeaterIcon';
import SchoolIcon from 'mdi-react/SchoolIcon';
import VideoAccountIcon from 'mdi-react/VideoAccountIcon';

import { editor_toolbar, decode_message_object, encode_message_object } from '../../libs/editor';

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      message: props.activity.message,
      messageObject: encode_message_object(props.activity.message),
    };
  }

  renderUserIcon(activity) {
    if (activity.user) {
      return (
        <img className="img-circle" src={activity.user.avatar} alt={activity.user.name} />
      );
    }
    return null;
  }

  renderActivityType(activityType) {
    const list = ['face_to_face', 'phone', 'video_call', 'text', 'email', 'product_issues', 'aei', 'complaints_resolutions', 'Training', 'misc', 'quote_message'];
    const activityTypes = ['Face to Face', 'Phone Call', 'Video Call', 'Text', 'Email', 'Product Issues', 'AEI Responsibility', 'Complaints/Resolutions', 'training', 'Misc.', 'Quote Message']
    return activityTypes[list.indexOf(activityType)];
  }

  renderActivityTypeIcon(activityType) {
    const iconSize = 16;
    const list = ['face_to_face', 'phone', 'video_call', 'text', 'email', 'product_issues', 'aei', 'complaints_resolutions', 'training', 'misc', 'quote_message'];
    const activityTypes = [
      <FaceIcon size={iconSize} />,
      <PhoneIcon size={iconSize} />,
      <VideoAccountIcon icon={iconSize} />,
      <MessageTextIcon size={iconSize} />,
      <EmailIcon size={iconSize} />,
      <AlertCircleIcon size={iconSize} />,
      <ArmFlexIcon size={iconSize} />,
      <AlarmLightIcon size={iconSize} />,
      <SchoolIcon size={iconSize} />,
      <PatioHeaterIcon size={iconSize} />,
      <MessageTextIcon size={iconSize} />,
    ];
    return activityTypes[list.indexOf(activityType)];
  }

  renderActivityAttachedFile(activity) {
    if (activity.file === undefined) {
      return null;
    }
    return (
      [
        <span>&nbsp;</span>,
        <a href={activity.file.file_url} target="_blank">
          <FileDocumentIcon size={14} />
          &nbsp;
          {activity.file.file_name}
        </a>,
      ]
    );
  }

  renderEditAction() {
    const userId = localStorage.getItem('uid');
    const { activity } = this.props;
    const { editMode } = this.state;
    if (activity.user && userId !== activity.user.id) {
      return null;
    }
    if (activity.activityType === 'quote_message') {
      return <em>System Created</em>;
    }

    if (editMode) {
      return (
        <div className="text-right">
          <a href="#" onClick={e => this.clickSaveMessage(e)}>
            <ContentSaveIcon size={16} />
          </a>
        </div>
      );  
    }

    return (
      <div className="text-right">
        <a href="#" onClick={e => this.clickEditMode(e)}>
          <WrenchIcon size={16} />
        </a>
      </div>
    );
  }

  clickSaveMessage = (e) => {
    e.preventDefault();
    const { activity } = this.props;
    const { message, editMode, messageObject } = this.state;
    const finalMessage = decode_message_object(messageObject)
    // this has to be sent somewhere...
    this.props.updateActivityAction(activity.id, { activity: { message: finalMessage }});
    this.setState({ editMode: false, message: finalMessage });
  }

  clickEditMode = (e) => {
    e.preventDefault();
    const { editMode } = this.state;
    this.setState({ editMode: !editMode });
  }

  handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.setState({ [name]: value });
  }

  handleEditorChange = (content) => {
    this.setState({ messageObject: content });
  }

  renderQuoteLink(activity) {
    if (activity.quote) {
      return (
        <Link
          to={`/quote/${activity.quote.id}`}
          className="p-2 badge badge-primary mb-4"
        >
          View Quote {activity.quote.quoteNumber}
        </Link>
      );
    }
    return null;
  }

  render() {
    const  { editMode, message, messageObject } = this.state;
    const { activity } = this.props;
    return (
      <>
        <div className="sl-item" id={activity.id}>
          <div className="sl-left">
            {this.renderUserIcon(activity)}
          </div>
          <div className="sl-right">
            <div>
              {activity.user &&
                activity.user.name
              }
              &nbsp;
              <span className="sl-date">
                <Moment calendar>
                  {activity.created_at}
                </Moment>
              </span>
              <p>
                {this.renderActivityTypeIcon(activity.activityType)}
                &nbsp;
                {this.renderActivityType(activity.activityType)}
                &nbsp;
                {this.renderActivityAttachedFile(activity)}
              </p>
              {this.renderQuoteLink(activity)}
              {activity.task !== undefined && (
                <div className="text-right">
                  <Link
                    to={`/tasks/${activity.task}`}
                    className="btn btn-xs btn-secondary"
                  >
                    View Related Task
                  </Link>
                </div>
              )}
              {editMode &&
                <div className="form-group">
                  <Editor
                    wrapperClassName='card p-2'
                    editorState={messageObject}
                    onEditorStateChange={this.handleEditorChange}
                    toolbar={editor_toolbar()}
                  />
                  
                </div>
              }
              {!editMode &&
                <ErrorBoundary message="Text could not be rendered (Has been reported)">
                  <div dangerouslySetInnerHTML={{__html: activity.message}} />
                </ErrorBoundary>
              }
              {this.renderEditAction()}
            </div>
          </div>
        </div>
        <hr />
      </>
    );
  }
}

export default Activity;
