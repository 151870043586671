// api quotes
import api from './init';
import returnError from './returnError';

// get all quotes (paging)
export function getQuotes({page, filters}) {
  const {user, csr, status, company, qn} = filters
  const url = `/api/v1/quotes.json?page=${page}&user=${user.id}&csr=${csr.id}&status=${status.join(',')}&company=${company.id}&qn=${qn}`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getPendingQuotes() {
  const url = `/api/v1/quotes/pending.json`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

// get a quote by id (single)
export function getQuote(id) {
  const url = `/api/v1/quotes/${id}.json`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

// create or update quote (save)
export function saveQuote(data) {
  let url = `/api/v1/quotes.json`
  let method = "post"

  console.log('data', data.id)

  if (data.id !== undefined && data.id !== null) {
    url = `/api/v1/quotes/${data.id}.json`
    method = "put"
  }
  
  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// delete the quote (Admin only)
export function deleteQuote(id) {
  const url = `/api/v1/quotes/${id}.json`;
  return api
    .delete(url)
    .then((response) => {
      return response.data.action;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveQuoteNote(data) {
  const url = `/api/v1/quotes/${data.id}/notes.json`
  const method = "put"

  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// get the stats
export function getQuoteStats() {
  const url = '/api/v1/quotes/stats.json';
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// message
export function sendQuoteMessage(data) {
  const url = `/api/v1/quotes/${data.quoteId}/quote_messages.json`;
  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// quote clone
export function cloneQuote(id) {
  const url = `/api/v1/quotes/${id}/duplicate.json`;
  return api
    .post(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// calculate the tax
export function calculateTax(data) {
  const url = `/api/v1/quotes/tax.json`;
  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// save the tax info (Company)
export function saveTaxInfo(data) {
  const url = `/api/v1/companies/${data.id}/save_tax_info.json`;
  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function statusUpdate(data) {
  let url = `/api/v1/quotes/${data.id}/status.json`
  let method = "put"

  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}