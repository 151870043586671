
import {
  Row,
  Col,
} from 'reactstrap';

import styles from './MapHeader.module.scss'

const MapHeader = ({ map }) => {
  return (
    <>
      <Row className={styles.bold}>
        <Col xs={12} md={3}>
          Name
        </Col>
        <Col xs={12} md={2}>
          Urls
        </Col>
        <Col xs={12} md={2}>
          Review Status
        </Col>
        <Col xs={12} md={3}>
          Last Updated
        </Col>
        <Col xs={12} md={2} className="text-end">
          Actions
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default MapHeader
