import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
// import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Pagination from 'react-js-pagination';

// import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import CompanyDocumentItem from './CompanyDocumentItem';

import { saveCompanyDocument, deleteCompanyDocument } from '../../api/company';

export default function CompanyDocuments({companyDocuments, companyId, refreshCompany}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [documents, setDocuments] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [message, setMessage] = useState('');
  const [counter, setCounter] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    loadSubDocuments();
  }, [companyDocuments, currentPage]);

  const loadSubDocuments = () => {
    if (companyDocuments.length > 0) {
      const start = (currentPage - 1) * perPage;
      const end = start + perPage;
      setDocuments(companyDocuments.slice(start, end));
    }
  };
  
  // ****************************************************************
  // form functions
  // ****************************************************************

  const renderFileList = () => {
    return (
      fileList.map((file, idx) => (
        <Row key={file.name} className="dropzoneFileListItem">
          <Col md={8}>
            {file.name}
          </Col>
          <Col md={3}>
            {file.size} bytes
          </Col>
          <Col md={1}>
            <a role="button" href="#" onClick={(event) => deleteTempFile(event, idx)}>
              <DeleteIcon size={24} />
            </a>
          </Col>
        </Row>
      ))
    );
  };

  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <span>
          <LoadingIcon size={24} />
          Uploading
        </span>
      );
    }
    if (fileList.length === 0){
      return (
        <span>
          <p>Must add a file to the list.</p>
        </span>
      );
    }
    return (
      <input type="submit" value="upload file(s)" className="btn btn-primary" />
    );
  };

  const deleteTempFile = (event, idx) => {
    event.preventDefault();
    if (window.confirm('Are you sure? Cannot be undone')) {
      fileList.splice(idx, 1);
      updateFileList(fileList);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    let list = fileList
    if (acceptedFiles.length > 0) {
      acceptedFiles.map(item => {
        list.push(item);
      });
      updateFileList(list);
    }
  }, []);

  const updateFileList = (list) => {
    setFileList(list);
    setCounter(c => c + 1);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } = useDropzone({onDrop});

  const renderForm = () => {
    return (
      <form className="taskForm form-horizontal" onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col>
            <h4>Upload documents for this company</h4>
            <p><small>Valid file types: jpg jpeg gif png pdf docx doc xls xlsx xlsm txt csv</small></p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div {...getRootProps({ className: "dropzone" })}>
              <div {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here...</p> :
                  <p>Drag 'n' drop some files here, or click to select files</p>
              }
            </div>
          </Col>
          <Col md={8}>
            <div className="dropzoneFileList">
              <h5>File List</h5>
              {renderFileList()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            {renderSaveButton()}
            <p>{message}</p>
          </Col>
        </Row>
      </form>
    );
  };

  const buildFormData = () => {
    const formData = new FormData();
    formData.append('company_document[company_id]', companyId);
    fileList.map(file => {
      formData.append('company[company_documents][][file]', file, file.name);
    });
    console.log(formData);
    return formData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);
    const data = buildFormData();

    saveCompanyDocument(companyId, data)
      .then((res) => {
        console.log('saving company document... completed');
        setIsSaving(false);
        setErrors(res.errors);
        
        if (res.errors.length === 0) {
          updateFileList([]);
          setCurrentPage(1);
          setMessage("Document(s) Uploaded");
          hideMessage();
          refreshCompany(companyId);
        }
      });
  };

  const hideMessage = () => {
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  // ****************************************************************
  // saved file list (renders)
  // ****************************************************************

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    window.scrollTo({ top: 100, behavior: 'smooth' });
    setCurrentPage(pageNumber);
  };

  const renderPaging = () => {
    return (
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={perPage}
        totalItemsCount={companyDocuments.length}
        pageRangeDisplayed={5}
        onChange={(pageNumber) => handlePageChange(pageNumber)}
        innerClass="pagination"
        itemClass="paginate_button page-item"
        linkClass="page-link"
      />
    );
  };

  const rendercompanyDocuments = () => {
    return documents.map(item => (
      <CompanyDocumentItem
        companyDocument={item}
        key={item.id}
        deleteCompanyDocument={(event) => deleteDocument(event, item.id)}
      />
    ));
  };

  const renderListHeader = () => {
    return (
      <Row>
        <Col md={5}>Name/Uploaded By</Col>
        <Col md={3}>Size</Col>
        <Col md={3}>Upload Date</Col>
        <Col md={1}>&nbsp;</Col>
      </Row>
    );
  };

  const deleteDocument = (event, docId) => {
    event.preventDefault();
    if (window.confirm('Are you sure? (Cannot be undone)')) {
      deleteCompanyDocument(companyId, docId)
        .then((res) => {
          console.log('deleting company document... completed');
          if (res.errors.length === 0) {
            setCurrentPage(1);
            refreshCompany(companyId);
          }
        });
    }
  };

  return (
    <div>
      {renderForm()}
      {renderListHeader()}
      <hr />
      {rendercompanyDocuments()}
      {renderPaging()}
    </div>
  );
}
