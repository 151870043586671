import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { DropdownList, Multiselect } from 'react-widgets';
import MinusCircleIcon from 'mdi-react/MinusCircleIcon';

export default function TaskEntry({ users, whenInWeeks, followUpTypes, index, sendData }) {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(true);
  const [details, setDetails] = useState('');
  const [followUpType, setFollowUpType] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [weeks, setWeeks] = useState(0);

  useEffect(() => {
    pushData();
  });

  function pushData() {
    sendData(index, {
      delete: !show,
      assignedUsers,
      followUpType,
      weeks,
      details,
    });
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const detailsOnChange = (event) => {
    const { value } = event.target;
    setDetails(value);
  };

  const deleteItem = () => {
    setShow(false);
  };

  const updateFollowUpType = (obj) => {
    setFollowUpType(obj);
    setDetails(obj.description);
  };

  const updateUsers = (obj) => {
    setAssignedUsers(obj.map(x => x.id));
  };

  const updateWeeks = (obj) => {
    setWeeks(obj.value);
  };

  if (!show) { return null; }

  return (
    <div className="taskItem">
      <input type="hidden" name="followUpUser" className="followUpUser" value={assignedUsers} />
      <input type="hidden" name="followUpType" className="followUpType" value={followUpType} />
      <input type="hidden" name="followUpWeeks" className="followUpWeeks" value={weeks} />
      <Row className="mb-2">
        <Col md={4}>
          <DropdownList
            data={followUpTypes}
            textField="name"
            valueField="id"
            value={followUpType}
            placeholder="Follow Up"
            onChange={value => updateFollowUpType(value)}
          />
        </Col>
        <Col md={3}>
          <Multiselect
            placeholder="Select Users"
            data={users}
            textField="name"
            valueField="id"
            multiple
            caseSensitive={false}
            minLength={2}
            onChange={value => updateUsers(value)}
          />
        </Col>
        <Col md={2}>
          <DropdownList
            data={whenInWeeks}
            textField="text"
            valueField="value"
            placeholder="Weeks from now"
            onChange={value => updateWeeks(value)}
          />
        </Col>
        <Col md={2}>
          <button
            type="button"
            className="btn"
            onClick={toggleModal}
          >
            + Add Details
          </button>
        </Col>
        <Col md={1}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem()}
            title="Delete"
          >
            <MinusCircleIcon size={16} />
          </button>
        </Col>
      </Row>
      <Modal isOpen={showModal} toggle={toggleModal}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <textarea
                className="form-control"
                onChange={(e) => detailsOnChange(e)}
                rows={10}
              >
                {details}
              </textarea>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <button onClick={toggleModal}>Save</button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}

TaskEntry.propTypes = {
  users: PropTypes.array.isRequired,
  whenInWeeks: PropTypes.array.isRequired,
  followUpTypes: PropTypes.array.isRequired,
};
