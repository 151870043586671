import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import {
  DropdownList
} from 'react-widgets';
import RefreshIcon from 'mdi-react/RefreshIcon';

// components
import Error from '../../components/Error';
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';

import MapRow from '../../components/map/row';
import MapHeader from '../../components/map/header';

// api
import { getMapList, companyList } from '../../api/map';


const MapList = () => {
  const [maps, setMaps] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [page, setPage] = useState({
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    // api call here.
    getMapList({ page: page.currentPage, company })
      .then(res => {
        setMaps(res.maps)
        setPage(res.paging)
      })
  }, [page.currentPage, company])

  useEffect(() => {
    companyList()
      .then(res => {
        setCompanies(res.companies)
      })
  }, [])

  // actions
  const handlePageChange = (e) => {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    setPage({ ...page, currentPage: e })
  }

  const handleSelectedCompany = (e) => {
    Promise.all([
      setCompany(e),
      setPage({ ...page, currentPage: 1 })
    ])
  }

  // renders
  const renderRows = () => {
    if (maps.length === 0) {
      return (
        <Alert color="info">
          No Map Configurations found.
        </Alert>
      )
    }

    return maps.map((row) => (
      <MapRow key={row.id} map={row} />
    ))
  }


  // render paging
  const renderPaging = () => {
    return (
      <Pagination
        activePage={page.currentPage}
        itemsCountPerPage={10}
        totalItemsCount={page.totalRecords}
        pageRangeDisplayed={5}
        onChange={e => handlePageChange(e)}
        innerClass={'pagination'}
        itemClass={'paginate_button page-item'}
        linkClass={'page-link'}
      />
    );
  }

  return (
    <>
      <PageHeader title="Map Monitoring Configuration" />
      <CardWrapper>
        <Row>
          <Col>
            <DropdownList
              textField="name"
              valueField="id"
              data={companies}
              defaultValue={companies[0]}
              onChange={e => handleSelectedCompany(e)}
              placeholder="Select Company"
              value={company}
            />
            </Col>
          <Col>
            <button
              className="btn btn-secondary btn-xs mt-2"
              onClick={() => {
                setCompany(null)
                setPage({ ...page, currentPage: 1 })
              }}
            >
              <RefreshIcon size={12} />
              {' '}
              Reset
            </button>
          </Col>
          <Col className="text-end">
            <Link
              to="/map/new"
              className="btn btn-primary"
            >
              New Map Configuration
            </Link>
          </Col>
        </Row>
        <hr />
        <MapHeader />
        {renderRows()}
        {renderPaging()}
      </CardWrapper>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  )
}

export default MapList
