import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader'

import TimelineItem from './TimelineItem';
import WeekSelector from './WeekSelector';

import { getUserActivityData } from '../../api/report';
import { fullUserList } from '../../api/user';
import { inValidRole } from '../../libs/utils';

export default class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      startDate: new moment().startOf('week').format("YYYY-MM-DD 00:00:00"),
      endDate: new moment().endOf('week').format("YYYY-MM-DD 23:59:59"),
      items: [],
      users: [],
      selectedUser: null,
      errorMessage: '',
    };
  }

  componentWillMount() {
    this.getFilters(() => {
      this.loadUserList();
    });
  }

  getFilters(callback) {
    let json = localStorage.getItem("timeline:filters");
    if (json !== null) {
      let data = JSON.parse(json);
      this.setState({
        startDate: data.startDate,
        endDate: data.endDate,
        selectedUser: data.selectedUser,
      }, () => callback());
    } else {
      callback();
    }
  }

  saveFilters(data) {
    localStorage.setItem("timeline:filters", JSON.stringify(data));
  }

  loadUserList = () => {
    const { startDate, endDate } = this.state;
    if (inValidRole(['admin', 'sales_manager', 'csr_manager'], localStorage)) {
      fullUserList()
        .then((res) => {
          this.setState(
            { users: res }, 
            () => this.loadData(startDate, endDate)
        );
      });
    } else {
      this.setState({
        selectedUser: {
            id: localStorage.getItem("uid")
          }
        }, () => this.loadData(startDate, endDate)
      );
    }
  }

  loadData = (startDate, endDate) => {
    const { selectedUser } = this.state;
    if (selectedUser === null) { return; }
    const state = this;
    state.setState({
      loading: true,
      startDate,
      endDate
    }, () => {
      const filter = {
        startDate,
        endDate,
        userId: selectedUser.id,
      };

      getUserActivityData(filter)
        .then((data) => {
          console.log(data);
          if (data.errors) {
            state.setState({ 
              errorMessage: data.errors[0],
              items: [],
              loading: false
            });
          } else {
            state.setState({
              items: data.items,
              errorMessage: '',
              loading: false,
            }, () => {
                this.saveFilters({ startDate, endDate, selectedUser });
            });
          }
        });
    });
  }

  renderTimelineItems() {
    const { items } = this.state;
    return items.map(item => (
      <TimelineItem item={item} />
    ));
  }

  renderUserList = () => {
    const { users, selectedUser } = this.state;

    if (!inValidRole(['admin', 'sales_manager'], localStorage)) {
      return null;
    }

    return (
      [
        <Row>
          <Col>
            <DropdownList
              textField="name"
              valueField="id"
              data={users}
              value={selectedUser}
              onChange={value => this.updateFilters(value)}
              placeholder="Select User"
            />
          </Col>
        </Row>,
        <hr />,
      ]
    );
  }

  updateFilters = (value) => {
    const { startDate, endDate } = this.state;
    this.setState({
      selectedUser: value,
    }, () => this.loadData(startDate, endDate));
  }

  render() {
    const { startDate, endDate, loading, items } = this.state;
    return [
      <Helmet>
        <title>The Hub - Timeline</title>
      </Helmet>,
      <PageHeader title="Weekly Timeline" />,
      <CardWrapper>
        {this.renderUserList()}
        <WeekSelector
          updateWeekRange={(startDate, endDate) => this.loadData(startDate, endDate)} 
          startDate={startDate}
          endDate={endDate}
        />
        {loading && (
          <Row>
            <Col className="text-center">
              Loading...
            </Col>
          </Row>
        )}
        {!loading && (
          <ul class="cbp_tmtimeline">
            {this.renderTimelineItems()}
          </ul>
        )}
        {items.length === 0 && !loading && (
          <Row>
            <Col className="text-center">
              No Records for this Week
            </Col>
          </Row>
        )}
      </CardWrapper>,
    ];
  }
}
