import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "http://3173351f4a2545179018acdcdcca492c@sentry.boldarray.net:9000/5",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
  environment: 'production',
});

const root = createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)

reportWebVitals();
