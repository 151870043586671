import React from 'react';
import { Row, Col } from 'reactstrap';

import ErrorBoundary from '../ErrorBoundary';

export default function SalesHistoryTableMonth({report}) {


  if (report === undefined) {
    return null;
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const renderMonthColumns = () => {
    return (
      months.map(month => (<th className="text-center">{month}</th>))
    );
  };

  const productRow = (product) => {
    return (
      <>
        <tr>
          <td colSpan={13} className="bg-info text-white">
            <strong>
              {product.sku} - {product.item}
            </strong>
          </td>
        </tr>
        {report.requestedYears.map(year => (
          <tr>
            <td>
              <strong>{year}</strong>
            </td>
            {productInfo(product, year)}
          </tr>
        ))}
      </>
    );
  };

  const productInfo = (product, year) => {
    try {
      const y = report.years.filter(j => j.year === year)[0];
      const p = y.products.filter(x => x.productId === product.productId)[0];
      if (p === undefined || p.months.length === 0) {
        return (
          [1,2,3,4,5,6,7,8,9,10,11,12].map(item => (
            <td>-</td>
          ))
        );
      }
      return (
        p.months.map(month => (
          renderCell(month)
        ))
      );
    }
    catch (e) {
      console.log(e);
      return (
        [1,2,3,4,5,6,7,8,9,10,11,12].map(item => (
          <td>-</td>
        ))
      );
    }
  };

  const renderCell = (month) => {
    let total = month.salesTotal;
    let orders = month.orders;
    if (month.sales < 0) { 
      return (<td className="bg-danger text-white">{total} ({orders})</td>);
    } else {
      return (<td>{total} ({orders})</td>);
    }
  };

  return (
    <ErrorBoundary>
      <Row>
        <Col>
          <h3>Month Breakdown</h3>
        </Col>
      </Row>      
      <table className="table table-striped table-responsive">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {renderMonthColumns()}
          </tr>
        </thead>
        <tbody>
          {report.products.map(item => (
            productRow(item)
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </ErrorBoundary>
  );
}
