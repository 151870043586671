import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AccountIcon from 'mdi-react/AccountIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import WebIcon from 'mdi-react/WebIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import Error from '../../components/Error';
import NoteList from '../../components/notes/NoteList';

import Loading from '../../components/Loading';

import { saveContact, getContact } from '../../api/company';


const ContactForm = (props) => {
  const navigate = useNavigate()
  const { id, cid } = useParams()
  const companyId = cid
  const { isNew } = props;
  const [pageTitle, setPageTitle] = useState('New Contact');
  const [contact, setContact] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telephone: '',
    telephone_ext: '',
    cell_phone: '',
    title: '',
    avatar: '',
    address: '',
    address_two: '',
    city: '',
    state: '',
    zip_code: '',
  });
  const [errors, setErrors] = useState([]);
  const [savingRecord, setSavingRecord] = useState(false);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(true);

  // callbacks
  useEffect(() => {
    if (!isNew) {
      setPageTitle('Edit Contact')
      loadContact(id, cid)
    } else {
      setLoading(false)
    }
  }, [id, cid, isNew])

  // return blank
  const validateField = (field) => {
    if (field === "undefined") { return ""; }
    return field;
  }

  // setup form data
  const buildFormData = () => {
    let formData = new FormData();
    formData.append('contact[first_name]', validateField(contact.first_name));
    formData.append('contact[last_name]', validateField(contact.last_name));
    formData.append('contact[email]', validateField(contact.email));
    formData.append('contact[title]', validateField(contact.title));
    formData.append('contact[telephone]', validateField(contact.telephone));
    formData.append('contact[telephone_ext]', validateField(contact.telephone_ext));
    formData.append('contact[cell_phone]', validateField(contact.cell_phone));
    formData.append('contact[address]', validateField(contact.address));
    formData.append('contact[address_two]', validateField(contact.address_two));
    formData.append('contact[city]', validateField(contact.city));
    formData.append('contact[state]', validateField(contact.state));
    formData.append('contact[zip_code]', validateField(contact.zip_code));

    // image
    if (imageUpdated) {
      const file = document.getElementById('avatar').files[0];
      formData.append('contact[avatar]', file, file.name);
    }

    return formData;
  }

  // save the contact infomration
  const submitContact = (e) => {
    e.preventDefault()
    setSavingRecord(true)
    const data = buildFormData();

    saveContact(id, companyId, isNew, data)
      .then((res) => {
        console.log('saving company... completed');
        setSavingRecord(false)
        setErrors(res.errors)
        setSaved(false)

        if (res.errors.length === 0) {
          navigate(`/companies/${companyId}`)
        }
      });
  }

  // get the contact info
  const loadContact = (id, companyId) => {
    getContact(id, companyId)
      .then((response) => {
        setContact(response)
        setLoading(false)
      });
  }

  //  update state
  const updateContact = (key, value) => {
    setContact({...contact, [key]: value})
  }

  // get form value
  const handleInputChange = event => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    updateContact(name, value);
  }

  // display the image data
  const displayImage = (e) => {
    setImageUpdated(true)
    const file = document.getElementById('avatar').files[0];
    updateContact('avatar', URL.createObjectURL(file));
  }

  // avatar html
  const renderImage = () => {
    if (contact === undefined || contact.avatar === undefined && contact.avatar === '') {
      return null;
    }
    return (
      <div>
        <img src={contact.avatar} alt="Avatar" className="img-circle" width={150} height={150} />
      </div>
    );
  }

  const renderSaveButton = () => {
    const link = `/companies/${companyId}`

    if (savingRecord) {
      return (
        <span>
          <LoadingIcon size={24} />
          Saving
        </span>
      )
    } else {
      return (
        <>
          <button type="submit" value="save" className="btn btn-primary">
            <ContentSaveIcon size={16} />
            &nbsp;
            Save
          </button>
          <span>&nbsp;</span>,
          <Link to={link} className="btn btn-secondary">
            <CancelIcon size={16} />
            Cancel
          </Link>
        </>
      )
    }
  }

  const renderNotes = () => {
    if (isNew) {
      return null
    }
    return (
      <CardWrapper>
        <Row>
          <Col>
            <h4>Notes</h4>
          </Col>
        </Row>
        <NoteList objectId={id} objectName="contact" />
      </CardWrapper>
    )
  }

  // render
  return (
    <>
      <PageHeader title={pageTitle} />
      {loading && (
        <Loading />
      )}
      {!loading && (
        <>
          <CardWrapper>
            <form onSubmit={submitContact}>
              <Error errors={errors} />
              <Row>
                <Col xs={12} sm={3}>
                  <div className="form-group">
                    <label for="avatar" className="control-label btn-primary btn">
                      <AccountIcon size={21} />
                      &nbsp;
                      Upload Image
                    </label>
                    <p>
                      Image Size: 150 x 150
                    </p>
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      style={{
                        width: 0.1,
                        height: 0.1,
                        opacity: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={e => displayImage(e)}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={9}>
                  {renderImage()}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <div className="form-group">
                    <label forHtml="first_name" className="control-label">First Name *</label>
                    <input type="text" required name="first_name" id="first_name" className="form-control" value={contact.first_name} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label forHtml="last_name" className="control-label">Last Name *</label>
                    <input type="text" required name="last_name" id="last_name" className="form-control" value={contact.last_name} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label forHtml="title" className="control-label">Title *</label>
                    <input type="text" required name="title" id="title" className="form-control" value={contact.title} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label forHtml="email" className="control-label">Email Address</label>
                    <input type="text" name="email" id="email" className="form-control" value={contact.email} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-group">
                    <label forHtml="telephone" className="control-label">Telephone Number</label>
                    <input type="tel" name="telephone" id="telephone" className="form-control" value={contact.telephone} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="form-group">
                    <label forHtml="telephone_ext" className="control-label">Telephone Ext</label>
                    <input type="text" name="telephone_ext" id="telephone_ext" className="form-control" value={contact.telephone_ext} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label forHtml="cell_phone" className="control-label">Cell Number</label>
                    <input type="tel" name="cell_phone" id="cell_phone" className="form-control" value={contact.cell_phone} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>Address</h3>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div className="form-group">
                    <label forHtml="address" className="control-label">Address</label>
                    <input type="text" name="address" id="address" className="form-control" value={contact.address} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-group">
                    <label forHtml="address_two" className="control-label">Address Cont.</label>
                    <input type="text" name="address_two" id="address_two" className="form-control" value={contact.address_two} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label forHtml="city" className="control-label">City</label>
                    <input type="text" name="city" id="city" className="form-control" value={contact.city} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label forHtml="state" className="control-label">State</label>
                    <input type="text" name="state" id="state" className="form-control" value={contact.state} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label forHtml="zip_code" className="control-label">Zip Code</label>
                    <input type="text" name="zip_code" id="zip_code" className="form-control" value={contact.zip_code} onChange={e => handleInputChange(e)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {renderSaveButton()}
                </Col>
              </Row>
            </form>
          </CardWrapper>
          {renderNotes()}
        </>
      )}
    </>
  )
}

export default ContactForm;
