import React from 'react';
import Pagination from 'react-js-pagination';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Form from './Form';
import Activity from './Activity';

import { getActivities, updateActivity } from '../../api/company';

const activityTypes = ['ALL', 'Face to Face', 'Phone Call', 'Video Call', 'Text', 'Email', 'Product Issues', 'AEI Responsibility', 'Complaints/Resolutions', 'Training', 'Misc.'];
const activityTypesList = ['', 'face_to_face', 'phone', 'video_call', 'text', 'email', 'product_issues', 'aei', 'complaints_resolutions', 'training', 'misc'];

class ActivityList extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.companyId);
    this.state = {
      companyId: props.companyId,
      activities: [],
      noActivities: {
        icon: '',
        message: 'No activities',
      },
      selectedActivityType: 'ALL',
      selectedActivityTypeValue: '',
      currentPage: 1,
      totalRecords: 0,
      loading: true,
    };

    this.loadActivities = this.loadActivities.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.loadActivities();
  }

  componentWillReceiveProps(newProps) {
    const { companyId } = this.state;
    console.log(`New Company Id: ${newProps.companyId}`);
    console.log(`Old Company Id: ${companyId}`);
    console.log(newProps.companyId !== companyId);
    if (newProps.companyId !== companyId) {
      this.setState({
        companyId: newProps.companyId,
        currentPage: 1,
      }, () => this.loadActivities());
    }
  }

  loadActivities() {
    const { companyId, currentPage, selectedActivityTypeValue } = this.state;
    console.log(companyId);
    if (companyId === undefined || companyId === null || companyId === '') { return null; }
    
    getActivities(companyId, { page: currentPage, activityType: selectedActivityTypeValue })
      .then((response) => {
        console.log(response);
        this.setState({
          activities: response.activities,
          currentPage: response.details.current_page,
          totalRecords: response.details.total_records,
          loading: false,
        });
      });
  }

  updateActivityAction = (id, data) => {
    const { companyId } = this.state;
    updateActivity(companyId, id, data)
      .then((response) => {
        this.loadActivities();
      });
    // let { activities } = this.state;
    // activity = activities.filter(x => x.id == id)[0];
    // activity.message = data.activity.message;
  }

  renderActivities() {
    const { activities, noActivities } = this.state;
    if (activities.length === 0) {
      return (
        <Activity activity={noActivities} key={'NoActivities'} />
      );
    }

    return activities.map(item => (
      <Activity activity={item} key={item.id} updateActivityAction={this.updateActivityAction} />
    ));
  }

  renderPaging() {
    const { activities } = this.state;
    if (activities.length === 0) {
      return null;
    }
    return (
      <Pagination
        activePage={this.state.currentPage}
        itemsCountPerPage={10}
        totalItemsCount={this.state.totalRecords}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange}
        innerClass={'pagination'}
        itemClass={'paginate_button page-item'}
        linkClass={'page-link'}
      />
    );
  }

  handlePageChange(pageNumber) {
    this.setState({ currentPage: pageNumber }, () => {
      this.loadActivities();
    });
  }

  renderFilters = () => {
    const { selectedActivityType } = this.state;

    return (
      <>
        <hr />
        <Row>
          <Col md={3}>
            Filter By Activity Type:
          </Col>
          <Col md={4}>
            <DropdownList
                data={activityTypes}
                value={selectedActivityType}
                onChange={value => this.setActivityType({ value })}
              />    
          </Col>
        </Row>
        <hr />
      </>
    );
  }

  setActivityType(activityType) {
    let selectedActivityTypeValue = activityTypesList[activityTypes.indexOf(activityType.value)];
    this.setState({
      selectedActivityType: activityType.value,
      selectedActivityTypeValue,
      currentPage: 1,
    }, () => { this.loadActivities() });
  }

  render() {
    return (
      <div className="card-body">
        <div className="profiletimeline">
          <div className="sl-item">
            <div className="sl-right">
              <Form companyId={this.props.companyId} updateActivityList={this.loadActivities} />
            </div>
          </div>
          {this.renderFilters()}
          {this.renderActivities()}
          {this.renderPaging()}
        </div>
      </div>
    );
  }
}

export default ActivityList;
