import React from 'react';

const Error = ({ errors, header }) => {

  const renderHeader = () => {
    if (header === undefined || header === null || header === '') {
      return "The following errors prohibited the item from being saved:"
    }
    return header
  }

  if (errors.length === 0) {
    return null
  }

  return (
    <div className="errors alert alert-danger text-start">
      <h3>{renderHeader()}</h3>
      <ul>
        {errors.map(error => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
}

export default Error;
