// list of quotes
import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Badge,
  Button
} from 'reactstrap';
import { DropdownList } from 'react-widgets';

// icons
import PlusIcon from 'mdi-react/PlusIcon';

// components
import PageHeader from '../../components/PageHeader';
// import PageSubHeader from '../../components/PageSubHeader';
import CardWrapper from '../../components/CardWrapper';
// import Error from '../../components/Error';
import AuthorizationNotice from '../../components/authorizationNotice'

// quote - components
import QuoteRowPending from '../../components/quote/QuoteRowPending';

// api
import { getPendingQuotes } from '../../api/quote';

// css
import styles from './List.module.scss';

const Pending = () => {
  const [quotes, setQuotes] = useState([])

  // filters
  const [totalRecords, setTotalRecords] = useState(0)
  // loading
  const [loading, setLoading] = useState(true)

  // loading
  useEffect(() => {
    setLoading(true)
    getPendingQuotes()
      .then(data => {
        setQuotes(data.quotes)
        setTotalRecords(data.paging.total_pages)
        setLoading(false)
      })
  }, [])

  // actions 

  const renderNoQuotesFound = () => {
    if (quotes.length > 0) return null

    return (
      <Row>
        <Col>
          <Alert color="info" className='text-center'>
            No quotes found with status Payment Pending.
          </Alert>
        </Col>
      </Row>
    )
  }

  const renderQuotes = () => {
    return quotes.map(quote => (
      <QuoteRowPending
        key={quote.id}
        quote={quote}
      />
    ))
  }
  
  const renderNumberOfQuotes = () => {
    if (quotes.length === 0) return null

    return (
      <Row>
        <Col className="text-end">
          <Badge>
            Total Quotes: {totalRecords}
          </Badge>
          <hr />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <PageHeader title="Quotes - Pending" />
      <AuthorizationNotice />
      <Alert color="info">
        <p>Quotes that have a PAYMENT STATUS of Pending.</p>
      </Alert>
      <CardWrapper>
        {renderNoQuotesFound()}
        {renderNumberOfQuotes()}
        {renderQuotes()}
      </CardWrapper>
    </>
  )
}

export default Pending;
