import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Pagination from 'react-js-pagination';
import CompanyItem from './CompanyItem';
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';
import Loading from '../../components/Loading';

import RefreshIcon from 'mdi-react/RefreshIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';

import { companyList } from '../../api/company';
import { listClassifications } from '../../api/classifications';
import { stateList } from '../../libs/utils';

// filters
// import { getCompanyFilters, resetCompanyFilters, setCompanyFilters } from '../../libs/filters';
// let companyFilters = getCompanyFilters();

const defaultFilters = {
  paging: {
    page: 1,
    totalRecords: 0
  },
  filters: {
    search: '',
    zip: '',
    stars: '',
    classification: {name: '-- All --', id: ''},
    state: { name: "", abbreviation: "" },
  }
}

const useFilterStorage = (storageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultFilters
  )

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

const CompanyList = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [companyFilters, setCompanyFilters] = useFilterStorage('company:filters')
  
  useEffect(() => {
    loadClassifications()
  }, [])

  useEffect(() => {
    loadCompanies()
  }, [companyFilters])

  // load data
  const loadClassifications = () => {
    let list = localStorage.getItem("company:classifications");
    if (list === null) {
      listClassifications()
        .then(data => {
          const items = filterClassifications(setClassifications())
          localStorage.setItem("company:classifications", JSON.stringify(items))
          setClassifications(items)
        })
    } else {
      setClassifications(JSON.parse(list));
    }
  }

  const filterClassifications = (data) => {
    let list = [{name: "-- All --", id: ""}];
    data.classifications.map(item => {
      list.push({name: item.name, id: item.id});
    });
    return list;
  }

  const loadCompanies = () => {
    setLoading(true)

    const data = {
      page: companyFilters.paging.page,
      search: companyFilters.filters.search,
      zip: companyFilters.filters.zip,
      stars: companyFilters.filters.stars,
      classification: companyFilters.filters.classification.id,
      state: companyFilters.filters.state.abbreviation,
    }

    companyList(data)
      .then(response => {
        setTotalRecords(response.details.total_records)
        setCompanies(response.companies)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // actions

  const updateFilterSelection = (value, name) => {
    const val = {
      ...companyFilters,
      filters: {...companyFilters.filters, [name]: value},
      paging: {...companyFilters.paging, page: 1}
    }
    setCompanyFilters(val)
  }

  const handlePageChange = (pageNumber) => {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    const val = {...companyFilters, paging: {...companyFilters.paging, page: pageNumber}}
    setCompanyFilters(val)
  }

  const resetFilters = (e) => {
    e.preventDefault()
    setCompanyFilters(defaultFilters)
  }

  // renders

  const isActiveStars = num => {
    const { stars } = companyFilters.filters;
    if (stars === num) {
      return "btn btn-primary active";
    }
    return "btn btn-primary";
  }

  const renderCompanies = () => {
    if (loading) {
      return (
        <Loading />
      );
    }
    if (companies.length === 0) {
      return (
        <Row>
          <Col className="text-center">
            No Companies Found.
          </Col>
        </Row>
      )
    }
    return companies.map(company => (
      <CompanyItem company={company} key={`company-detail-${company.id}`} />
    ));
  }

  const renderHeader = () => {
    return (
      <>
        <Row>
          <Col md={2}></Col>
          <Col md={5}>
            <strong>Name</strong>
          </Col>
          <Col md={5}>
            <strong>Address</strong>
          </Col>
        </Row>
        <hr />
      </>
    )
  }

  // final render

  return (
    <div>
      <PageHeader title="Customers" key="pageHeader" />
      <Row className="mb-4 text-right">
        <Col>
          <Link to="/companies/new" className="btn btn-primary">
            <PlusIcon size={16} />
            New Customer
          </Link>
        </Col>
      </Row>
      <CardWrapper key="cardWrapperMain">
        <Row>
          <Col md={3}>
            <DropdownList
              name="classification"
              data={classifications}
              value={companyFilters.filters.classification}
              onChange={value => updateFilterSelection(value, "classification")}
              placeholder="Select Class"
              textField="name"
              valueField="id"
            />
          </Col>
          <Col md={2} className="text-right">
            <input
              type="text"
              placeholder="Start of Company Name..."
              id="searchCompany"
              name="searchCompany"
              className="form-control"
              onChange={e => updateFilterSelection(e.target.value, "search")}
              value={companyFilters.filters.search}
              title="Requires at least 3 characters"
            />
            {/* <small>
              Requires at least 3 characters.
              <br />
              Or Clear value to search all.
            </small> */}
          </Col>
          <Col md={2}>
            <input
              text="text"
              placeholder="City or Zip"
              id="searchCity"
              name="searchCity"
              className="form-control"
              onChange={e => updateFilterSelection(e.target.value, 'zip')}
              value={companyFilters.filters.zip}
            />
          </Col>
          <Col md={1}>
            <DropdownList
              data={stateList()}
              name="states"
              textField="abbreviation"
              valueField="abbreviation"
              value={companyFilters.filters.state}
              onChange={value => updateFilterSelection(value, "state")}
            />
            <small>
              By State.
            </small>
          </Col>
          <Col md={3}>
            <button
              className={isActiveStars("")}
              onClick={() => updateFilterSelection("", "stars")}
              title="Show All"
            >
              <StarOutlineIcon size={16} />
              All
            </button>
            &nbsp;
            <button
              className={isActiveStars(1)}
              onClick={() => updateFilterSelection(1, "stars")}
              title="Prospect"
            >
              <StarIcon size={16} />
            </button>
            &nbsp;
            <button
              className={isActiveStars(2)}
              onClick={() => updateFilterSelection(2, "stars")}
              title="Customer"
            >
              <StarIcon size={16} />
              <StarIcon size={16} />
            </button>
            &nbsp;
            <button
              className={isActiveStars(3)}
              onClick={() => updateFilterSelection(3, "stars")}
              title="Top Customer"
            >
              <StarIcon size={16} />
              <StarIcon size={16} />
              <StarIcon size={16} />
            </button>
          </Col>
          
          <Col md={1} className="text-right">
            <button
              className="btn btn-primary"
              onClick={(e) => resetFilters(e)}
              title="Reset Filters"
            >
              <RefreshIcon size={16} key="resetAction" /> Reset
            </button>
          </Col>
        </Row>
        <br />
        <br />

        {renderHeader()}
        {renderCompanies()}

        <Pagination
          activePage={companyFilters.paging.page}
          itemsCountPerPage={10}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={5}
          onChange={(e) => handlePageChange(e)}
          innerClass={"pagination"}
          itemClass={"paginate_button page-item"}
          linkClass={"page-link"}
        />
      </CardWrapper>
    </div>
  )
}

export default CompanyList
