import React from 'react';
import { renderStars } from './helpers';

const PageHeader = ({title, stars}) => (
  <div className="row page-titles">
    <div className="col-md-8 col-8 align-self-center">
      <h3 className="text-themecolor">
        {title}
        {stars !== undefined && (
          renderStars(stars)
        )}
      </h3>
    </div>
  </div>
);

export default PageHeader;
