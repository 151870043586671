import React from 'react';
import { Row, Col, Alert } from 'reactstrap';

const PageSubHeader = ({title, description}) => (
  <Row>
    <Col lg={12}>
      <Alert color="primary">
        <h3 className="card-title">
          {title}
        </h3>
        {description !== '' &&
          <h6 className="card-subtitle">
            {description}
          </h6>
        }
      </Alert>
    </Col>
  </Row>
);

export default PageSubHeader;
