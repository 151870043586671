import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// compoennts
import CommentForm from './CommentForm';
import Comment from './Comment';

// api
import { taskComments } from '../../api/comment';

const CommentContainer = ({taskId}) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    loadComments()
  }, [taskId])

  const loadComments = () => {
    taskComments(taskId)
      .then((response) => {
        setComments(response.comments);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const renderComments = () => {
    return comments.map(comment => (
      <Comment comment={comment} key={comment.id} />
    ));
  }

  return (
    <div id={taskId}>
      <ul className="chat-list">
        {renderComments()}
      </ul>
      <CommentForm taskId={taskId} loadComments={() => loadComments()} />
    </div>
  )
}

CommentContainer.propTypes = {
  taskId: PropTypes.string,
};

CommentContainer.defaultProps = {
  taskId: '',
};

export default CommentContainer;
