import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';


export function decode_message_object(content) {
  if (content === undefined || content === null) {
    return ''
  }

  try {
    const rawContentState = convertToRaw(content.getCurrentContent())
    const markup = draftToHtml(rawContentState, {}, {}, {})
    return markup
  } catch (e) {
    return ''
  }
}

export function encode_message_object(content) {
  const blocksFromHtml = htmlToDraft(content)
  const contentBlocks = blocksFromHtml.contentBlocks
  const contentState = ContentState.createFromBlockArray(contentBlocks)
  const editorState = EditorState.createWithContent(contentState)

  return editorState 
}

export function editor_toolbar() {
  return {
    options: ['inline' , 'list', 'textAlign', 'link', 'remove', 'history'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
    list: { options: ['unordered', 'ordered'] },
    textAlign: { options: ['left', 'center', 'right'] },
    link: { options: ['link', 'unlink'] },
    remove: {},
    history: { options: ['undo', 'redo'] },
  }
}