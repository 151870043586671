import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';
import RefreshIcon from 'mdi-react/RefreshIcon';

// components
import PageHeader from '../../../components/PageHeader';
import CardWrapper from '../../../components/CardWrapper';
import CompanyItem from '../CompanyItem';

// api
import { companyList } from '../../../api/company';

// filters
const defaultFilters = {
  paging: {
      page: 1,
      totalRecords: 0
    },
    filters: {
      search: '',
      stars: 1,
    }
}

const useFilterStorage = (storageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultFilters
  )

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

const Prospects = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [prospectFilters, setProspectFilters] = useFilterStorage('prospect:filters')

  useEffect(() => {
    loadCompanies()
  }, [prospectFilters])

  // loads

  const loadCompanies = () => {
    setLoading(true)

    const data = {
      page: prospectFilters.paging.page,
      search: prospectFilters.filters.search,
      stars: prospectFilters.filters.stars,
      zip: '',
      state: '',
      classification: '',
    }

    companyList(data)
      .then(response => {
        setCompanies(response.companies)
        setTotalRecords(response.details.total_records)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  // actions

  const updateFilterSelection = (value, name) => {
    const val = {...prospectFilters, filters: {...prospectFilters.filters, [name]: value}}
    setProspectFilters(val)
  }

  const resetFilters = (e) => {
    e.preventDefault()
    setProspectFilters(defaultFilters)
  }

  const handlePageChange = (pageNumber) => {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    const val = {...prospectFilters, paging: {...prospectFilters.paging, page: pageNumber}}
    console.log(val)
    setProspectFilters(val)
  }

  // render

  const renderHeader = () => {
    return (
      <>
        <Row>
          <Col md={2}></Col>
          <Col md={5}>
            <strong>Name</strong>
          </Col>
          <Col md={5}>
            <strong>Address</strong>
          </Col>
        </Row>
        <hr />
      </>
    )
  }

  const renderCompanies = () => {
    if (loading) {
      return (
        <p>
          Loading...
        </p>
      );
    }
    if (companies.length === 0) {
      return (
        <Row>
          <Col className="text-center">
            No Companies Found.
          </Col>
        </Row>
      )
    }
    return companies.map(company => (
      <CompanyItem company={company} key={`company-detail-${company.id}`} />
    ));
  }

  const renderFilters = () => {
    return (
      <>
        <Row>
          <Col md={2} className="text-right">
            <input
              type="text"
              placeholder="Start of Company Name..."
              id="searchCompany"
              name="searchCompany"
              className="form-control"
              onChange={e => updateFilterSelection(e.target.value, "search")}
              value={prospectFilters.filters.search}
              title="Requires at least 3 characters"
            />
          </Col>
          <Col md={1} className="text-right">
            <button
              className="btn btn-primary"
              onClick={(e) => resetFilters(e)}
              title="Reset Filters"
            >
              <RefreshIcon size={16} key="resetAction" /> Reset
            </button>
          </Col>
        </Row>
        <br />
        <br />
      </>
    )
  }

  return (
    <div>
      <PageHeader title="Prospects" subtitle="" />
      <CardWrapper key="prospectsWrapper">
        {/* filters */}
        {renderFilters()}
        {renderHeader()}
        {renderCompanies()}

        <Pagination
          activePage={prospectFilters.paging.page}
          itemsCountPerPage={18}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          innerClass={"pagination"}
          itemClass={"paginate_button page-item"}
          linkClass={"page-link"}
        />
      </CardWrapper>
    </div>
  )
}

export default Prospects
