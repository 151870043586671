import api from './init';
import returnError from './returnError';

const TASK_TEMPLATE_URL = '/api/v1/task_templates';
const CALL_LOGS_URL = '/api/v1/call_logs';

export function getCallLog() {
  return null;
}

export function addCallLogs(data) {
  const url = CALL_LOGS_URL;
  const formData = convertToFormData(data);
  return api.post(url, formData, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError(error);
    });
}

export function getTaskTemplates() {
  const url = TASK_TEMPLATE_URL;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function saveTaskTemplate(id, data) {
  let url = TASK_TEMPLATE_URL;
  let method = 'post';
  if (id !== null) {
    method = 'patch';
    url = `${TASK_TEMPLATE_URL}/${id}`;
  }

  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteTaskTemplate(id) {
  return null;
}


// function

function convertToFormData(data) {
  console.log(data);
  let formData = new FormData();
  data.callLog.map((item, idx) => {
    formData.append(`call_log[call_logs][][company_id]`, item.company.id);
    formData.append(`call_log[call_logs][][activity_type]`, item.activityType.value);
    formData.append(`call_log[call_logs][][date_of_activity]`, item.when);
    formData.append(`call_log[call_logs][][discussion]`, item.description);
    formData.append(`call_log[call_logs][][delete]`, item.delete);
    if (item.file !== '') {
      formData.append(`call_log[call_logs][][file]`, item.file);
    }

    if (item.taskEntries.length > 0) {
      item.taskEntries.map((item2, idx2) => {
        formData.append(`call_log[call_logs][][call_log_tasks][][follow_up_type]`, item2.followUpType.id);
        formData.append(`call_log[call_logs][][call_log_tasks][][when_in_weeks]`, item2.weeks);
        formData.append(`call_log[call_logs][][call_log_tasks][][assigned_users]`, item2.assignedUsers);
        formData.append(`call_log[call_logs][][call_log_tasks][][details]`, item2.details);
      });
    }
  });

  console.log(formData);

  return formData;
}
