import React from 'react';
import { 
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardText
} from 'reactstrap';

import styles from './CardWrapper.module.scss';

const CardWrapper = ({ header, headerColorClass, renderBody, children }) => {
  const getHeaderClass = () => {
    if (headerColorClass !== undefined && headerColorClass !== null) {
      return styles[headerColorClass]
    }
    return styles.header
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          {header !== undefined && (
            <CardHeader tag="h5" className={getHeaderClass()}>
              {header}
            </CardHeader>
          )}
          {renderBody === undefined &&
            <CardBody>
              {children}
            </CardBody>
          }
          {renderBody !== undefined &&
            <CardText>
              {children}
            </CardText>
          }
        </Card>
      </Col>
    </Row>
  )
}

export default CardWrapper;
