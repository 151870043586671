import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Alert
} from 'reactstrap'
import {
  DropdownList,
  DatePicker,
} from 'react-widgets'
import Moment from 'react-moment'

// components
import CardWrapper from '../../components/CardWrapper'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'

import ReportData from '../../components/quote/ReportData'

// api
import { getQuotesReport } from '../../api/report'
import { fullUserListByRole } from '../../api/user'

// utils
import { dateDropDownList } from '../../libs/list'
import { inValidRole, currentId, numberToCurrency } from '../../libs/utils'

// css
import styles from './Report.module.scss'

const Report = () => {
  const [salesReps, setSalesReps] = useState([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    user: null,
    dateOption: { name: 'Current Month', value: 'current_month' },
    doUpdate: 0,
  })
  const [reportData, setReportData] = useState(null)
  const [doUpdate, setDoUpdate] = useState(1)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (inValidRole(["sales_rep"], localStorage)) {
      setFilters({ ...filters, user: { id: currentId(localStorage) }})
    } else {
      fullUserListByRole('sales_rep').then((res) => {
        setSalesReps(res)
      })
    }
  }, [])

  useEffect(() => {
    setError(null)
    setLoading(true)

    const { startDate, endDate, dateOption } = filters

    if (dateOption.value === 'range' && startDate > endDate) {
      setError('Start Date should be less than End Date')
      setLoading(false)
      return
    }
    
    if (dateOption.value === 'range' && (startDate === null || endDate === null)) {
      setError('Start Date and End Date need to be selected')
      setLoading(false)
      return
    }

    const data = {
      startDate: startDate !== null ? startDate : "",
      endDate: endDate !== null ? endDate : "",
      userId: evalUser(),
      dateOption: dateOption !== null ? dateOption.value : ""
    }

    getQuotesReport(data).then((res) => {
      setReportData(res.data)
      setLoading(false)
    })
  }, [filters.doUpdate])

  // actions

  const applyFilters = () => {
    setFilters({ ...filters, doUpdate: Math.random() })    
  }

  const resetFilters = () => {
    setError(null)
    setFilters({
      startDate: null,
      endDate: null,
      user: null,
      dateOption: { name: 'Current Month', value: 'current_month' },
      doUpdate: Math.random()
    })
  }

  // helpers
  const evalUser = () => {
    if (inValidRole(["sales_rep"], localStorage)) {
      return currentId(localStorage)
    }
    let user = filters.user !== null ? filters.user.id : ""
    
    if (user !== "" && filters.user.name === "AEI") {
      return ""
    }

    return user
  }

  // renders

  return (
    <>
      <PageHeader title="Report" />

      <div className="text-end">
        <Link
          to="/quote"
          className="btn btn-primary btn-xs mb-2"
        >
          Quotes
        </Link>
      </div>

      <CardWrapper header="Filters">
        {error && (
          <Row>
            <Col>
              <Alert color="danger">
                {error}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          {inValidRole(['admin', 'sales_manager', 'csr_manager'], localStorage) && (
            <Col xs={12} md={4}>
              <DropdownList
                data={salesReps}
                textField="name"
                valueField="id"
                placeholder="Sales Rep"
                value={filters.user}
                onChange={(v) => setFilters({ ...filters, user: v })}
              />
            </Col>
          )}
          <Col xs={12} md={4}>
            <DropdownList
              data={dateDropDownList()}
              textField="name"
              valueField="value"
              placeholder="Select Date option"
              value={filters.dateOption}
              onChange={(v) => setFilters({ ...filters, dateOption: v })}
            />

            {(filters.dateOption !== null && filters.dateOption.value === 'range') && (
              <Row className="mt-4">
                <Col>
                  <DatePicker
                    placeholder="Start Date"
                    value={filters.startDate}
                    onChange={(v) => setFilters({ ...filters, startDate: v })}
                  />
                </Col>
                <Col>
                  <DatePicker
                    placeholder="End Date"
                    value={filters.endDate}
                    onChange={(v) => setFilters({ ...filters, endDate: v })}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12} md={4} className="text-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => applyFilters()}
            >
              Apply
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-4"
              onClick={() => resetFilters()}
            >
              Reset
            </button>
          </Col>
        </Row>
      </CardWrapper>

      <CardWrapper>
        {loading || reportData === null ? (
          <Loading />
        ) : (
          <>
            <h4 className={styles.header}>
              Quotes Opened Between:
              <span className={styles.date}>
                {reportData.display_dates.start}
                {' '}
                to
                {' '}
                {reportData.display_dates.end}
              </span>
            </h4>

            <Row>
              <Col xs={12} sm={6}>
                <CardWrapper>
                  Total Quotes: 
                    <span className={styles.bold}>{reportData.total_quotes}</span>
                </CardWrapper>
              </Col>
              <Col xs={12} sm={6}>
                <CardWrapper>
                  Average Quote Amount:
                  <span className={styles.bold}>{numberToCurrency(reportData.average_quote_amount)}</span>
                </CardWrapper>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={3}>
                <ReportData status="Approved" reportData={reportData.statuses.approved} />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <ReportData status="Open" reportData={reportData.statuses.open} />
              </Col>

              <Col xs={12} sm={6} md={3}>
                <ReportData status="Declined" reportData={reportData.statuses.declined} />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <ReportData status="Expired" reportData={reportData.statuses.expired} />
              </Col>
            </Row>
          </>
        )}
      </CardWrapper>
    </>
  )
}

export default Report
