import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import Error from '../Error';

// api
import { saveComment } from '../../api/comment';

const CommentForm = ({ taskId, loadComments }) => {
  const [comment, setComment] = useState({ message: '' });
  const [errors, setErrors] = useState([]);

  // update value
  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    setComment({...comment, [name]: value});
  }

  const handleSubmit = (event) => {
    // save data - reload
    event.preventDefault();

    saveComment(taskId, comment)
      .then((response) => {
        if (response.errors) {
          setErrors(response.errors);
        } else {
          loadComments();
          setComment({ message: '' });
        }
      }
    )
  }

  return (
    <>
      <hr />
      <div className="row m-t-20">
        <div className="col">
          <h4>Add a Comment</h4>
        </div>
      </div>
      <div className="row m-t-20">
        <div className="col">
          <Error errors={errors} />
          <form id="CommentForm" onSubmit={event => handleSubmit(event)}>
            <div>
              <label className="control-label" htmlFor="message">Comment</label>
              <textarea className="form-control" id="message" name="message" rows="5" onChange={event => handleChange(event)} />
            </div>
            <input className="btn btn-primary" type="submit" value="Add Comment" />
          </form>
        </div>
      </div>
    </>
  );
}

export default CommentForm;

CommentForm.propTypes = {
  loadComments: PropTypes.func,
  taskId: PropTypes.string,
};

CommentForm.defaultProps = {
  taskId: '',
  loadComments: null,
};
