import React from 'react';
import Moment from 'react-moment';

import { renderUserIcon } from '../helpers';

class Note extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, note } = this.props;

    return (
      [
        <div className="sl-item note-item">
          <div className="sl-left">
            {renderUserIcon(user)}
          </div>
          <div className="sl-right">
            <div>
              {user && (
                user.name
              )}
              &nbsp;
              <span className="sl-date">
                <Moment calendar>
                  {note.created_at}
                </Moment>
              </span>
              <p>
                {note.note}
              </p>
            </div>
          </div>
        </div>,
        <hr />,
      ]
    );
  }
}

export default Note;
