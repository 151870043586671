import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
// import { DropdownList } from 'react-widgets';
import { Row, Col } from 'reactstrap';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

export default class WeekSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    };
  }

  previousWeek = () => {
    let { startDate, endDate} = this.state;
    startDate = new moment(startDate).subtract(1, 'week').format("YYYY-MM-DD 00:00:00");
    endDate = new moment(endDate).subtract(1, 'week').format("YYYY-MM-DD 23:59:59");
    this.updateDate(startDate, endDate);
  }

  nextWeek = () => {
    let { startDate, endDate } = this.state;
    startDate = new moment(startDate).subtract(-1, 'week').format("YYYY-MM-DD 00:00:00");
    endDate = new moment(endDate).subtract(-1, 'week').format("YYYY-MM-DD 23:59:59");
    this.updateDate(startDate, endDate);
  }

  updateDate(startDate, endDate) {
    this.setState({
      startDate,
      endDate
    }, () => this.props.updateWeekRange(startDate, endDate));
  }

  render() {
    const { startDate, endDate } = this.state;

    return (
      <Row>
        <Col>
          <button className="btn btn-primary" href="#" onClick={() => this.previousWeek()}>
            <ChevronLeftIcon size={24} />
          </button>
        </Col>
        <Col className="text-center">
          <h2>Start Date</h2>
          <Moment format="YYYY-MM-DD">
            {startDate}
          </Moment>
        </Col>
        <Col className="text-center">
          <h2>End Date</h2>
          <Moment format="YYYY-MM-DD">
            {endDate}
          </Moment>
        </Col>
        <Col className="text-right">
          <button className="btn btn-primary" href="#" onClick={() => this.nextWeek()}>
            <ChevronRightIcon size={24} />
          </button>
        </Col>
      </Row>
    );
  }
}