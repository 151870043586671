import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DateTimePicker, Multiselect, DropdownList } from 'react-widgets';
import { Row, Col, Button, ButtonGroup, UncontrolledTooltip, Alert } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import Moment from 'moment';
import moment from 'moment';
// import momentLocalizer from 'react-widgets-moment';
import MomentLocalizer from 'react-widgets-moment';
import DeleteIcon from 'mdi-react/DeleteIcon';
import * as Sentry from '@sentry/browser';

import api from '../../api/init';
import { saveTask, getTask, deleteDoc } from '../../api/task';
import { fullUserList } from '../../api/user';
import { listCompaniesForDropDown } from '../../api/company';

import PageHeader from '../../components/PageHeader';
import PageSubHeader from '../../components/PageSubHeader';
import CardWrapper from '../../components/CardWrapper';
import Error from '../../components/Error';
import CommentContainer from '../../components/comments';

import ErrorBoundary from '../../components/ErrorBoundary';

import styles from './TaskForm.module.scss';

const maxSize = 20971520;
const MAX_DATE = new Date(8640000000000);

Moment.locale('en');
// momentLocalizer();
const localizer = new MomentLocalizer(moment)


const TaskForm = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [task, setTask] = useState({
    name: '',
    due_date: new Date(),
    priority: 'none',
    status: 'open',
    description: '',
    company: null,
    user_list: [],
    user: null,
    docs: [],
  })

  const [dueAt, setDueAt] = useState(null)
  const [users, setUsers] = useState([])
  const [errors, setErrors] = useState([])
  const [companyList, setCompanyList] = useState([{ name: 'No Company', id: '' }])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [pageTitle, setPageTitle] = useState('Task Add')
  const [isNew, setIsNew] = useState(props.isNew)
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasBeenReset, setHasBeenReset] = useState(false)

  const [counter, setCounter] = useState(0)

  const onDrop = useCallback(acceptedFiles => {
    let list = fileList
    acceptedFiles.map(item => {
      list.push(item)
    })
    setFileList(list)
  }, [fileList])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})

  useEffect(() => {
    listCompaniesForDropDown()
      .then((res) => {
        let companyList = [{ name: 'No Company', id: '' },...res.companies];
        setCompanyList(companyList)
      })
    fullUserList()
      .then((res) => {
        setUsers(res)
      })
    console.log('load the default data...')
  }, [])

  useEffect(() => {
    console.log(`ID is ${id}`)

    if (id !== undefined) {
      getTask(id)
        .then((data) => {
          setTask(data)
          if (data.company !== undefined) {
            setSelectedCompany(data.company)
          }
          setIsNew(false)
          setDueAt(new Date(data.due_date))
          setLoading(false)
        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      setLoading(false);
    }
  }, [id])

  // actions
  const upCounter = () => {
    setCounter(counter + 1)
  }

  const updateCompany = (value) => {
    setSelectedCompany(value.value)
    setTask({...task, company: value.value })
  }
  
  // update the user list assigned to task.
  const updateUserList = (value) => {
    setTask({...task, user_list: value})
  }

  const updateDueDate = (value) => {
    setTask({...task, due_date: value})
  }

  const updateTask = (field, value) => {
    setTask({ ...task, [field]: value });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    updateTask(name, value)
  }

  // save the data
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('submitting')

    let formData = new FormData();
    formData.append('task[name]', task.name);
    formData.append('task[due_date]', task.due_date);
    formData.append('task[description]', task.description);
    formData.append('task[priority]', task.priority);
    formData.append('task[status]', task.status);
    task.user_list.map(item => {
      formData.append('task[user_list][][id]', item.id);  
    });
    
    if (task.company) {
      if (task.company.id !== undefined) {
        formData.append('task[company][id]', task.company.id);
      } else {
        formData.append('task[company][id]', task.company.value);
      }
    }

    // files
    fileList.map(file => {
      formData.append('task[docs][][file]', file, file.name);
    });

    saveTask(id, formData)
      .then((res) => {
        if (res.errors !== undefined && res.errors.length > 0) {
          setErrors(res.errors)
          document.getElementsByClassName('errors')[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        } else {
          console.log("success - going to tasks list...")
          navigate("/tasks")
        }
      })

  }

  const filterName = (user, value) => {
    let name = user.name.toLowerCase()
    let search = value.toLowerCase()
    return name.indexOf(search) === 0
  }

  const deleteFile = (event, docId) => {
    event.preventDefault();
    if (window.confirm('Are you sure? Cannot be undone')) {
      deleteDoc({
        id,
        docId
      })
        .then((data) => {
          setTask({...task, docs: data.docs})
        });
    }
  }

  const deleteTempFile = (event, idx) => {
    event.preventDefault();
    if (window.confirm('Are you sure? Cannot be undone')) {
      let list = fileList
      list.splice(idx,1)
      setFileList(list)
      upCounter()
    }
  }

  // renders

  const activePriority = (item) => {
    const { priority } = task
    if (item === priority) { return 'active' }
    return ''
  }

  const activeStatus = (item) => {
    const { status } = task;
    if (item === status) { return 'active' }
    return ''
  }

  const renderCompanyName = () => {
    const { company } = task
    if (company) {
      return (
        <Link to={`/companies/${company.value}`}>
          {company.name}
        </Link>
      );
    }
    return '-- No Company --'
  }

  const isCreator = () => {
    if (isNew || localStorage.getItem('role') === 'admin') { return true }
    const { user } = task
    const loggedIn = localStorage.getItem('uid')
    return loggedIn === user.id
  }

  const renderFileList = () => {
    return (
      [
        task.docs.map(file => (
          <Row key={file.id}>
            <Col md={8}>
              <a href={file.url} target="_blank">
                {file.name}
              </a>
            </Col>
            <Col md={3}>
              {file.size} bytes
            </Col>
            <Col md={1}>
              <button
                onClick={(event) => deleteFile(event, file.id)}
              >
                <DeleteIcon size={24} />
              </button>
            </Col>
          </Row>
        )),
        fileList.map((file, idx) => (
          <Row key={file.name}>
            <Col md={8}>
              {file.name}
            </Col>
            <Col md={3}>
              {file.size} bytes
            </Col>
            <Col md={1}>
              <button
                onClick={(event) => deleteTempFile(event, idx)}
              >
                <DeleteIcon size={24} />
              </button>
            </Col>
          </Row>
        )),
      ]
    );
  }

  return (
    <ErrorBoundary>
      <PageHeader title={pageTitle} />
      <PageSubHeader title="Task Information" />
      {loading && (
        <CardWrapper>
          <Row>
            <Col>
              Loading...
            </Col>
          </Row>
        </CardWrapper>
      )}
      {!loading && (
        <CardWrapper>
          <Error errors={errors} />
          {task.quote && (
            <Row>
              <Col className="text-end">
                <Link
                  to={`/quote/${task.quote}`}
                  className="btn btn-xs btn-secondary"
                >
                  View Quote
                  {' '}
                  #{task.quote_number}
                </Link>
              </Col>
            </Row>
          )}
          <form className="taskForm form-horizontal" onSubmit={event => handleSubmit(event)}>
            {!isNew && (
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Created by</label>
                    <div className="task-box">
                      {task.user.name}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Create Date:</label>
                    <div className="task-box">
                      {Moment(task.created_at).format('MM/DD/YYYY h:mm A')}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              {!isNew && (
                <Col md={4}>
                  <label className="control-label" htmlFor="status">Status</label>
                  &nbsp;
                  <ButtonGroup>
                    <Button className={activeStatus('open')} onClick={() => updateTask('status', 'open')}>Open</Button>
                    <Button className={activeStatus('complete')} onClick={() => updateTask('status', 'complete')}>Complete</Button>
                  </ButtonGroup>
                </Col>
              )}
              <Col md={4}>
                <div className="form-group">
                  <label className="control-label" htmlFor="priority">Priority</label>
                  &nbsp;
                  {isCreator() && (
                    <ButtonGroup>
                      <Button className={activePriority('none')} onClick={() => updateTask('priority', 'none')}>None</Button>
                      <Button className={activePriority('low')} onClick={() => updateTask('priority', 'low')}>Low</Button>
                      <Button className={activePriority('high')} onClick={() => updateTask('priority', 'high')}>High</Button>
                      <Button className={activePriority('urgent')} onClick={() => updateTask('priority', 'urgent')}>Urgent</Button>
                    </ButtonGroup>
                  )}
                  {!isCreator() && (
                    <span className="task-box">
                      {task.priority}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
            {isCreator() && (
              [
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="company" className="control-label">Company</label>
                      <DropdownList name="company" id="company"
                        filter
                        data={companyList}
                        value={selectedCompany}
                        onChange={value => updateCompany({ value })}
                        textField="name"
                      />
                    </div>
                    <p className="text-right">
                      Company must exist, if the company does not please
                      &nbsp;
                      <Link to="/companies/new" className="btn btn-primary btn-xs">
                        add
                      </Link>
                    </p>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="name">Name of Task (Title)</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        onChange={event => handleInputChange(event)}
                        value={task.name}
                      />
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="users">Assigned To</label>
                      <Multiselect
                        data={users}
                        textField='name'
                        value={task.user_list}
                        caseSensitive={false}
                        minLength={2}
                        filter={filterName}
                        onChange={value => updateUserList(value)}
                      />
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col md={4}>
                    <div className="form-group">
                      <label className="control-label" htmlFor="due_date">Due Date</label>
                      <DateTimePicker
                        placeholder="Select Date and Time..."
                        name="due_date"
                        value={new Date(task.due_date)}
                        min={isNew ? new Date() : new Date(task.due_date)}
                        max={MAX_DATE}
                        includeTime
                        onChange={value => updateDueDate(value)}
                      />
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="description">Description</label>
                      <textarea
                        name="description"
                        id="description"
                        className="form-control"
                        value={task.description}
                        onChange={event => handleInputChange(event)}
                        rows="10"
                      />
                    </div>
                  </Col>
                </Row>,
              ]
            )}
            {!isCreator() && (
              [
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Created by</label>
                      <div className="task-box">
                        {task.user.name}
                      </div>
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="company" className="control-label">Company</label>
                      <div className="task-box">
                        {renderCompanyName()}
                      </div>
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="name">Name of Task (Title)</label>
                      <div className="task-box">
                        {task.name}
                      </div>
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="users">Assigned To</label>
                      <div className="task-box">
                        {task.user_list.map(user => (
                          [
                            <img src={user.avatar} alt={user.name} className="img-circle" width={50} id={'user-' + user.id} />,
                            <UncontrolledTooltip placement="bottom" target={'user-' + user.id}>
                              {user.name}
                            </UncontrolledTooltip>,
                          ]
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col md={4}>
                    <div className="form-group">
                      <label className="control-label" htmlFor="due_date">Due Date</label>
                      <div className="task-box">
                        {Moment(task.due_date).format('MM/DD/YYYY h:mm A')}
                      </div>
                    </div>
                  </Col>
                </Row>,
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label" htmlFor="description">Description</label>
                      <div className="task-box task-description">
                        {task.description}
                      </div>
                    </div>
                  </Col>
                </Row>,
              ]
            )}
            <Row>
              <Col md={4}>
                <div {...getRootProps()} className={styles.dropArea}>
                  <div {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>Drop the files here ...</p> :
                      <p>Drag 'n' drop some files here, or click to select files</p>
                  }
                </div>
              </Col>
              <Col md={8}>
                <h3>File List</h3>
                {renderFileList()}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="text-center">
                <input type="submit" value="save" className="btn btn-primary" />
                &nbsp;
                <Link to="/tasks" className="btn btn-secondary">
                  Cancel
                </Link>
              </Col>
            </Row>
          </form>
        </CardWrapper>
      )}
      {!isNew &&
        <Fragment>
          <PageSubHeader title="Comments" />
          <CardWrapper>
            <CommentContainer taskId={id} />
          </CardWrapper>
        </Fragment>
      }
    </ErrorBoundary>
  )
}



// class TaskForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       id: '',
//       task: {
//         name: '',
//         due_date: null,
//         priority: 'none',
//         status: 'open',
//         description: '',
//         company: null,
//         user_list: [],
//         user: null,
//         docs: [],
//       },
//       dueAt: null,
//       users: [],
//       errors: [],
//       companyList: [{ name: 'No Company', id: '' }],
//       selectedCompany: null,
//       pageTitle: 'Task Add',
//       isNew: this.props.isNew,
//       fileList: [],
//       loading: true,
//       hasBeenReset: false,
//     };

//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleInputChange = this.handleInputChange.bind(this);
//     this.updateDueDate = this.updateDueDate.bind(this);
//   }

//   componentWillReceiveProps(newProps) {
//     console.log(newProps.match.params.id);
//     const { id } = this.state;
//     console.log(id);
//     if (newProps.match.params.id !== id) {
//       if (newProps.match.params.id === undefined) {
//         this.resetForm();
//       } else {
//         getTask(newProps.match.params.id)
//           .then((data) => {
//             this.setState({
//               id: newProps.match.params.id,
//               task: data,
//               selectedCompany: data.company,
//               isNew: false,
//               dueAt: new Date(data.due_date)
//             }, () => this.setState({ loading: false }));
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       }
//     }
//   }

//   resetForm() {
//     const { hasBeenReset } = this.state;
//     if (!hasBeenReset) {
//       console.log('resetting data');
//       this.setState({
//         task: {
//           name: '',
//           due_date: null,
//           priority: 'none',
//           status: 'open',
//           description: '',
//           company: null,
//           user_list: [],
//           user: null,
//           docs: [],
//         },
//         dueAt: null,
//         pageTitle: 'Task Add',
//         isNew: true,
//         hasBeenReset: true,
//       });
//     }
//   }

//   componentDidMount() {
//     const { params } = this.props.match;
//     const { isNew } = this.props;

//     if (!isNew) {
//       this.setState({ id: params.id, pageTitle: 'Task Edit' });
//     } else {
//       this.resetForm();
//     }

//     this.loadListOfUsers(() => {
//       this.loadCompanies(() => {
//         if (!isNew) {
//           getTask(params.id)
//             .then((data) => {
//               this.setState({ 
//                 task: data, 
//                 selectedCompany: data.company, 
//                 dueAt: new Date(data.due_date) 
//               }, () => this.setState({ loading: false }));
//             })
//             .catch((error) => {
//               console.log(error);
//             });
//         } else {
//           this.setState({ loading: false });
//         }
//       });
//     });    
//   }

//   loadListOfUsers(callback) {
//     fullUserList()
//       .then((res) => {
//         this.setState({ users: res }, () => callback());
//       });
//   }

//   loadCompanies(callback) {
//     listCompaniesForDropDown()
//       .then((res) => {
//         let companyList = [{ name: 'No Company', id: '' },...res.companies];
//         this.setState({ companyList }, () => callback());
//       });
//   }

//   filterName(user, value) {
//     let name = user.name.toLowerCase()
//     let search = value.toLowerCase();
//     return name.indexOf(search) === 0
//   }

//   updateUserList(value) {
//     this.updateTask('user_list', value);
//   }

//   updateTask(key, value) {
//     this.setState(prevState => ({
//       task: {
//         ...prevState.task,
//         [key]: value,
//       },
//     }));
//   }

//   handleInputChange(event) {
//     const { target } = event;
//     const { name } = target;
//     const { value } = target;
//     this.updateTask(name, value);
//   }

//   updateDueDate(value) {
//     console.log(value);
//     this.setState({ dueAt: value }, () => {
//       this.setState(prevState => ({
//         task: {
//           ...prevState.task,
//           due_date: value,
//         },
//       }));
//     });
//   }

//   updateCompany(value) {
//     this.setState({ selectedCompany: value.value });
//     this.updateTask('company', value.value);
//   }

//   buildFormData() {
//     let formData = new FormData();
//     const { task, fileList } = this.state;

//     // task info
//     formData.append('task[name]', task.name);
//     formData.append('task[due_date]', task.due_date);
//     formData.append('task[description]', task.description);
//     formData.append('task[priority]', task.priority);
//     formData.append('task[status]', task.status);
//     task.user_list.map(item => {
//       formData.append('task[user_list][][id]', item.id);  
//     });
    
//     if (task.company) {
//       formData.append('task[company][id]', task.company.id);
//     }

//     // files
//     fileList.map(file => {
//       formData.append('task[docs][][file]', file, file.name);
//     });
    
//     return formData;
//   }

//   handleSubmit(event) {
//     event.preventDefault();
//     const { task } = this.state;
//     const { params } = this.props.match;
//     const { history } = this.props;
//     const obj = this;

//     delete task.comments;

//     if (params.id !== undefined) {
//       this.setState({ id: params.id });
//       api
//         .patch(`/api/v1/tasks/${params.id}.json`, this.buildFormData())
//         .then((response) => {
//           console.log(response);
//           if (response.data.errors != undefined && response.data.errors.length > 0) {
//             obj.setState({ errors: response.data.errors });
//             document.getElementsByClassName('errors')[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
//           } else {
//             history.push('/tasks');
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     } else {
//       api
//         .post('/api/v1/tasks.json', this.buildFormData())
//         .then((response) => {
//           console.log(response);
//           if (response.data.errors != undefined && response.data.errors.length > 0) {
//             obj.setState({ errors: response.data.errors });
//             document.getElementsByClassName('errors')[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
//           } else {
//             history.push('/tasks');
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }

//   activePriority(item) {
//     const { priority } = this.state.task;
//     if (item === priority) { return 'active'; }
//     return '';
//   }

//   activeStatus(item) {
//     const { status } = this.state.task;
//     if (item === status) { return 'active'; }
//     return '';
//   }

//   deleteFile = (event, docId) => {
//     const { id } = this.state;
//     event.preventDefault();
//     if (window.rm('Are you sure? Cannot be undone')) {
//       console.log(id);
//       deleteDoc({
//         id,
//         docId
//       })
//         .then((data) => {
//           this.updateTask('docs', data.docs);
//         });
//     }
//   }

//   deleteTempFile = (event, idx) => {
//     event.preventDefault();
//     if (window.confirm('Are you sure? Cannot be undone')) {
//       const { fileList } = this.state;
//       fileList.splice(idx,1);
//       this.setState({
//         fileList: fileList
//       });
//     }
//   }

//   renderFileList() {
//     const { fileList, task } = this.state;

//     return (
//       [
//         task.docs.map(file => (
//           <Row key={file.id}>
//             <Col md={8}>
//               <a href={file.url} target="_blank">
//                 {file.name}
//               </a>
//             </Col>
//             <Col md={3}>
//               {file.size} bytes
//             </Col>
//             <Col md={1}>
//               <a href="#" onClick={(event) => this.deleteFile(event, file.id)}>
//                 <DeleteIcon size={24} />
//               </a>
//             </Col>
//           </Row>
//         )),
//         fileList.map((file, idx) => (
//           <Row key={file.name}>
//             <Col md={8}>
//               {file.name}
//             </Col>
//             <Col md={3}>
//               {file.size} bytes
//             </Col>
//             <Col md={1}>
//               <a href="#" onClick={(event) => this.deleteTempFile(event, idx)}>
//                 <DeleteIcon size={24} />
//               </a>
//             </Col>
//           </Row>
//         )),
//       ]
//     );
//   }

//   onDrop = (files) => {
//     let { fileList } = this.state;
//     if (fileList.length > 0) {
//       files.map(item => {
//         fileList.push(item);
//       })
//       files = fileList;
//     }
//     console.log(fileList);
//     this.setState({
//       fileList: files
//     });
//   }

//   renderCompanyName() {
//     const { task } = this.state;
//     const { company } = task;
//     if (company) {
//       return (
//         <Link to={`/companies/${company.value}`}>
//           {company.name}
//         </Link>
//       );
//     }
//     return '-- No Company --';
//   }



//   render() {
//     const { task, errors, dueAt, users, isNew, companyList, selectedCompany, loading, id } = this.state;
//     const { params } = this.props.match;

//     return (
//       <ErrorBoundary>
//         <PageHeader title={this.state.pageTitle} />
//         <PageSubHeader title="Task Information" />
//         {loading && (
//           <CardWrapper>
//             <Row>
//               <Col>
//                 Loading...
//               </Col>
//             </Row>
//           </CardWrapper>
//         )}
//         {!loading && (
//           <CardWrapper>
//             <Error errors={errors} />
//             <form className="taskForm form-horizontal" onSubmit={this.handleSubmit}>
//               {!isNew && (
//                 <Row>
//                   <Col md="6">
//                     <div className="form-group">
//                       <label>Created by</label>
//                       <div className="task-box">
//                         {task.user.name}
//                       </div>
//                     </div>
//                   </Col>
//                   <Col md="6">
//                     <div className="form-group">
//                       <label>Create Date:</label>
//                       <div className="task-box">
//                         {Moment(task.created_at).format('MM/DD/YYYY h:mm A')}
//                       </div>
//                     </div>
//                   </Col>
//                 </Row>
//               )}
//               <Row>
//                 {!isNew && (
//                   <Col md={4}>
//                     <label className="control-label" htmlFor="status">Status</label>
//                     &nbsp;
//                     <ButtonGroup>
//                       <Button className={this.activeStatus('open')} onClick={() => this.updateTask('status', 'open')}>Open</Button>
//                       <Button className={this.activeStatus('complete')} onClick={() => this.updateTask('status', 'complete')}>Complete</Button>
//                     </ButtonGroup>
//                   </Col>
//                 )}
//                 <Col md={4}>
//                   <div className="form-group">
//                     <label className="control-label" htmlFor="priority">Priority</label>
//                     &nbsp;
//                     {this.isCreator() && (
//                       <ButtonGroup>
//                         <Button className={this.activePriority('none')} onClick={() => this.updateTask('priority', 'none')}>None</Button>
//                         <Button className={this.activePriority('low')} onClick={() => this.updateTask('priority', 'low')}>Low</Button>
//                         <Button className={this.activePriority('high')} onClick={() => this.updateTask('priority', 'high')}>High</Button>
//                         <Button className={this.activePriority('urgent')} onClick={() => this.updateTask('priority', 'urgent')}>Urgent</Button>
//                       </ButtonGroup>
//                     )}
//                     {!this.isCreator() && (
//                       <span className="task-box">
//                         {task.priority}
//                       </span>
//                     )}
//                   </div>
//                 </Col>
//               </Row>
//               {this.isCreator() && (
//                 [
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label htmlFor="company" className="control-label">Company</label>
//                         <DropdownList name="company" id="company"
//                           filter
//                           data={companyList}
//                           value={selectedCompany}
//                           onChange={value => this.updateCompany({ value })}
//                           textField="name"
//                         />
//                       </div>
//                       <p className="text-right">
//                         Company must exist, if the company does not please
//                         &nbsp;
//                         <Link to="/companies/new" className="btn btn-primary btn-xs">
//                           add
//                         </Link>
//                       </p>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="name">Name of Task (Title)</label>
//                         <input
//                           type="text"
//                           id="name"
//                           name="name"
//                           className="form-control"
//                           onChange={this.handleInputChange}
//                           value={task.name}
//                         />
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="users">Assigned To</label>
//                         <Multiselect
//                           data={users}
//                           textField='name'
//                           value={task.user_list}
//                           caseSensitive={false}
//                           minLength={2}
//                           filter={this.filterName}
//                           onChange={value => this.updateUserList(value)}
//                         />
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col md={4}>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="due_date">Due Date</label>
//                         <DateTimePicker
//                           placeholder="Select Date and Time..."
//                           name="due_date"
//                           value={dueAt}
//                           min={new Date()}
//                           onChange={value => this.updateDueDate(value)}
//                         />
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="description">Description</label>
//                         <textarea
//                           name="description"
//                           id="description"
//                           className="form-control"
//                           value={task.description}
//                           onChange={this.handleInputChange}
//                           rows="10"
//                         />
//                       </div>
//                     </Col>
//                   </Row>,
//                 ]
//               )}
//               {!this.isCreator() && (
//                 [
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label>Created by</label>
//                         <div className="task-box">
//                           {task.user.name}
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label htmlFor="company" className="control-label">Company</label>
//                         <div className="task-box">
//                           {this.renderCompanyName()}
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="name">Name of Task (Title)</label>
//                         <div className="task-box">
//                           {task.name}
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="users">Assigned To</label>
//                         <div className="task-box">
//                           {task.user_list.map(user => (
//                             [
//                               <img src={user.avatar} alt={user.name} className="img-circle" width={50} id={'user-' + user.id} />,
//                               <UncontrolledTooltip placement="bottom" target={'user-' + user.id}>
//                                 {user.name}
//                               </UncontrolledTooltip>,
//                             ]
//                           ))}
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col md={4}>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="due_date">Due Date</label>
//                         <div className="task-box">
//                           {Moment(task.due_date).format('MM/DD/YYYY h:mm A')}
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>,
//                   <Row>
//                     <Col>
//                       <div className="form-group">
//                         <label className="control-label" htmlFor="description">Description</label>
//                         <div className="task-box task-description">
//                           {task.description}
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>,
//                 ]
//               )}
//               <Row>
//                 <Col md={4}>
//                   <Dropzone
//                     onDrop={this.onDrop}
//                     minSize={0}
//                     maxSize={maxSize}
//                     multiple
//                   >
//                     {({ getRootProps, getInputProps, isDragActive, rejectedFiles }) => {
//                       const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
//                       return (
//                         <section className="container">
//                           <div {...getRootProps({ className: 'dropzone' })}>
//                             <input id="docFiles" {...getInputProps()} />
//                             <p>
//                               {!isDragActive && 'Click here or drop a file to upload!'}
//                               {isDragActive && "Drop it like it's hot!"}
//                               {isFileTooLarge && 'File is too large.'}
//                             </p>
//                           </div>
//                         </section>
//                       )
//                     }}
//                   </Dropzone>
//                 </Col>
//                 <Col md={8}>
//                   <h3>File List</h3>
//                   {this.renderFileList()}
//                 </Col>
//               </Row>
//               <hr />
//               <Row>
//                 <Col className="text-center">
//                   <input type="submit" value="save" className="btn btn-primary" />
//                   &nbsp;
//                   <Link to="/tasks" className="btn btn-secondary">
//                     Cancel
//                   </Link>
//                 </Col>
//               </Row>
//             </form>
//           </CardWrapper>
//         )}
//         {!isNew &&
//           <Fragment>
//             <PageSubHeader title="Comments" />
//             <CardWrapper>
//               <CommentContainer taskId={id} />
//             </CardWrapper>
//           </Fragment>
//         }
//       </ErrorBoundary>
//     );
//   }
// }

export default TaskForm;
