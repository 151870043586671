import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from 'mdi-react/SearchIcon';
import CloseIcon from 'mdi-react/CloseIcon';

import { search } from '../api/search';

let searchStyle = { display: 'none' };

const Search = () => {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const [searchResults, setSearchResults] = useState({
    data: {
      tasks: [],
      companies: [],
      contacts: [],
      events: [],
    },
    info: {
      task_records: 0,
      company_records: 0,
      contact_records: 0,
      event_records: 0,
    }
  });

  useEffect(() => {
    setDefaultSearchResults();
  }, [])

  useEffect(() => {
    if (searchParams.length >= 3) {
      search(searchParams)
        .then((data) => {
          setSearchResults(data)
        });
    }
  }, [searchParams])
  
  const setDefaultSearchResults = () => {
    setSearchResults(
      {
        data: {
          tasks: [],
          companies: [],
          contacts: [],
          events: [],
        },
        info: {
          task_records: 0,
          company_records: 0,
          contact_records: 0,
          event_records: 0,
        }
    })
  }


  const updateSearchParams = (e) => {
    e.preventDefault();
    const { target } = e;
    const { value } = target;

    setSearchParams(value);

    this.setState({
      searchParam: value,
    }, () => this.doSearch());
  }

  const enabledSearch = (e) => {
    e.preventDefault();
    setShowSearch(!showSearch);
    
    if (showSearch) {
      searchStyle = { display: 'block' };
      setSearchParams('')
      setDefaultSearchResults()

      fixSearchResultsBox()
      
    } else {
      searchStyle = { display: 'none' };
    }
  }

  const fixSearchResultsBox = () => {
    const h = window.outerHeight - document.getElementsByClassName("app-search")[0].offsetHeight;
    document.getElementsByClassName("mega-dropdown-menu")[0].style.height = h + "px";
    document.getElementsByName("globalSearch")[0].focus();
  }

  const goToLink = (e, link) => {
    e.preventDefault();
    hideSearch()
    navigate(link)
  }

  const hideSearch = () => {
    console.log("hide search...");
    setShowSearch(false);
    searchStyle = { display: 'none' };
  }

  const renderResults = (data) => {
    return ( 
      data.map(item => (
        <Row>
          <Col>
            <a
              href="#"
              onClick={(e) => goToLink(e, item.link)}
            >
              {item.name}
            </a>
          </Col>
        </Row>
      ))
    );
  }


  // const { task_records, company_records, contact_records, event_records } = info;


  return (
    <li className="nav-item hidden-sm-down search-box mega-dropdown">
      <a
        role="button"
        className="nav-link hidden-sm-down text-muted waves-effect waves-dark"
        href="#"
        onClick={(e) => enabledSearch(e)}
      >
        <SearchIcon
          size={24}
        />
      </a>
      <form className="app-search" style={searchStyle}>
        <input
          type="search"
          name="globalSearch"
          className="form-control"
          placeholder="Search & enter"
          onChange={(e) => updateSearchParams(e)}
          value={searchParams}
        />
        <a
          href="#"
          className="srh-btn"
          onClick={(e) => enabledSearch(e)}
        >
          <CloseIcon
            size={24}
          />
        </a>
        {searchResults !== null && (
          <div className="dropdown-menu show">
            <ul className="mega-dropdown-menu row">
              <li className="col-md-3">
                <h4>Tasks</h4>
                <p>{searchResults.info.task_records} total</p>
                {renderResults(searchResults.data.tasks)}
              </li>
              <li className="col-md-3">
                <h4>Companies</h4>
                <p>{searchResults.info.company_records} total</p>
                {renderResults(searchResults.data.companies)}
              </li>
              <li className="col-md-3">
                <h4>Contacts</h4>
                <p>{searchResults.info.contact_records} total</p>
                {renderResults(searchResults.data.contacts)}
              </li>
              <li className="col-md-3">
                <h4>Events</h4>
                <p>{searchResults.info.event_records} total</p>
                {renderResults(searchResults.data.events)}
              </li>
            </ul>
          </div>
        )}
      </form>
    </li>
  )
}

export default Search;