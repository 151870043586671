import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Badge,
  Alert
} from 'reactstrap';
import Moment from 'react-moment'

// components
import PageHeader from '../../../components/PageHeader'
import CardWrapper from '../../../components/CardWrapper'

// api
import { getCompanySources, saveCompanySource } from '../../../api/company'

// styles
import styles from './CompanySources.module.scss'


const CompanySources = () => {
  const [companySources, setCompanySources] = useState([])
  const [companySource, setCompanySource] = useState({})
  const [loading, setLoading] = useState(true)
  const [inactive, setInactive] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    getCompanySources(inactive)
      .then(data => {
        setCompanySources(data.company_sources);
        setLoading(false);
      });
  }, [inactive]);

  // action
  const toggle = () => setIsOpen(!isOpen);

  const create = () => {
    setCompanySource({ name: '', active: true });
    toggle();
  }

  const edit = (id) => {
    const companySource = companySources.find(companySource => companySource.id === id);
    setCompanySource(companySource);
    toggle();
  }

  const saveForm = (e) => {
    e.preventDefault();

    if (companySource.name === '') {
      alert('Name is required');
      return;
    }
    saveCompanySource(companySource)
      .then(data => {
        getCompanySources(inactive)
          .then(data => {
            setCompanySources(data.company_sources);
            setLoading(false);
          });
        toggle();
      });
  }

  const resetForm = () => {
    setCompanySource({ name: '', active: true });
    toggle();
  }

  const updateActive = (id, active) => {
    const data = { id, active };
    saveCompanySource(data)
      .then(data => {
        getCompanySources(inactive)
          .then(data => {
            setCompanySources(data.company_sources);
            setLoading(false);
          });
      });
  }

  // render

  const renderHeader = () => {
    return (
      <>
        <br />
        <Row>
          <Col xs={12} md={4} className={styles.bold}>Name</Col>
          <Col xs={12} md={2} className={styles.bold}>Active</Col>
          <Col xs={12} md={3} className={styles.bold}>Updated At</Col>
          <Col xs={12} md={3} className={[styles.bold, styles.rowActions].join(' ')}>Actions</Col>
        </Row>
        <hr />
      </>
    )
  }

  const renderRows = ({id, name, updated_at, active}) => {
    return (
      <>
        <Row key={id}>
          <Col xs={12} md={4}>{name}</Col>
          <Col xs={12} md={2}>{active ? <Badge color="primary">Yes</Badge> : <Badge color="danger">No</Badge>}</Col>
          <Col xs={12} md={3}>
            <Moment format="MM/DD/YYYY hh:mm a ">
              {updated_at}
            </Moment>
          </Col>
          <Col xs={12} md={3} className={styles.rowActions}>
            <Button
              color="primary"
              onClick={() => edit(id)}
            >
              Edit
            </Button>
            {' '}
            {active ? (
              <Button
                color="danger"
                onClick={() => updateActive(id, false)}
              >
                Set Inactive
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => updateActive(id, true)}
              >
                Set Active
              </Button>

            )}
          </Col>
        </Row>
        <hr />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>The Hub - Company Sources</title>
      </Helmet>
      <PageHeader title="Company Sources" />
      <CardWrapper>
        <Row>
          <Col xs={12} md={6}>
            <ButtonGroup>
              <Button onClick={() => setInactive(false)} active={!inactive}>Active</Button>
              <Button onClick={() => setInactive(true)} active={inactive}>Inactive</Button>
            </ButtonGroup>
          </Col>
          <Col xs={12} md={6} className="text-end">
            <button
              onClick={create}
              className="btn btn-primary"
            >
              Add New Company Source
            </button>
          </Col>
        </Row>
        <br />
        <Alert>
          <h4>Notice</h4>
          <ul>
            <li>Active list will display in the dropdown.</li>
            <li>All <strong>OTHER</strong> entries will be stored as "inactive".</li>
          </ul>
        </Alert>
        <Row>
          <Col md={12}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {renderHeader()}
                {companySources.map((item) => (
                  renderRows(item)
                ))}
              </>
            )}
          </Col>
        </Row>
      </CardWrapper>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader
          toggle={toggle}
        >
          Company Sources
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => saveForm(e)}>
            <FormGroup floating>
              <Input
                type="text"
                name="name"
                id="name"
                value={companySource.name}
                onChange={(e) => setCompanySource({...companySource, name: e.target.value})}
              />
              <Label for="name">Name</Label>
            </FormGroup>
            <FormGroup switch>
              <Input
                type="checkbox"
                name="active"
                id="active"
                checked={companySource.active}
                onChange={(e) => setCompanySource({...companySource, active: e.target.checked})}
              />
              <Label for="active">Active</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => saveForm(e)}
          >
            Save
          </Button>
          <Button
            color="secondary"
            onClick={resetForm}
          >
            Cancel
          </Button>
        </ModalFooter>

      </Modal>
    </>
  );
}


export default CompanySources