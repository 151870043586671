import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import LogoutIcon from 'mdi-react/LogoutIcon';
import LoginIcon from 'mdi-react/LoginIcon';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';

export default class ActivityLogItem extends React.Component {

  renderIcon = (activity_type) => {
    let icon = null;
    switch(activity_type) {
      case 'login':
        icon = <LoginIcon size={16} />;
        break;
      case 'logout':
        icon = <LogoutIcon size={16} />;
        break;
      case 'task_create':
      case 'task_update':
      case 'task_destroy':
        icon = <FormatListBulletedIcon size={16} />
        break;  
      case 'company_create':
      case 'company_update':
      case 'company_destroy':
      case 'company_activity_create':
      case 'company_note_create':
        icon = <DomainIcon size={16} />
        break;  
      case 'event_create':
      case 'event_update':
      case 'event_destroy':
        icon = <CalendarIcon size={16} />
        break;  
    }
    return (
      icon
    );
  }

  renderTaskInfo = (activity_log) => {
    if (activity_log.info.task_id === undefined)
    {
      return null;
    }
    return (
      <div>
        <h6>Task Info</h6>
        {activity_log.activity_type.indexOf('destroy') > -1 && (
          <span>
            Deleted - {activity_log.info.task_name}
          </span>
        )}
        {activity_log.activity_type.indexOf('destroy') === -1 && (
          <Link
            to={{
              pathname: `/tasks/${activity_log.info.task_id}`,
            }}
          >
            {activity_log.info.task_name}
            <ArrowRightIcon size={12} />
          </Link>
        )}
      </div>
    );
  }

  renderCompanyInfo = (activity_log) => {
    if (activity_log.info.company_id === undefined)
    {
      return null;
    }
    return (
      <div>
        <h6>Company Info</h6>
        {activity_log.activity_type.indexOf('destroy') > -1 && (
          <span>
            Deleted - {activity_log.info.company_name}
          </span>
        )}
        {activity_log.activity_type.indexOf('destroy') === -1 && (
          <Link
            to={{
              pathname: `/companies/${activity_log.info.company_id}`,
            }}
          >
            {activity_log.info.company_name}
            <ArrowRightIcon size={12} />
          </Link>
        )}
      </div>
    );
  }
  
  renderEventInfo = (activity_log) => {
    if (activity_log.info.event_id === undefined)
    {
      return null;
    }
    return (
      <div>
        <h6>Event Info</h6>
        {activity_log.activity_type.indexOf('destroy') > -1 && (
          <span>
            Deleted - {activity_log.info.event_name}
          </span>
        )}
        {activity_log.activity_type.indexOf('destroy') === -1 && (
          <Link
            to={{
              pathname: `/events/${activity_log.info.event_id}`,
            }}
          >
            {activity_log.info.event_name}
            <ArrowRightIcon size={12} />
          </Link>
        )}
      </div>
    );
  }

  render() {
    const { activity_log } = this.props;
    return (
      <Row className="p-t-10 p-b-10 border-bottom">
        <Col>
          <Moment format="MM/DD/YYYY h:mm A">
            {activity_log.activity_date}
          </Moment>
        </Col>
        <Col>
        {this.renderIcon(activity_log.activity_type)}
        &nbsp;
          {activity_log.activity_type}
        </Col>
        <Col>
          {activity_log.info.name}
          <br />
          {activity_log.info.email}
        </Col>
        <Col>
          {this.renderTaskInfo(activity_log)}
          {this.renderCompanyInfo(activity_log)}
          {this.renderEventInfo(activity_log)}
        </Col>
      </Row>
    )
  }
}
