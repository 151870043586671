import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import { DropdownList } from 'react-widgets';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import PlusIcon from 'mdi-react/PlusIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import Error from '../Error';
import { saveActivity } from '../../api/company';
// import { activityTypes } from '../../../libs/utils';
import { editor_toolbar, decode_message_object } from '../../libs/editor';

const howFarOutList = [
  { title: "Week 1", value: 1 },
  { title: "Week 2", value: 2 },
  { title: "Week 3", value: 3 },
  { title: "Week 4", value: 4 },
  { title: "Week 5", value: 5 },
  { title: "Week 6", value: 6 },
  { title: "Week 7", value: 7 },
  { title: "Week 8", value: 8 },
  { title: "Week 9", value: 9 },
  { title: "Week 10", value: 10 },
  { title: "Week 11", value: 11 },
  { title: "Week 12", value: 12 },
];
const activityTypes = ['Face to Face', 'Phone Call', 'Video Call', 'Text', 'Email', 'Product Issues', 'AEI Responsibility', 'Complaints/Resolutions', 'Training', 'Misc.'];
const list = ['face_to_face', 'phone', 'video_call', 'text', 'email', 'product_issues', 'aei', 'complaints_resolutions', 'training', 'misc'];
const activityTaskTypes = ['Face to Face', 'Phone Call', 'Video Call', 'Text', 'Email'];

class Form extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      activity: {
        activityType: '',
        message: '',
        taskType: list[0],
        howFarOut: howFarOutList[0],
        taskDescription: '',
      },
      saving: false,
      errors: [],
      displayActivityType: '',
      displayActivityTaskType: activityTaskTypes[0],
      fileName: '',
      modal: false,
      showTask: false,
    };

    this.submitActivity = this.submitActivity.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  setActivityType(value) {
    const v = list[activityTypes.indexOf(value.value)];
    this.setState({ displayActivityType: value.value });
    this.updateActivity('activityType', v);
  }

  setActivityTaskType(value) {
    const v = list[activityTaskTypes.indexOf(value.value)];
    this.setState({ displayActivityTaskType: value.value });
    this.updateActivity('taskType', v);
  }

  setHowFarOut(value) {
    this.updateActivity('howFarOut', value.value);
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.updateActivity(name, value);
  }

  handleEditorChange = (content) => {
    // const content = e.target.value;
    const rawContentState = convertToRaw(content.getCurrentContent())
    const markup = draftToHtml(
      rawContentState, 
      {},
      {},
      {}
    );
    console.log(markup);
    this.updateActivity('message', content);
  }

  handleTaskDescription = (event) => {
    const { target } = event;
    const { name, value } = target;
    this.updateActivity("taskDescription", value);
  }

  updateActivity(name, value) {
    this.setState(prevState => ({
      activity: {
        ...prevState.activity,
        [name]: value,
      },
    }));
  }


  submitActivity(event) {
    event.preventDefault();
    const { activity, showTask } = this.state;
    this.setState({ saving: true, errors: [] });

    let errors = [];
    if (activity.message === '') {
      errors.push('Must include a message or details');
    }
    if (activity.activityType === '') {
      errors.push('Must select an activity type');
    }
    if (errors.length > 0) {
      this.setState({ saving: false });
      this.setState({ errors });
    } else {
      // submit activity...
      let formData = new FormData();
      formData.append('activity[message]', decode_message_object(activity.message));
      formData.append('activity[activity_type]', activity.activityType);
      if (showTask) {
        formData.append('activity[add_task]', true);
        formData.append('activity[task_type]', activity.taskType);
        formData.append('activity[how_far_out]', activity.howFarOut.value);
        formData.append('activity[task_description]', activity.taskDescription);
      }

      const files = document.getElementById('single_file').files;
      if (files.length === 1) {
        formData.append('company[company_documents][][file]', files[0], files[0].name);
      }

      saveActivity(this.props.companyId, formData)
        .then(response => {
          console.log(response)
          if (response.success) {
            console.log('saved');
            this.toggleModal()
            this.props.updateActivityList();
          } else {
            this.setState({ errors: response.errors });
          }
          this.setState({ saving: false, fileName: '', showForm: false });
        });
    }
  }

  displayFileName() {
    const file = document.getElementById('single_file').files[0];
    this.setState({ fileName: file.name });
  }

  resetForm(updateList, callback) {
    this.setState({
      displayActivityType: '',
      errors: [],
      saving: false,
      showTask: false,
      activity: { message: "", activityType: "", taskType: '', howFarOut: howFarOutList[0], taskDescription: "" }
    }, () => callback);

    if (updateList) {
      this.props.updateActivityList();
    }
  }

  renderSaveButton() {
    const { saving } = this.state;
    if (saving) {
      return (
        <span>
          Saving
        </span>
      );
    }
    return (
      <Row>
        <Col xs={12} md={9}>
          <button type="submit" className="btn btn-primary btn-block">
            <ContentSaveIcon size={16} />
            &nbsp;
            Log Activity
          </button>          
        </Col>
        <Col xs={12} md={3}>
          <Button className="btn btn-secondary btn-block" onClick={this.toggleModal}>Cancel</Button>
        </Col>
      </Row>
    );
  }

  renderErrors() {
    const { errors } = this.state;
    if (errors.length === 0) {
      return null;
    }
    return (
      <Error errors={errors} />
    );
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal
    });

    if (!modal === true) {
      this.resetForm(false, null);
    }
  }

  toggleTask = () => {
    const { showTask } = this.state;
    this.setState({
      showTask: !showTask
    });
  }

  render() {
    const { activity, displayActivityType, displayActivityTaskType, fileName, showTask, modal } = this.state;
    return (
      <>
        <Row>
          <Col className="text-right">
            <a onClick={this.toggleModal} className="btn btn-primary" href="#">
              <PlusIcon size={16} />
              Add Activity
            </a>
          </Col>
        </Row>
        <Modal isOpen={modal} size="lg">
          <ModalHeader onClick={this.toggleModal}>
            Add Activity
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.submitActivity}>
              {this.renderErrors()}
              <Row>
                <Col md={4}>
                  <label>
                    Activity Type
                  </label>                  
                  <div className="form-group">
                    <DropdownList
                      data={activityTypes}
                      value={displayActivityType}
                      onChange={value => this.setActivityType({ value })}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <Editor
                      wrapperClassName="card p-2"
                      html={activity.message}
                      onEditorStateChange={this.handleEditorChange}
                      toolbar={editor_toolbar()}
                    />                    
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label for="single_file" className="control-label btn-primary btn">
                      <FileDocumentIcon size={21} />
                      &nbsp;
                      Upload File
                    </label>
                    <input
                      type="file"
                      name="single_file"
                      id="single_file"
                      style={{
                        width: 0.1,
                        height: 0.1,
                        opacity: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={e => this.displayFileName(e)}
                    />
                    &nbsp;
                    {fileName}
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <a href="#" onClick={this.toggleTask}>
                    {!showTask ? ("Add Task for Follow Up") : ("Remove Task for Follow Up")}
                  </a>
                </Col>
              </Row>
              {showTask && (
                <>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label>Select Task Type:</label>
                        <DropdownList
                          data={activityTaskTypes}
                          value={displayActivityTaskType}
                          onChange={value => this.setActivityTaskType({ value })}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label>How Far Out?</label>
                        <DropdownList
                          data={howFarOutList}
                          value={activity.howFarOut}
                          textField="title"
                          valueField="value"
                          onChange={value => this.setHowFarOut({ value })}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Task Description</label>
                        <textarea
                          id="taskDescription"
                          name="taskDescription"
                          className="form-control"
                          rows="5"
                          value={activity.taskDescription}
                          onChange={this.handleTaskDescription}
                        >
                        </textarea>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  {this.renderSaveButton()}
                </Col>
              </Row>
            </form>
          </ModalBody>        
        </Modal>
      </>
    );
  }
}

export default Form;
