import api from './init';
import returnError from './returnError';

export function getActivityLogs(data) {
  const url = `/api/v1/report_stores/activity_logs.json?page=${data.page}&user=${data.user}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}