import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import PlusIcon from 'mdi-react/PlusIcon';

import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

import CallLogItem from '../../components/callLog/CallLogItem';
import Loading from '../../components/Loading';

import { fullUserList } from '../../api/user';
import { listCompaniesForDropDown } from '../../api/company';
import { getTaskTemplates, addCallLogs } from '../../api/callLog';
import { saveCompany } from '../../api/company';
import { activityTypes, whenInWeeks } from '../../libs/utils';

export default function CallLog() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [callLogs, setCallLogs] = useState([]);
  const [callLogData, setCallLogData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [toggleCompanyAdd, setToggleCompanyAdd] = useState(false);
  const [company, setCompany] = useState({ name: '', address: '', city: '', state: '', zip_code: '', telephone: '', website: '', email_address: '', errors: [], user_current_user: true });
  const [verified, setVerified] = useState(false);
  const [callLogSaved, setCallLogSaved] = useState(false);
  const [errors, setErrors] = useState([]);

  const callLogsList = useMemo(() => renderCallLogs(), [callLogs.length, counter]);

  useEffect(() => {
    // get the list of users
    fullUserList()
      .then((res) => {
        setUsers(res);
      });
    // get the list of companies
    loadCompanies();
    // load task templates
    getTaskTemplates()
      .then((data2) => {
        setTaskTemplates(data2.task_templates);
      });
  }, []);

  useEffect(() => {
    if (!toggleCompanyAdd) {
      setCompany({ name: '', address: '', city: '', state: '', zip_code: '', telephone: '', website: '', email_address: '', errors: [], user_current_user: true });
    }
  }, [toggleCompanyAdd]);

  function sendData(index, data) {
    callLogData[index] = {
      company: data.company,
      when: data.callLogDate,
      activityType: data.activityType,
      description: data.details,
      file: data.selectedFile,
      delete: data.deleted,
      taskEntries: data.taskEntries,
    };
    setCallLogData(callLogData);
  }

  // Add Call Log
  const buildCallLog = () => {
    const item = {
      company: null,
      when: null,
      activityType: null,
    };
    return item;
  };

  const addCallLogItem = () => {
    setVerified(false);
    const item = buildCallLog();
    callLogs.push(item);
    item.delete = false;
    item.taskEntries =[];
    callLogData.push(item);
    setCallLogs(callLogs);
    setCallLogData(callLogData);
    setCounter(c => c + 1);
  };

  function addCompany() {
    const data = {
      company,
    };

    setLoading(true);

    saveCompany(data)
      .then((res) => {
        if (res.errors.length === 0) {
          setToggleCompanyAdd(false);
          loadCompanies();
        } else {
          company.errors = res.errors;
          setCompany({ ...company });
        }
        setLoading(false);
      });
  }

  // save the data!
  const saveData = () => {
    setSaving(true);
    console.log('saving!');
    addCallLogs({ callLog: callLogData })
      .then((res) => {
        if (res.errors.length > 0) {
          setErrors(res.errors);
        } else {
          setCallLogSaved(true);
        }
        setSaving(false);
      });
  };

  const verifyDataClick = () => {
    setCounter(counter => counter + 1);
    // verify the array + objects ('' or null => failed)
    let position = 1;
    let issues = [];
    if (callLogData.filter(x => x.delete === false).length === 0) {
      issues.push('No Entries Found');
    }
    callLogData.forEach((obj) => {
      if (obj.delete) {
        return;
      }
      let passed = true;
      if (obj.activityType === null || obj.company === null || obj.description === '' || obj.when === null) {
        passed = false;
      }
      obj.taskEntries.forEach((obj2) => {
        if (obj2.delete) { return; }
        if (obj2.details === '' || obj2.followUpType === null || obj2.assignedUsers.length === 0 || obj2.weeks === 0) {
          passed = false;
        }
      });
      if (!passed) {
        issues.push(`Entry ${position}: All fields must be completed.`);
      }
      position += 1;
    });

    if (issues.length === 0) {
      setVerified(true);
      setErrors([]);
    } else {
      setErrors(issues);
    }
  };

  function resetVerified() {
    setVerified(false);
  }

  function loadCompanies() {
    listCompaniesForDropDown()
      .then((data) => {
        setCompanies(data.companies);
        setLoading(false);
        setCounter(counter => counter + 1);
      });
  }

  function toggleCompanyAddModal() {
    setToggleCompanyAdd(!toggleCompanyAdd);
  }

  function reset() {
    setVerified(false);
    setCallLogData([]);
    setCallLogs([]);
    setCallLogSaved(false);
    setCounter(0);
    setErrors([]);
  }

  function updateCompanyFormData(event) {
    const { target } = event;
    const { name, value } = target;
    const fieldName = name.replace('company_', '');
    company[fieldName] = value;
    setCompany({ ...company });
  }

  const renderAddCompanyErrors = () => {
    if (company.errors.length === 0) { return null; }
    return (
      <Alert color="danger">
        <p>Please correct the following errors:</p>
        {company.errors.map(item => (
          <ul>
            <li>{item}</li>
          </ul>
        ))}
      </Alert>
    );
  };

  function renderCallLogs() {
    return callLogs.map((item, idx) => (
      <CallLogItem
        key={`callLogItem${idx}`}
        index={idx}
        callLog={item}
        companies={companies}
        users={users}
        activityTypes={activityTypes()}
        followUpTypes={taskTemplates}
        whenInWeeks={whenInWeeks(15)}
        addCompany={toggleCompanyAddModal}
        sendData={sendData}
        resetVerified={resetVerified}
      />
    ));
  }

  function renderErrors() {
    if (errors.length === 0) {
      return null;
    }

    return(
      <Alert color="danger mt-2">
        {errors.map(item => (
          <p>{item}</p>
        ))}
      </Alert>
    );
  }

  if (callLogSaved) {
    return (
      <ErrorBoundary message="Item could not be rendered (Has been reported)">
        <PageHeader title="Call Log" />
        <CardWrapper>
          <Row>
            <Col>
              <Alert color="success">
                <h3>Success</h3>
                <p>The Call Log entries have been saved.</p>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => reset()}
                >
                  Enter More Call Logs
                </button>
              </Alert>
            </Col>
          </Row>
        </CardWrapper>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary message="Item could not be rendered (Has been reported)">
      <Helmet>
        <title>The Hub - Call Log</title>
      </Helmet>
      <PageHeader title="Call Log" />
      <CardWrapper>
        { loading && (
          <Loading />
        )}
        { !loading && (
          <>
            <Alert color="info">
              <p>All Fields are required.</p>
              <p>Must verify data prior to saving.</p>
              <p>Then click save once data has been verified and you are complete.</p>
            </Alert>
            {callLogsList}
            <button
              className="btn"
              onClick={addCallLogItem}
              type="button"
            >
              <PlusIcon size={16} />
              Add New Call Log
            </button>
            <hr size="1" />
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => verifyDataClick()}
            >
              Verify Data
            </button>
            {renderErrors()}
            <hr size="1" />
            <div className="text-right">
              { verified ? (
                <>
                  {saving ? (
                    <em>Saving...</em>
                  ) : (
                    <>
                      <em>Verified</em>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => saveData()}
                      >
                        Save
                      </button>
                    </>
                  )}
                </>
              ) : (
                <em>Must click verify data in order to save (then click save if you have completed your call log)</em>
              )}
            </div>
          </>
        )}
      </CardWrapper>
      <Modal isOpen={toggleCompanyAdd} toggle={toggleCompanyAddModal}>
        <ModalHeader>Add Company</ModalHeader>
        <ModalBody>
          {renderAddCompanyErrors()}
          <Row>
            <Col className="form-group">
              <div className="text-right">* required</div>
              <input type="text" name="company_name" value={company.name} placeholder="Company Name...*" className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_address" value={company.address} placeholder="Address...*" className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_city" value={company.city} placeholder="City...*" className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_state" value={company.state} placeholder="State...*" className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_zip_code" value={company.zip_code} placeholder="Zip Code...*" className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_telephone" value={company.telephone} placeholder="Telephone..." className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_email_address" value={company.email_address} placeholder="Email Address..." className="form-control mb-2" onChange={updateCompanyFormData} />
              <input type="text" name="company_website" value={company.website} placeholder="Website..." className="form-control mb-2" onChange={updateCompanyFormData} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => addCompany()}
          >
            Add
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => toggleCompanyAddModal()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </ErrorBoundary>
  );
}

