import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';
import WrenchIcon from 'mdi-react/WrenchIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import TransferRightIcon from 'mdi-react/TransferRightIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import { renderAvatar, priorityColor } from '../../libs/utils';

class TaskItem extends React.Component {
  constructor(props) {
    super(props);
  }

  renderCompany() {
    const { task } = this.props;
    if (task.company !== undefined) {
      const url = `/companies/${task.company.value}`;
      return (
        <div key={`company-${task.company.id}`}>
          <small>
            <Link to={url}>
              <ArrowRightIcon size={10} />
              &nbsp;
              {task.company.name}
            </Link>
          </small>
        </div>
      );
    }
    return null;
  }

  renderUserList() {
    const { task } = this.props;
    const { user_list } = task;
    let list = Array.from(user_list);
    const shortUserList = list.splice(0, 4);
    const html = shortUserList.map(item => (
      renderAvatar(item, 50)
    ));
    let html2 = null;
    if (list.length > 0) {
      const url = `/img/+${list.length}/50/50/16`;
      html2 = <img src={url} alt="+" className="img-circle" with={50} />;
    }

    return (
      [
        html,
        html2,
      ]
    );
  }

  renderPriority = (priority) => {
    let color = '';
    switch (priority) {
      case 'urgent':
        color = 'danger';
        break;
      case 'high':
        color = 'warning';
        break;
      case 'low':
        color = 'info';
        break;
      default:
        color = '';
        break;
    }
    return (
      <span className={`label label-${color}`}>
        {priority}
      </span>
    );
  }

  renderPriorityColumn = (priority) => {
    let color = priorityColor(priority);
    return (
      <td className={`cell-no-padding bg-${color}`} title={priority}>
      </td>
    );
  }

  renderComplete() {
    let html = [];
    const { task } = this.props;
    if (task.status === 'complete') {
      html.push(<br />);
      html.push(
        <span className="label bg-info">
          Completed:
                {this.renderCompletedBy()}
          &nbsp;
                <Moment format="MM/DD/YYYY h:mm A">
            {task.complete_date}
          </Moment>
        </span>
      );
    }
    return (html);
  }

  renderCompletedBy() {
    const { task } = this.props;
    if (task.complete_by !== null) {
      return (
        <span>
          &nbsp;
          {task.complete_by}
          &nbsp;
          @
        </span>
      );
    }
    return null;
  }

  renderDueDateInfo() {
    const { task } = this.props;
    let icon = '';
    let labelColor = 'grey';
    if (moment(task.due_date).isBefore(new Date())) {
      labelColor = 'danger';
      icon = <AlertCircleIcon size={14} />
    }
    return (
      [
        <Moment format="MM/DD/YYYY h:mm A">
          {task.due_date}
        </Moment>,
        <br />,
        <small className={`label label-${labelColor}`}>
          {icon}
          &nbsp;
          <Moment fromNow>
            {task.due_date}
          </Moment>
        </small>,
        <br />,
        <small className="label label-primary">
          Created:
          &nbsp;
          <Moment format="MM/DD/YYYY h:mm A">
            {task.created_at}
          </Moment>
        </small>,
      ]
    );
  }

  render() {
    const { task } = this.props;

    return (
      <tr data-id={task.id} key={`task-item-${task.id}`}>
        {this.renderPriorityColumn(task.priority)}
        <td className="text-center">
          <Link
            to={{
              pathname: `/tasks/${task.id}`,
            }}
          >
            <WrenchIcon size={24} />
          </Link>
        </td>
        
        <td>
          {renderAvatar(task.user, 50)}
        </td>
        <td>
          <TransferRightIcon size={24} />
        </td>
        <td className="userListIcons">
          {this.renderUserList()}
        </td>
        <td width="30%">
          <Link
            to={{
              pathname: `/tasks/${task.id}`,
            }}
          >
            {task.name}
          </Link>
          {this.renderCompany()}
          {task.comments > 0 && (
            [
              <br />,
              <label className="label bg-primary">
                Comments:
                &nbsp;
                {task.comments}
              </label>,
            ]
          )}
        </td>
        <td>
          {this.renderDueDateInfo()}
          {this.renderComplete()}
        </td>
        <td>
          {task.can_delete && (
            <a href='#' onClick={() => this.props.onDelete(task.id)}>
              <DeleteIcon size={24} />
            </a>
          )}
        </td>
      </tr>
    );
  }
}

export default TaskItem;
