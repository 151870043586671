import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import UserRoutes from './users';
// import Sales from './sales';
// import Import from './import';
import Company from './companies';
import CompanySource from './company_sources';
// import CompanyMerge from './companyMerge';
import TaskTemplates from './taskTemplates';
import Segments from './segments';
import User from './users/user';

const Admin = () => (
  <>
    <Helmet>
      <title>The Hub - Admin</title>
    </Helmet>
    <Routes>
      <Route path="users/*" element={<UserRoutes />} />
      {/* <Route path="/admin/sales" element={<Sales />} /> */}
      {/* <Route path="import" element={<Import />} /> */}
      <Route path="companies" element={<Company />} />
      <Route path="company_sources" element={<CompanySource />} />
      {/* <Route path="company_merge" element={<CompanyMerge />} /> */}
      <Route path="task_templates" element={<TaskTemplates />} />
      <Route path="segments" element={<Segments />} />
      <Route path="" element={<Dashboard />} />
    </Routes>
  </>
);

export default Admin;
