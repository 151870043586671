import api from './init';
import returnError from './returnError';

// list of segment data
export const loadSegments = (year) => {
  const url = `/api/v1/segments.json?year=${year}`;
  console.log(url);
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

// save segment data
export const saveSegment = (data) => {
  const url = `/api/v1/segments/${data.id}.json`;
  return api.put(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export const segmentList = () => {
  const url = `/api/v1/segments/list.json`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data.segments;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    })
}