import React, { useEffect, useState } from 'react';
import { Col, Row, FormGroup } from 'reactstrap';

export default function SegmentRow({data, save, saving, isUpdated}) {
  const [goal, setGoal] = useState(data.goal);

  const updateGoal = (e) => {
    setGoal(e.target.value);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={2}>
          {data.area}
        </Col>
        <Col xs={12} md={3}>{data.name}</Col>
        <Col xs={12} md={2}>{data.year}</Col>
        <Col xs={12} md={3}>
          <input
            type="number"
            name={`goal_${data.id}`}
            value={goal}
            step={0.01}
            onChange={e => updateGoal(e)}
          />
        </Col>
        <Col xs={12} md={2}>
          { saving ? 'Saving...' : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={e => save(data.id, goal)}
            >
              Update
            </button>
          )}
          {isUpdated && <span id={`updated_${data.id}`} className="text-info badge">Updated</span>}
        </Col>
      </Row>
      <hr />
    </>
  );
}
