import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import AccountIcon from 'mdi-react/AccountIcon';
import CashIcon from 'mdi-react/CashIcon';
import GearIcon from 'mdi-react/GearIcon';
import BuildingIcon from 'mdi-react/BuildingIcon';
import MergeIcon from 'mdi-react/MergeIcon';
import FormatListChecksIcon from 'mdi-react/FormatListChecksIcon';
import FlagCheckeredIcon from 'mdi-react/FlagCheckeredIcon';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import PageSubHeader from '../../components/PageSubHeader';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "Administration section (Admin Only)"
    };
  }

  render() {
    const { description } = this.state;
    return(
      <>
        <Helmet>
          <title>The Hub - Admin</title>
        </Helmet>
        <PageHeader title="Administration" />
        <PageSubHeader title="Administration - Dashboard" description={description} />
        <Row>
          <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/users">
                  <AccountIcon size={48} />
                  <br />
                  Users
                </Link>
              </div>
            </CardWrapper>
          </Col>
          {/* <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/sales">
                  <CashIcon size={48} />
                  <br />
                  Sales Updates
                </Link>
              </div>
            </CardWrapper>
          </Col> */}
          {/* <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/import">
                  <GearIcon size={48} />
                  <br />
                  Import
                </Link>
              </div>
            </CardWrapper>
          </Col> */}
        
          <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/companies">
                  <BuildingIcon size={48} />
                  <br />
                  Companies
                </Link>
              </div>
            </CardWrapper>
          </Col>
          <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/company_sources">
                  <BuildingIcon size={48} />
                  <br />
                  Company Sources
                </Link>
              </div>
            </CardWrapper>
          </Col>
          {/* <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/company_merge">
                  <MergeIcon size={48} />
                  <br />
                  Company Merge
                </Link>
              </div>
            </CardWrapper>
          </Col> */}
        </Row>
        <Row>
          <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/task_templates">
                  <FormatListChecksIcon size={48} />
                  <br />
                  Task Templates
                </Link>
              </div>
            </CardWrapper>
          </Col>
          <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/admin/segments">
                  <FlagCheckeredIcon size={48} />
                  <br />
                  Goals (Segments)
                </Link>
              </div>
            </CardWrapper>
          </Col>
        </Row>
      </>
    );
  }
}

export default Dashboard;
