import api from './init';
import returnError from './returnError';


export function getNotes(data) {
  const url = `/api/v1/notes.json?page=${data.currentPage}&object_id=${data.objectId}&object_name=${data.objectName}&search=${data.search}`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveNote(data) {
  const url = '/api/v1/notes';
  return api
    .post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}
