import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null,
    };

    this.deleteComment = this.deleteComment.bind(this);
  }

  componentDidMount() {
    const { comment } = this.props;
    this.setState({ comment });
  }

  deleteComment(event) {
    event.preventDefault();
    console.log('delete clicked...');
  }

  render() {
    const { comment } = this.props;
    return (
      <li>
        <div className="chat-img">
          <img src={comment.user.avatar.url} alt="user" />
        </div>
        <div className="chat-content">
          <h5>
            {comment.user.name}
          </h5>
          <div className="box bg-light-inverse">
            {comment.message}
          </div>
        </div>
        <div className="chat-time">
          <Moment format="MM/DD/YYYY h:mm A">
            {comment.created_at}
          </Moment>
        </div>
        {/* <button type="button" className="btn btn-danger btn-xs" data-id={comment.id} onClick={this.deleteComment}>
          Delete
        </button> */}
      </li>
    );
  }
}

Comment.propTypes = {
  comment: PropTypes.shape({
    user: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    created_at: PropTypes.object
  }),
};

export default Comment;
