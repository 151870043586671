import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import AccountIcon from 'mdi-react/AccountIcon';

import PageHeader from '../../components/PageHeader';
import PageSubHeader from '../../components/PageSubHeader';
import CardWrapper from '../../components/CardWrapper';
import Error from '../../components/Error';

import api from '../../api/init';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Update Profile',
      user: {
        email: '',
        name: '',
        telephone_number: '',
        cell_phone: '',
        cell_phone_carrier: '',
        password: '',
        password_confirm: '',
        avatar: '',
      },
      status: '',
      errors: [],
      imageUpdated: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    api
      .get(`/api/v1/users/${localStorage.getItem('uid')}`, { withCredentials: true })
      .then(response => this.setState({ user: response.data }))
      .catch ((error) => {
        console.log(error);
      });
  }

  updateUser(key, value) {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [key]: value,
      },
    }));
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.updateUser(name, value);
  }

  buildFormData() {
    const { user, imageUpdated } = this.state;
    let formData = new FormData();
    formData.append('user[name]', user.name);
    formData.append('user[email]', user.email);
    formData.append('user[telephone_number]', user.telephone_number);
    formData.append('user[cell_phone]', user.cell_phone);
    formData.append('user[cell_phone_carrier]', user.cell_phone_carrier);

    if (typeof user.password !== 'undefined') {
      if (user.password !== '' && (user.password === user.password_confirm)) {
        console.log('adding passwords...');
        formData.append('user[password]', user.password);
        formData.append('user[password_confirmation]', user.password_confirm);
      }
    }

    if (imageUpdated) {
      const file = document.getElementById('avatar').files[0];
      formData.append('user[avatar]', file, file.name);
    }

    console.log(formData);

    return formData;
  }

  handleSubmit(e) {
    e.preventDefault();
    const obj = this;
    console.log('starting submit...');
    let submitMethod = 'patch';
    let url = `/api/v1/users/${localStorage.getItem('uid')}`;

    api
      [submitMethod](url, this.buildFormData())
      .then((response) => {
        console.log(response);
        if (response.data.errors != undefined && response.data.errors.length > 0) {
          obj.setState({ errors: response.data.errors });
        } else {
          obj.setState({ status: 'Profile Updated' });
          setTimeout(function(){
            obj.setState({ status: '' });
          }, 1500);
        }
      });
  }

  displayImage() {
    this.setState({ imageUpdated: true });
    const file = document.getElementById('avatar').files[0];
    this.updateUser('avatar', URL.createObjectURL(file));
  }

  renderImage() {
    const { user } = this.state;
    if (user.avatar === '') {
      return null;
    }
    return (
      <div>
        <img src={user.avatar} alt="Avatar" className="img-circle" width={200} height={200} />
      </div>
    );
  }

  render() {
    const { user, errors, pageTitle } = this.state;
    return (

      <div>
        <Helmet>
          <title>The Hub - Profile</title>
        </Helmet>
        <PageHeader title={pageTitle} />
        <PageSubHeader title="Instructions" description="Any changes you make to your profile, you must click save at the bottom." />
        <CardWrapper>
          <Error errors={errors} />
          <form className="taskForm form-horizontal">
            <Row>
              <Col xs={12} sm={3}>
                <div className="form-group">
                  <label htmlFor="avatar" className="control-label btn-primary btn">
                    <AccountIcon size={21} />
                    &nbsp;
                    Upload Avatar
                  </label>
                  <p>
                     Image Size: 150 x 150
                  </p>
                  <input
                    type="file"
                    name="avatar"
                    id="avatar"
                    style={{
                      width: 0.1,
                      height: 0.1,
                      opacity: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      zIndex: -1,
                    }}
                    onChange={e => this.displayImage(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={9}>
                {this.renderImage()}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor="name" className="control-label">Name</label>
                  <input type="text" className="form-control" name="name" id="name" onChange={this.handleInputChange} value={user.name} required />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor="email" className="control-label">Email Address (Login)</label>
                  <input type="email" className="form-control" name="email" onChange={this.handleInputChange} value={user.email} required />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="telephone_number" className="control-label">Telephone</label>
                  <input type="tel" className="form-control" name="telephone_number" value={user.telephone_number} onChange={this.handleInputChange} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="cell_phone" className="control-label">Cell Phone</label>
                  <input type="tel" className="form-control" name="cell_phone" value={user.cell_phone} onChange={this.handleInputChange} />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="cell_phone_carrier" className="control-label">Cell Phone Carrier</label>
                  <select name="cell_phone_carrier" value={user.cell_phone_carrier} className="form-control" name="cell_phone_carrier" onChange={this.handleInputChange}>
                    <option>AT&T</option>
                    <option>Verizon</option>
                    <option>Sprint</option>
                    <option>T-Mobile</option>
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3>Password</h3>
                <p>Leave password blank if you do not want to update your password.</p>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <div className="form-group">
                  <label htmlFor="password" className="control-label">Password</label>
                  <input type="password" placeholder="********" className="form-control" name="password" onChange={this.handleInputChange} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="form-group">
                  <label htmlFor="password_confirm" className="control-label">Password Confirmation</label>
                  <input type="password" placeholder="********" className="form-control" name="password_confirm" onChange={this.handleInputChange} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="text-center">
                <input type="submit" value="save" className="btn btn-primary" onClick={e => this.handleSubmit(e)} />
                &nbsp;
                <Link to="/tasks" className="btn btn-secondary">
                  Cancel
                </Link>
              </Col>
            </Row>

            {this.state.status !== '' &&
              <Row>
                <Col className="text-center">
                  <br />
                  <div className="alert alert-success">
                    {this.state.status}
                  </div>
                </Col>
              </Row>
            }
          </form>
        </CardWrapper>
      </div>
    );
  }
}

export default Profile;
