import api from './init';

function returnError() {
  return {
    errors: ['Data Error - Contact Administrator'],
  };
}

// ******************************************************

// get list of users
// filters can be included
export function listUsers(data) {
  const url = `/api/v1/users?r=1&page=${data.currentPage}&role=${data.role}&list=${data.list}`;
  console.log(url);
  // , { withCredentials: true }
  return api.get(url)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function fullUserListByRole(role) {
  const url = `/api/v1/users?r=1&fullList=1&role=${role}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data.users;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function fullUserList() {
  const url = '/api/v1/users?r=1&fullList=1';
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data.users;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// get a user detail
export function getUser(id) {
  const url = `/api/v1/users/${id}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// save the user
export function saveUser({id, data}) {
  let submitMethod = 'post';
  let url = '/api/v1/users/';

  if (id !== undefined && id !== null && id !== 'new') {
    submitMethod = 'patch';
    url = `/api/v1/users/${id}`;
  }

  return api[submitMethod](url, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// delete a user
export function deleteUser(data) {
  return api.delete(`/api/v1/users/${data.id}`, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

// list the roles
export function listUserRoles() {
  return api
    .get('/api/v1/users/roles.json', { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}
