import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Alert } from 'reactstrap';


// components
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

import SalesGoal from '../../components/expandedDashboard/sales_goal';
import Upcoming from '../../components/expandedDashboard/upcoming';

import QuoteRow from '../../components/quote/QuoteRow';

import TaskList from '../tasks/TaskList';

// utils
import { inValidRole } from '../../libs/utils';

// api
import { fullUserListByRole } from '../../api/user';
import { getQuoteStats, getQuotes } from '../../api/quote';
import { getTaskList } from '../../api/task';

export default function CSRDashboard() {
  const [goalLoading, setGoalLoading] = useState(true);
  const [upcomingLoading, setUpcomingLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  const [quoteStats, setQuoteStats] = useState(null);
  const [quotes, setQuotes] = useState([]);

  const csrUserId = localStorage.getItem('uid');

  const quoteFilters = {
    status: ['open', 'draft'],
    company: { id: null, name: null },
    user: { id: null, name: null },
    csr: { id: null, name: null },
  }

  useEffect(() => {
    fullUserListByRole('sales_rep')
      .then((data) => {
        setUserId(data[0].id)
      })
    getQuoteStats()
      .then((data) => {
        console.log('data', data)
        setQuoteStats(data)
      })
    getQuotes({page: 1, user: { id: null }, filters: quoteFilters})
      .then(data => {
        setQuotes(data.quotes)
      })
  }, [])


  useEffect(() => {
    if (!goalLoading && !upcomingLoading) {
      setLoading(false);
    }
  }, [goalLoading, upcomingLoading]);


  const updateLoadingStatus = (child, status) => {
    console.log(`${child} => ${status}`);
    switch (child) {
      case 'goal':
        setGoalLoading(status);
        break;
      case 'upcoming':
        setUpcomingLoading(status);
        break;
      default:
        break;
    }
  };

  // renders
  const renderQuotes = () => {
    return (
      <CardWrapper>
        <h4 className="mb-4">Quotes</h4>
        {quoteStats !== null && (
          <Row>
            {quoteStats.map((stat, index) => (
              <Col xs={6} md={2} key={index}>
                <CardWrapper header={stat.name}>
                  <p>{stat.total}</p>
                </CardWrapper>
              </Col>
            ))}
          </Row>
        )}
        <h5>Open Quotes</h5>
        <hr />
        {quotes.length === 0 && (
          <Alert color="info">
            <p>No Quotes Found</p>
          </Alert>
        )}
        {quotes.map((quote) =>(
          <QuoteRow
            key={quote.id}
            quote={quote}
          />
        ))}
      </CardWrapper>
    )
  }

  return (
    <ErrorBoundary message="Item could not be rendered (Has been reported)">
      <Helmet>
        <title>The Hub - Dashboard</title>
      </Helmet>
      <PageHeader title="Dashboard" />
      <SalesGoal
        userID={userId}
        segment={'All'}
        updateStatus={updateLoadingStatus}
      />
      {renderQuotes()}

      <TaskList displayHeader={false} displayFilters={false} />

      <Upcoming userId={csrUserId} updateStatus={updateLoadingStatus} />
    </ErrorBoundary>
  )
}