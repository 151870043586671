import React, { useEffect, useState } from 'react';
import { DropdownList } from 'react-widgets';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

import Loading from '../Loading';

import { getChartData } from '../../api/report';

const years = () => {
  let list = []
  const lastYear = 2019
  const thisYear = new Date().getFullYear()
  for (let i = thisYear; i >= lastYear; i--) {
    list.push(i)
  }
  return list
} //[2022, 2021, 2020, 2019]

export default function SalesHistoryProductClass({ companyId }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // if (companyId === null) {
  //   return null;
  // }
  
  useEffect(() => {
    setLoading(true);
    getChartData('pie', [year], companyId, null)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [companyId, year]);
  
  const COLORS = ['#fc4b6c', '#26c6da', '#ffb22b', '#7460ee', '#1e88e5'];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div className="custom-tooltip" style={{backgroundColor: '#fff', color: '#000', padding: '10px'}}>
          <p>{payload[0].name}</p>
          <p>{payload[0].payload.valueDisplay}</p>
        </div>
      );
    }
  };

  return (
    <>
      <h4>Sales Breakdown by Item Class</h4>
      <PieChart width={500} height={500}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={30}
          outerRadius={200}
          fill="#7460ee"
        >
          {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
        </Pie>
        <Tooltip content={CustomTooltip} />
      </PieChart>
      {loading && (
        <Loading />
      )}
      <DropdownList
        data={years()}
        value={year}
        onChange={value => setYear(value)}
      />
    </>
  );
}
