import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Badge,
} from 'reactstrap';
import EditIcon from 'mdi-react/EditIcon';
import BarChartIcon from 'mdi-react/BarChartIcon';
import EyeIcon from 'mdi-react/EyeIcon';

const MapRow = ({ map }) => {
  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <Badge>
            {map.company.name}
          </Badge>
          <br />
          {map.name}
        </Col>
        <Col xs={12} md={2}>
          {map.numberOfUrls}
        </Col>
        <Col xs={12} md={2}>
          {map.requiresReview ? 'Yes' : 'No'}
          <Badge color="secondary" className="ms-2">
            {map.numberOfItemsNotReviewed}
          </Badge>
        </Col>
        <Col xs={12} md={3}>
          {map.updatedAt}
        </Col>
        <Col xs={12} md={2} className="text-end">
          
          <Link
            to={`/map/${map.id}/report`}
            className="btn btn-secondary me-4"
          >
            <BarChartIcon size={16} />
            {' '}
            Report
          </Link>
          <Link
            to={`/map/${map.id}`}
            className="btn btn-secondary"
          >
            <EditIcon size={16} />
            {' '}
            Edit
          </Link>
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default MapRow
