// list of quotes
import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Badge,
  Button
} from 'reactstrap';
import { DropdownList } from 'react-widgets';

// icons
import PlusIcon from 'mdi-react/PlusIcon';

// components
import PageHeader from '../../components/PageHeader';
// import PageSubHeader from '../../components/PageSubHeader';
import CardWrapper from '../../components/CardWrapper';
// import Error from '../../components/Error';
import AuthorizationNotice from '../../components/authorizationNotice'

// quote - components
import QuoteRow from '../../components/quote/QuoteRow';

// api
import { getQuotes } from '../../api/quote';
import { fullUserListByRole, fullUserList } from '../../api/user';
import { listCompaniesForDropDown } from '../../api/company';

// list
import { inValidRole, removeDuplicates } from '../../libs/utils';
import { quoteStatusList, quoteOrderByList } from '../../libs/list'

// filters
// import { getQuoteFilters, } from '../../libs/filters';

// css
import styles from './List.module.scss';

// let quoteFilters = getQuoteFilters()

const defaultQuoteFilters = {
  paging: { page: 1, totalRecords: 0 },
  filters: {
    status: ['open', 'draft'],
    company: { id: null, name: null },
    user: { id: null, name: null },
    csr: { id: null, name: null },
    qn: "",
  }
}

const useQuoteFilterStorage = (storageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultQuoteFilters
  )

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

const List = () => {

  const [quotes, setQuotes] = useState([])
  const [users, setUsers] = useState([])
  const [csrUsers, setCsrUsers] = useState([])

  // filters
  // const [status, setStatus] = useState({ name: 'Draft', value: 'draft'})
  const [companies, setCompanies] = useState([])
  const [user, setUser] = useState({id: null, name: null})
  const [csrUser, setCsrUser] = useState({id: null, name: null})
  const [company, setCompany] = useState({id: null, name: null})
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [tempQN, setTempQN] = useState('')
  const [tempStatus, setTempStatus] = useState(['open', 'draft'])
  const [totalRecords, setTotalRecords] = useState(0)
  // loading
  const [loading, setLoading] = useState(true)

  const [quoteFilters, setQuoteFilters] = useQuoteFilterStorage('quote:filters')

  // loading
  useEffect(() => {
    getQuotes({ page: quoteFilters.paging.page, filters: quoteFilters.filters })
      .then(data => {
        setQuotes(data.quotes)
        setTotalRecords(data.paging.total_pages)
        setLoading(false)
        setTempStatus(quoteFilters.filters.status)
        setUser(quoteFilters.filters.user)
        setCsrUser(quoteFilters.filters.csr)
        setCompany(quoteFilters.filters.company)
        setTempQN(quoteFilters.filters.qn)
      })
  }, [quoteFilters])

  useEffect(() => {
    Promise.all([fullUserListByRole('sales_rep'), listCompaniesForDropDown(), fullUserList()])
      .then(data => {
        setUsers(data[0])
        setCompanies(data[1].companies)
        setCsrUsers(data[2])
        setLoading(false)
      })
  }, [])

  // actions
  const updateFilters = (e) => {
    e.preventDefault()
    const statuses = []
    const list = document.getElementsByClassName('statusCheckboxes')

    for(let i = 0; i < list.length; i++) {
      if (list[i].checked) { statuses.push(list[i].value) }
    }

    setQuoteFilters({
      ...quoteFilters, 
        paging: { page: 1, totalRecords: 0 },
        filters: {
          status: statuses,
          company: company,
          user: user,
          csr: csrUser,
          qn: document.getElementById('searchQuoteNumber').value,
        }
    })
    setIsFilterOpen(false)
  }

  const updateTempStatus = (e, value) => {
    const list = document.getElementsByClassName('statusCheckboxes')
    let statuses = []

    for (let i = 0; i < list.length; i++) {
      if (list[i].checked) { statuses.push(list[i].value) }
    }
    setTempStatus(statuses)
  }

  const handlePageChange = (e) => {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    const paging = { ...quoteFilters.paging, page: e }
    setQuoteFilters({...quoteFilters, paging: paging})
  }

  const reset = () => {
    setUser({ id: null, name: null })
    setCsrUser({ id: null, name: null })
    setCompany({ id: null, name: null })
    setTempStatus(['open', 'draft'])
    setQuoteFilters(defaultQuoteFilters)
  }

  // render paging
  const renderPaging = () => {
    return (
      <Pagination
        activePage={quoteFilters.paging.page}
        itemsCountPerPage={10}
        totalItemsCount={totalRecords}
        pageRangeDisplayed={5}
        onChange={e => handlePageChange(e)}
        innerClass={'pagination'}
        itemClass={'paginate_button page-item'}
        linkClass={'page-link'}
      />
    );
  }

  // toggle filter
  const toggleFilter = () => {
    if (!isFilterOpen) {
      setTempQN(quoteFilters.filters.qn)
    }

    setIsFilterOpen(!isFilterOpen)
  }

  // renders
  const filters = () => {
    return (
      <>
        <CardWrapper>
          <Row>
            <Col>
              <div className={styles.filterContainer}>
                <div className={styles.filterBlock}>
                  <label>
                    Quote #:
                  </label>
                  <button
                    onClick={toggleFilter}
                    className={styles.filterButton}
                  >
                    {quoteFilters.filters.qn.length === 0 ? (
                      <>
                        -- Select --
                      </>
                    ) : (
                      <>
                        {quoteFilters.filters.qn}
                      </>
                    )}
                  </button>
                </div>
                <div className={styles.filterBlock}>
                  <label>
                    Status:
                  </label>
                  
                  <button
                    onClick={toggleFilter}
                    className={styles.filterButton}
                  >
                    {quoteFilters.filters.status.length === 0 ? (
                      <>
                        -- Select --
                      </>
                    ) : (
                      <>
                        {quoteFilters.filters.status.map(item => (
                          <Badge className={styles.badge} key={item}>
                            {item}
                          </Badge>
                        ))}
                      </>
                    )}
                  </button>
                </div>

                <div className={styles.filterBlock}>
                  <label>
                    Company:
                  </label>

                  <button
                    onClick={toggleFilter}
                    className={styles.filterButton}
                  >
                    {quoteFilters.filters.company.id !== null ? (
                      <Badge>
                        {quoteFilters.filters.company.name}
                      </Badge>
                    ) : (
                      <>-- Select --</>
                    )}
                    
                  </button>
                </div>

                <div className={styles.filterBlock}>
                  <label>
                    Account Executive:
                  </label>

                  <button
                    onClick={toggleFilter}
                    className={styles.filterButton}
                  >
                    {quoteFilters.filters.user.id !== null ? (
                      <Badge>
                        {quoteFilters.filters.user.name}
                      </Badge>
                    ) : (
                      <>-- Select --</>
                    )}
                    
                  </button>
                </div>
                
                <div className={styles.filterBlock}>
                  <label>
                    Creator of Quote
                  </label>

                  <button
                    onClick={toggleFilter}
                    className={styles.filterButton}
                  >
                    {quoteFilters.filters.csr.id !== null ? (
                      <Badge>
                        {quoteFilters.filters.csr.name}
                    </Badge>
                    ) : (
                      <>
                        -- Select --
                      </>
                    )}
                  </button>
                </div>
                <div className={styles.filterBlock}>
                  <Button
                    color="primary"
                    onClick={e => reset()}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="text-end" xs={12} md={2}>
              <Link
                to="/quote/new"
                className="btn btn-primary"
              >
                <PlusIcon size={16} />
                {' '}
                Create Quote
              </Link>
            </Col>
          </Row>
        </CardWrapper>
        <Offcanvas
          toggle={toggleFilter}
          isOpen={isFilterOpen}
          direction="end"
          scrollable={true}
        >
          <OffcanvasHeader
            closeButton
            toggle={toggleFilter}
          >
            Filters
          </OffcanvasHeader>
          <OffcanvasBody>
            <div className={styles.filterItemBlock}>
              <h3>Quote #</h3>
              <input
                type="text"
                className="form-control"
                placeholder="Quote #"
                value={tempQN}
                id="searchQuoteNumber"
                onChange={(e) => setTempQN(e.target.value)}
              />
            </div>
            <div className={styles.filterItemBlock}>
              <h3>Status</h3>
              <p>All unchecked will return all.</p>
              {quoteStatusList().map((status, idx) => (
                <div key={idx} className={styles.filterItem}>
                  <label class="from-group">
                    <input
                      type="checkbox"
                      value={status.value}
                      className="statusCheckboxes"
                      checked={tempStatus.indexOf(status.value) > -1}
                      onClick={() => updateTempStatus(status.value)}
                    />
                    {' '}
                    {status.name}
                  </label>
                </div>
              ))}
            </div>

            <div className={styles.filterItemBlock}>
              <h3>Company</h3>
              <DropdownList
                data={companies}
                valueField="id"
                textField="name"
                placeholder='Select Company'
                value={company}
                disabled={loading}
                onChange={(value) => setCompany(value)}
              />
              <div className="text-end mt-2">
                <button
                  className="btn btn-primary btn-xs"
                  onClick={() => setCompany({ id: null, name: null })}
                >
                  Reset
                </button>
              </div>
            </div>

            {inValidRole(['admin', 'sales_manager', 'csr_manager', 'sales_admin', 'csr'], localStorage) && (
              <div className={styles.filterItemBlock}>
                <h3>Account Executive</h3>
                <DropdownList
                  placeholder='Account Executive'
                  data={users}
                  valueField="id"
                  textField="name"
                  disabled={loading}
                  value={user.name}
                  onChange={(value) => setUser(value)}
                  />
                <div className="text-end mt-2">
                  <button
                    className="btn btn-primary btn-xs"
                    onClick={() => setUser({ id: null, name: null })}
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}

            {inValidRole(['admin', 'sales_manager', 'csr_manager', 'sales_admin', 'csr'], localStorage) && (
              <div className={styles.filterItemBlock}>
                <h3>Who Created the Quote?</h3>
                <DropdownList
                  placeholder='Creator of Quote'
                  data={csrUsers}
                  valueField="id"
                  textField="name"
                  disabled={loading}
                  value={csrUser.name}
                  onChange={(value) => setCsrUser(value)}
                />
                <div className="text-end mt-2">
                  <button
                    className="btn btn-primary btn-xs"
                    onClick={() => setCsrUser({ id: null, name: null })}
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}

            <div className={styles.filterItemBlock}>
              <button
                className="btn btn-primary"
                onClick={(e) => updateFilters(e)}
              >
                Apply Filters
              </button>
              <br/>
              <br/>
              <small>Must click APPLY FILTERS to update list</small>
            </div>

          </OffcanvasBody>
        </Offcanvas>
      </>
    )
  }

  const renderNoQuotesFound = () => {
    if (quotes.length > 0) return null

    return (
      <Row>
        <Col>
          <Alert color="info" className='text-center'>
            No quotes found.
          </Alert>
        </Col>
      </Row>
    )
  }

  const renderQuotes = () => {
    return quotes.map(quote => (
      <QuoteRow
        key={quote.id}
        quote={quote}
      />
    ))
  }
  
  const renderNumberOfQuotes = () => {
    if (quotes.length === 0) return null

    return (
      <Row>
        <Col className="text-end">
          <Badge>
            Total Quotes: {totalRecords}
          </Badge>
          <hr />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <PageHeader title="Quotes" />
      <AuthorizationNotice />
      <div className="text-end">
        <Link
          to="/quote/report"
          className="btn btn-primary btn-xs mb-2"
        >
          Report
        </Link>
      </div>
      {filters()}
      <CardWrapper>
        {renderNoQuotesFound()}
        {renderNumberOfQuotes()}
        {renderQuotes()}
        {renderPaging()}
      </CardWrapper>
    </>
  )
}

export default List;
