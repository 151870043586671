// item (product)
import api from './init';
import returnError from './returnError';

function getItems() {
  const url = `/api/v1/items.json`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return returnError(error);
    });
}

function getItem(id) {
  const url = `/api/v1/items/${id}.json`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return returnError(error);
    });
}

function itemPricingForCompany({ companyId, itemId }) {
  const url = `/api/v1/items/${itemId}/pricing/${companyId}.json`;
  return api
    .post(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return returnError(error);
    });
}

export { getItems, getItem, itemPricingForCompany }