import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import EmailIcon from 'mdi-react/EmailIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';

class ContactItem extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  fullName() {
    const { contact } = this.props;
    return `${contact.first_name} ${contact.last_name}`;
  }

  displayTelephone() {
    let html = [];
    const { contact } = this.props;
    if (contact.telephone !== '') {
      html.push(
        <address>
          <abbr title="Phone">
            P:
          </abbr>
          &nbsp;
          {contact.telephone}
          {contact.telephone_ext !== '' && (
            <>
              {' '}
              x
              {contact.telephone_ext}
            </>
          )}
        </address>
      );
    }
    if (contact.cell_phone !== '') {
      html.push(
        <address>
          <abbr title="Phone">
            C:
          </abbr>
          &nbsp;
          {contact.cell_phone}
        </address>
      );
    }
    return (html);
  }

  displayInfo() {
    let html = [];
    const { contact } = this.props;
    if (contact.email !== '') {
      html.push(
        <a href={`mailto:${contact.email}`}>
          <EmailIcon size={24} />
          &nbsp;
          {contact.email}
        </a>
      );
    }

    return (
      html
    );
  }

  displayAddress() {
    const { address, address_two, city, state, zip_code} = this.props.contact;
    if (address === '') { return null; }

    return (
      <div>
        {address}
        {' '}
        {address_two}
        <br />
        {city}
        {' '}
        {state}
        {' '}
        {zip_code}
      </div>
    )
  }

  deleteContact = (e) => {
    e.preventDefault();
    const { id } = this.props.contact;
    this.props.deleteContact(id);
  }

  render() {
    const { contact, companyId } = this.props;

    return (
      <Col xs={12}>
        <div className="card card-body">
          <Row>
            <Col md={1} className="text-center">
              <Link
                to={{
                  pathname: `/companies/${companyId}/contacts/${contact.id}`,
                }}
              >
                <img src={contact.avatar} alt={this.fullName()} className="img-circle img-responsive" />
              </Link>
            </Col>
            <Col md={4}>
              <Link
                to={{
                  pathname: `/companies/${companyId}/contacts/${contact.id}`,
                }}
              >
                <h3 className="box-title m-b-0">
                  <EditIcon size={16} />
                  {this.fullName()}
                </h3>
              </Link>
              <small>
                {contact.title}
              </small>
            </Col>
            <Col md={3}>
              {this.displayTelephone()}
              {this.displayAddress()}
            </Col>
            <Col md={3}>
              {this.displayInfo()}
            </Col>
          
            <Col m={1} className="text-right">
              <button className="btn btn-primary btn-xs" onClick={(e) => this.deleteContact(e)}>
                <DeleteIcon size={24} />
              </button>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

export default ContactItem;
