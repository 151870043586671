import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import WrenchIcon from 'mdi-react/WrenchIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import { renderAvatar, inValidRole } from '../../libs/utils';

export default class EventItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canEdit: false,
    };
  }

  componentDidMount() {
  }

  renderLink = event => {
    if (event.can_edit) {
      return (
        <Link
          to={{
            pathname: `/events/${event.id}`,
          }}
        >
          <WrenchIcon size={24} />
          {' '}
          {event.name}
        </Link>
      )
    }
    return event.name;
  }

  renderDelete = () => {
    const { event } = this.props;

    let canDelete = inValidRole(['admin'], localStorage) || event.can_edit
    if (canDelete) {
      return (
        <a href="#" onClick={(e) => this.props.deleteEvent(e, event.id)}>
          <DeleteIcon size={24} />
        </a>
      );
    }
    return null;
  }

  render() {
    const { event } = this.props;
    return (
      [
        <Row className="p-2">
          <Col md={2}>
            <label className="label bg-primary">
              {event.event_type}
            </label>
            {event.private_event && (
              [
                <br />,
                <label className="label bg-secondary">
                  Private
                </label>,
              ]
            )}
          </Col>
          <Col md={4}>
            {this.renderLink(event)}
            <br />
            <Moment format="MM/DD/YYYY h:mm A">
              {event.start_date}
            </Moment>
            &nbsp;
            -
            &nbsp;
            <Moment format="MM/DD/YYYY h:mm A">
              {event.end_date}
            </Moment>
          </Col>
          <Col md={5}>
            {event.user_list.map(user => renderAvatar(user, 50))}
          </Col>
          <Col md={1} className="text-center">
            {this.renderDelete()}
          </Col>
        </Row>,
        <hr />,
      ]
    );
  }
}
