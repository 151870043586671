import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col } from 'reactstrap';
import CompanyIcon from 'mdi-react/CompanyIcon';
import classnames from 'classnames';
import * as Sentry from '@sentry/browser';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import ContactItem from './ContactItem';
import Error from '../../components/Error';
import CompanySourceDropdown from '../../components/companySource';

import { useNavigate } from 'react-router-dom';

import api from '../../api/init';
import { listUsers } from '../../api/user';
import { saveCompany } from '../../api/company';
import { inValidRole, currentId } from '../../libs/utils';
import { stars } from '../../components/helpers';

import ErrorBoundary from '../../components/ErrorBoundary';

const roles = ['sales_manager', 'admin', 'sales_admin'];

const CompanyNewForm = (props) => {
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    name: '',
    email: '',
    website: '',
    telephone: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    logo: '',
    salesRepId: '',
    as400_id: '',
    contacts: [],
    stars: -1,
    company_source: { id: '', name: '' },
  })
  const [users, setUsers] = useState([]);
  const [pageTitle, setPageTitle] = useState('New Company');
  const [errors, setErrors] = useState([]);
  const [imageUpdated, setImageUpdated] = useState(false);

  // this.handleInputChange = this.handleInputChange.bind(this);
  // this.handleSubmit = this.handleSubmit.bind(this);

  useEffect(() => {
    listUsers({ role: 'sales_rep', currentPage: '', list: '' })
      .then((res) => {
        setUsers(res.users);
      })
    
    if (!inValidRole(roles, localStorage)) {
      updateCompany('salesRepId', localStorage.getItem('uid'));
    }
  }, [])

  const updateCompany = (key, value) => {
    setCompany({...company, [key]: value})
  }

  const setStars = (value) => {
    console.log(value);
    updateCompany('stars', value.value);
  }

  const handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    updateCompany(name, value);
  }

  const displayImage = () => {
    setImageUpdated(true);
    const file = document.getElementById('logo').files[0];
    updateCompany('logo', URL.createObjectURL(file));
  }

  const renderSalesReps = () => {
    if (users.length === 0) {
      return null;
    }
    return users.map(user => (
      <option value={user.id}>
        {user.name}
      </option>
    ));
  }

  const renderImage = () => {
    if (company.logo === '') {
      return null;
    }
    return (
      <div>
        <img src={company.logo} alt="Logo" className="img-circle" width={150} height={150} />
      </div>
    );
  }

  const buildFormData = () => {
    let formData = new FormData();
    formData.append('company[name]', company.name);
    formData.append('company[email]', company.email);
    formData.append('company[website]', company.website);
    formData.append('company[user_id]', company.salesRepId);
    formData.append('company[telephone]', company.telephone);
    formData.append('company[address]', company.address);
    formData.append('company[city]', company.city);
    formData.append('company[state]', company.state);
    formData.append('company[zip_code]', company.zip_code);
    formData.append('company[as400_id]', company.as400_id);
    formData.append('company[stars]', company.stars);
    formData.append('company[source]', company.company_source.name);
    formData.append('company[source_id]', company.company_source.id);

    if (imageUpdated) {
      const file = document.getElementById('logo').files[0];
      formData.append('company[logo]', file, file.name);
    }

    console.log(formData);

    return formData;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('submitting form...');
    const data = buildFormData();

    saveCompany(data)
      .then((res) => {
        setErrors(res.errors);
        if (res.errors.length === 0) {
          console.log('saving company... completed');
          navigate('/companies');
        }
      });
  }

  return (
    <ErrorBoundary>
      <PageHeader title={pageTitle} />
      <CardWrapper>
        <form className="taskForm form-horizontal" onSubmit={(e) => handleSubmit(e)}>
          <Error errors={errors} />

          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label forHtml="name" className="control-label">Name</label>
                <input type="text" name="name" id="name" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <CompanySourceDropdown
                updateCompanySourceField={(value) => updateCompany('company_source', value)}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={3}>
              <div className="form-group">
                <label for="logo" className="control-label btn-primary btn">
                  <CompanyIcon size={21} />
                  &nbsp;
                  Upload Logo
                </label>
                <p>
                  Image Size: 200 x 200
                </p>
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  style={{
                    width: 0.1,
                    height: 0.1,
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  onChange={e => displayImage(e)}
                />
              </div>
            </Col>
            <Col xs={12} sm={9}>
              {renderImage()}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="form-group">
                <label forHtml="stars" className="control-label">Star Rating</label>
                <DropdownList
                  data={stars()}
                  textField="name"
                  valueField="value"

                  onChange={value => setStars(value)}
                />
              </div>
            </Col>
            {inValidRole(roles, localStorage) && (
              [
                <Col md={6}>
                  <div className="form-group">
                    <label className="control-label" forHtml="salesRepId">Sales Rep</label>
                    <select name="salesRepId" id="salesRepId" className="form-control" onChange={(e) => handleInputChange(e)}>
                      <option value="">-- Select Sales Rep --</option>
                      {renderSalesReps()}
                    </select>
                  </div>
                </Col>,
                <Col md={3}>
                  <div className="form-group">
                    <label forHtml="as400_id" className="control-label">AS400 ID</label>
                    <input type="text" name="as400_id" id="as400_id" className="form-control" onChange={(e) => handleInputChange(e)} />
                  </div>
                </Col>,
              ]
            )}
          </Row>
          <Row>
            <Col md={8}>
              <div className="form-group">
                <label forHtml="website" className="control-label">Website</label>
                <input type="text" name="website" id="website" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label forHtml="email" className="control-label">Email Address</label>
                <input type="text" name="email" id="email" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
          
            <Col md={3}>
              <div className="form-group">
                <label forHtml="telephone" className="control-label">Telephone Number</label>
                <input type="tel" name="telephone" id="telephone" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Address</h3>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label forHtml="address" className="control-label">Address</label>
                <input type="text" name="address" id="address" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
          </Row>
          
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label forHtml="city" className="control-label">City</label>
                <input type="text" name="city" id="city" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label forHtml="state" className="control-label">State</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  className="form-control"
                  maxlength="2"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label forHtml="zip_code" className="control-label">Zip Code</label>
                <input type="text" name="zip_code" id="zip_code" className="form-control" onChange={(e) => handleInputChange(e)} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <input type="submit" value="save" className="btn btn-primary" />
              &nbsp;
                <Link to="/tasks" className="btn btn-secondary">
                Cancel
                </Link>
            </Col>
          </Row>
        </form>
      </CardWrapper>
    </ErrorBoundary>
  );
}


export default CompanyNewForm;
