import React, { useState } from 'react'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert
} from 'reactstrap'

// util
import { splitLines } from '../../libs/utils'

// styles
import styles from './Message.module.scss'

// api
import { sendQuoteMessage } from '../../api/quote'

const Message = ({id, quote, addNew }) => {
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const [messageText, setMessageText] = useState('')
  const [newMessages, setNewMessages] = useState([])

  // actions
  const toggle = () => setOpen(!open)

  const showBlock = () => {
    if (quote.status === 'declined') { return false }
    if (quote.status === 'approved') { return false }
    return true
  }

  const submitMessage = (e) => {
    e.preventDefault()
    setErrors([])

    if (messageText === '') {
      setErrors(['Message cannot be blank'])
      return
    }

    let data = {
      quoteId: id,
      quote_message: {
        message: messageText
      }
    }

    sendQuoteMessage(data)
      .then(res => {
        if (res.errors) {
          setErrors(res.errors)
        } else {
          setErrors([])
          setMessageText('')
          setNewMessages([...newMessages, res.message])
        }
      })
  }

  const handleChange = (e) => {
    setMessageText(e.target.value)
  }

  // renders

  if (quote === null) {
    return null
  }

  return (
    <>
      {/* button */}
      {quote.status !== 'draft' && (
        <div className={styles.spacing}>
          <Button
            color='primary'
            onClick={e => toggle()}
          >
            Messages
            {' '}
            ({quote.messages.length})
          </Button>
        </div>
      )}

      {/* modal */}
      <Modal
        isOpen={open}
        toggle={toggle}
        size='xl'
        >
        <ModalHeader toggle={toggle}>
          Messages
          {' '}
          ({quote.messages.length})
        </ModalHeader>
        <ModalBody>
          {quote.messages.map((message, idx) => (
            <Row key={idx} className={styles.messageRow}>
              <Col className={message.external ? styles.external : styles.internal}>
                <h4 className={styles.header1}>{message.from} @ {message.createdAt}</h4>
                <div className={styles.message}>
                  {splitLines(message.message)}
                </div>
              </Col>
            </Row>
          ))}
          
          {newMessages.map((message, idx) => (
            <Row key={idx} className={styles.messageRow}>
              <Col className={message.external ? styles.external : styles.internal}>
                <h4 className={styles.header1}>{message.from} @ {message.createdAt}</h4>
                <div className={styles.message}>
                  {splitLines(message.message)}
                </div>
              </Col>
            </Row>
          ))}

          {(showBlock() && addNew) && (
            <form onSubmit={(e) => submitMessage(e)}>
              {errors.length > 0 && (
                <Alert color="danger">
                  {errors.map((error, idx) => (
                    <p key={idx}>
                      {error}
                    </p>
                  ))}
                </Alert>
              )}
              <Row>
                <Col className="form-group">
                  <label
                    htmlFor="message"
                    className="form-label"
                  >
                    Send a Message to the Customer
                  </label>
                  <textarea
                    className="form-control"
                    rows={10}
                    name="message"
                    value={messageText}
                    onChange={e => handleChange(e)}
                  ></textarea>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                    <Button
                      color="secondary"
                      onClick={e => toggle()}
                    >
                      Close/Cancel
                    </Button>
                </Col>
                <Col className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </form>
          )}


        </ModalBody>
      </Modal>
    </>
  )
}

export default Message