import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';

import EventForm from './EventForm';
import EventList from './EventList';

const Events = () => (
  <>
    <Helmet>
      <title>The Hub - Events</title>
    </Helmet>
    <Routes>
      <Route path=":id" element={<EventForm />} />
      <Route path="" element={<EventList />} />
    </Routes>
  </>
);

export default Events;
