import React from 'react';
import { Link } from 'react-router-dom';
import { updateCompany } from '../../api/company';
import { inValidRole } from '../../libs/utils';

import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import CompanyIcon from 'mdi-react/CompanyIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';

import Error from '../../components/Error';

import { stars } from '../../components/helpers';

// const stars = [
//   { value: 1, name: '⋆ Propsect' },
//   { value: 2, name: '⋆ ⋆ Customer' },
//   { value: 3, name: '⋆ ⋆ ⋆ Top Customer' },
// ];

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      company: props.company,
      saving_record: false,
      saved: false,
      errors: [],
      users: [],
      message: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.refreshCompany.bind(this);
  }

  componentDidMount() {
    this.setState({ company: this.props.company });
  }

  componentWillReceiveProps() {
    if (this.state.saved) {
      this.setState({ company: this.props.company, saved: false });
    }
  }

  updateCompany(key, value) {
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [key]: value,
      },
    }));
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.updateCompany(name, value);
  }

  setStars = (value) => {
    console.log(value);
    this.updateCompany('stars', value.value);
  }

  displayImage() {
    this.setState({ imageUpdated: true });
    const file = document.getElementById('logo').files[0];
    this.updateCompany('logo', URL.createObjectURL(file));
  }

  renderImage() {
    const { company } = this.state;
    if (company.logo === '') {
      return null;
    }
    return (
      <div>
        <img src={company.logo} alt="Logo" className="img-circle" width={150} height={150} />
      </div>
    );
  }

  buildFormData() {
    const { company, imageUpdated } = this.state;
    let formData = new FormData();
    formData.append('company[name]', company.name);
    if (company.email !== null) {
      formData.append('company[email]', company.email);
    }
    if (company.website !== null && company.website !== "undefined") {
      formData.append('company[website]', company.website);
    } else {
      formData.append('company[website]', '');
    }
    if (company.telephone !== null && company.telephone !== "undefined") {
      formData.append('company[telephone]', company.telephone);
    } else {
      formData.append('company[telephone]', '');
    }
    formData.append('company[address]', company.address);
    formData.append('company[city]', company.city);
    formData.append('company[state]', company.state);
    formData.append('company[zip_code]', company.zip_code);
    formData.append('company[as400_id]', company.as400_id);
    formData.append('company[stars]', company.stars);

    if (imageUpdated) {
      const file = document.getElementById('logo').files[0];
      formData.append('company[logo]', file, file.name);
    }

    console.log(formData);

    return formData;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ saving_record: true });
    console.log('submitting form...');

    const { id } = this.state.company;
    const data = this.buildFormData();

    updateCompany(id, data)
      .then((res) => {
        console.log('saving company... completed');
        this.setState({
          saving_record: false,
          saved: false,
          errors: res.errors,
        });
        if (res.errors.length === 0) {
          this.setState({ 
            message: 'Company Updated'
          }, () => this.hideMessage());
          this.props.refreshCompany(id);
        } 
      });
  }

  hideMessage() {
    const s = this;
    setTimeout(function() {
      s.setState({ message: '' });
    }, 3000);
  }

  renderSaveButton() {
    const { saving_record } = this.state;
    if (saving_record) {
      return (
        <span>
          <LoadingIcon size={24} />
          Saving
        </span>
      );
    } else {
      return (
        <input type="submit" value="save" className="btn btn-primary" />
      );
    }
  }

  render() {
    const { company, errors, message } = this.state;
    return (
      <form className="taskForm form-horizontal" onSubmit={this.handleSubmit}>
        <Error errors={errors} />
        <Row>
          <Col xs={12} sm={3}>
            <div className="form-group">
              <label for="logo" className="control-label btn-primary btn">
                <CompanyIcon size={21} />
                &nbsp;
                Upload Logo
              </label>
              <p>
                Image Size: 150 x 150
              </p>
              <input
                type="file"
                name="logo"
                id="logo"
                style={{
                  width: 0.1,
                  height: 0.1,
                  opacity: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  zIndex: -1,
                }}
                onChange={e => this.displayImage(e)}
              />
            </div>
          </Col>
          <Col xs={12} sm={9}>
            {this.renderImage()}
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <div className="form-group">
              <label forHtml="stars" className="control-label">Star Rating</label>
              <DropdownList
                data={stars()}
                textField="name"
                valueField="value"
                value={company.stars}
                onChange={value => this.setStars(value)}
              />
            </div>
          </Col>
          {inValidRole(['admin'], localStorage) && (
            <Col md={3}>
              <div className="form-group">
                <label forHtml="as400_id" className="control-label">AS400 ID</label>
                <input
                  type="text"
                  name="as400_id"
                  id="as400_id"
                  className="form-control"
                  value={company.as400_id}
                  onChange={this.handleInputChange}
                />
              </div>
            </Col>
          )}
          {!inValidRole(['admin'], localStorage) && (
            <Col md={3}>
              <div className="form-group">
                <label forHtml="as400_id" className="control-label">AS400 ID</label>
                <input
                  type="text"
                  name="as400_id"
                  id="as400_id"
                  className="form-control"
                  value={company.as400_id} readonly
                />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <label forHtml="name" className="control-label">Name</label>
              <input type="text" name="name" id="name" className="form-control" value={company.name} onChange={this.handleInputChange} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <label forHtml="website" className="control-label">Website</label>
              <input type="text" name="website" id="website" className="form-control" value={company.website} onChange={this.handleInputChange} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <div className="form-group">
              <label forHtml="email" className="control-label">Email Address</label>
              <input type="text" name="email" id="email" className="form-control" value={company.email} onChange={this.handleInputChange} />
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label forHtml="telephone" className="control-label">Telephone Number</label>
              <input type="tel" name="telephone" id="telephone" className="form-control" value={company.telephone} onChange={this.handleInputChange} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3>Address</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <label forHtml="address" className="control-label">Address</label>
              <input type="text" name="address" id="address" className="form-control" value={company.address} onChange={this.handleInputChange} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="form-group">
              <label forHtml="city" className="control-label">City</label>
              <input type="text" name="city" id="city" className="form-control" value={company.city} onChange={this.handleInputChange} />
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label forHtml="state" className="control-label">State</label>
              <input type="text" name="state" id="state" className="form-control" value={company.state} onChange={this.handleInputChange} />
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label forHtml="zip_code" className="control-label">Zip Code</label>
              <input type="text" name="zip_code" id="zip_code" className="form-control" value={company.zip_code} onChange={this.handleInputChange} />
            </div>
          </Col>
        </Row>
        {message !== '' && (
          <Row>
            <Col>
              <div class="alert alert-info">
                {message}
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col className="text-center">
            {this.renderSaveButton()}
          </Col>
        </Row>
      </form>
    );
  }
}

export default Form;
