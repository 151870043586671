import React, { useState } from 'react';
import {
  Row,
  Col,
  Badge,
  Tooltip
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import CheckIcon from 'mdi-react/CheckIcon'
import NoteIcon from 'mdi-react/NoteIcon'

// utils
import { renderAvatar, numberToCurrency, inValidRole, splitLines } from '../../libs/utils';
import { findQuoteStatus } from '../../libs/quote';
import { quoteStatusList } from '../../libs/list'

import styles from './QuoteRow.module.scss';

const QuoteRowPending = ({ quote }) => {
  const [openTouch, setOpenTouch] = useState(false)
  const [openNotes, setOpenNotes] = useState(false)

  // actions
  const toggleTouch = () => setOpenTouch(!openTouch)
  const toggleNotes = () => setOpenNotes(!openNotes)

  const renderBadge = () => {
    const { status } = quote
    const { days, when, badge_color } = quote.aging
    const badgeText = `${when} old`

    if (['draft', 'approved', 'expired', 'declined'].indexOf(status) > -1) { return null }

    if (days === -1) {
      return null
    }

    return (
      <Badge
        color={badge_color}
        className={styles.badge}
      >
        Aged:
        {' '}
        <strong>
          {badgeText}
        </strong>
      </Badge>
    )
  }

  const renderBadgeForWhenExpired = () => {
    const { expiration_date, status } = quote

    if (status === "draft") { return null }

    if ((expiration_date === null) || (status === "expired") || status === 'approved')  { return null }

    return (
      <Badge
        color="success"
        className={styles.badge}
      >
        Expires:
        {' '}
        <strong>
          <Moment format="MM/DD/YYYY">
            {expiration_date}
          </Moment>
        </strong>
      </Badge>
    )
  }

  const renderStatus = (status) => {
    let text = quoteStatusList().filter(x => x.value === status)[0].name
    let badgeColor = "secondary"

    return (
      <Badge color={badgeColor} className={styles.badge}>{text} - Payment Pending</Badge>
    )
  }

  const renderQuoteNumber = () => {
    if (quote.quote_number === null || quote.quote_number === "") { return null; }

    return (
      <Badge
        color="primary"
        className={styles.badge}
      >
        Quote Number:
        {' '}
        <strong>
          {quote.quote_number}
        </strong>
      </Badge>
    )
  }

  const renderButton = () => {
    let link = `/quote/${quote.id}`
    if (quote.status === "expired") {
      link = `/quote/expired/${quote.id}`
    }

    return (
      <Link
        className="btn btn-primary"
        to={{
          pathname: link,
        }}
      >
        View
      </Link>
    )
  }

  const renderAETouch = () => {
    let text = "NO AE Touch"
    let checkColor = styles.untouch
    if (quote.aeTouch) {
      text = "AE Touch"
      checkColor = styles.touch
    }

    return (
      <>
        <CheckIcon
          size={20}
          className={checkColor}
          id={`aeTouch_${quote.id}`}
        />
        <Tooltip
          isOpen={openTouch}
          target={`aeTouch_${quote.id}`}
          toggle={toggleTouch}
        >
          {text}
        </Tooltip>
      </>
    )
  }

  const renderInternalNotesNotice = () => {
    if (!quote.hasInternalNotes) { return null }

    return (
      <>
        <NoteIcon size={20}
          id={`internalNotes_${quote.id}`}
        />
        <Tooltip
          isOpen={openNotes}
          target={`internalNotes_${quote.id}`}
          toggle={toggleNotes}
          placement="bottom"
        >
          <p className={styles.notes}>
            {quote.hasInternalNotes}
            {splitLines(quote.internal_notes)}
          </p>
        </Tooltip>
      </>
    )

  }

  const renderApprovedDate = () => {
    const finalStatus = findQuoteStatus(quote.history, 'approved')

    return (
      <small>
        Approved on
        {' '}
        <Moment format="MM/DD/YYYY">{finalStatus.date}</Moment>
        {' '}
        by {finalStatus.user}
        <br />
        <Moment date={finalStatus.date} durationFromNow />
      </small>
    )
  }

  return (
    <>
      <Row className="mb-2">
        <Col xs={12} md={8}>
          {renderStatus(quote.status)}
          {renderQuoteNumber()}
          {renderBadge()}
          {renderBadgeForWhenExpired()}
        </Col>
        <Col xs={12} md={4} className="text-end">
          {renderInternalNotesNotice()}
          {' '}
          {renderAETouch()}
        </Col>
      </Row>
     
      <Row>
        <Col xs={6} md={1}>
          {renderAvatar(quote.sales_rep, 50)}
        </Col>
        <Col xs={12} md={5}>
          <strong>
            {quote.company.name}
          </strong>
          {quote.projectName && (
            <>
              {' '}
              <Badge color="info" className="ml-2">
                {quote.projectName}
              </Badge>
            </>
          )}
          <br />
          {quote.contact.first_name}
          {' '}
          {quote.contact.last_name}
        </Col>
        
        
        <Col xs={12} md={4}>
          Quote Total:
          {' '}
          {numberToCurrency(quote.grandTotal)}
          <br />
          {renderApprovedDate()}
        </Col>
        <Col
          xs={12}
          md={2}
          className="text-end"
        >
          {renderButton()}
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default QuoteRowPending;
