import React from 'react';
import { Helmet } from 'react-helmet';
import Pagination from 'react-js-pagination';
import { Row, Col } from 'reactstrap';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import AlertItem from '../../components/AlertItem';

import { getAlerts } from '../../api/alert';

class Alerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      alerts: [],
      currentPage: 1,
      totalPages: 1,
      totalRecords: 0,
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    console.log("loading alerts...");
    this.loadData();
  }

  loadData() {
    const { currentPage } = this.state;

    getAlerts({ currentPage })
      .then((response) => {
        this.setState({ 
          alerts: response.alerts,
          currentPage: response.details.current_page,
          totalPages: response.details.total_pages,
          totalRecords: response.details.total_records,
          loading: false,
        });
      });
  }

  handlePageChange(pageNumber) {
    window.scrollTo({ top: 100, behavior: 'smooth' });
    this.setState({ currentPage: pageNumber }, () => {
      this.loadData();
    });
  }

  renderList() {
    const { alerts } = this.state;
    return alerts.map(item => <AlertItem alert={item} key={item.id} />);
  }

  renderLoading() {
    const { loading } = this.state;
    if (loading) {
      return (
        <Row>
          <Col className="text-center">
            Loading...
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderHeader() {
    return [
      <Row className="p-2">
        <Col md={2}>
          <strong>Date</strong>
        </Col>
        <Col md={1}>
          <strong>Type</strong>
        </Col>
        <Col md={8}>
          <strong>Message</strong>
        </Col>
        <Col md={1}>
          <strong>Link</strong>
        </Col>
      </Row>,
      <hr />
    ];
  }

  render() {
    const { currentPage, totalRecords } = this.state;

    return [
      <Helmet>
        <title>The Hub - Alerts</title>
      </Helmet>,
      <PageHeader title="Alerts" />,
      <CardWrapper>
        {this.renderLoading()}
        {this.renderHeader()}
        {this.renderList()}
      </CardWrapper>,
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={20}
        totalItemsCount={totalRecords}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange}
        innerClass={'pagination'}
        itemClass={'paginate_button page-item'}
        linkClass={'page-link'}
      />
    ];
  }
}

export default Alerts;
