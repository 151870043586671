import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import moment from 'moment';
import DeleteIcon from 'mdi-react/DeleteIcon';

class CompanyDocumentItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    const { companyDocument } = this.props;
    const { id } = companyDocument;

    return (
      [
        <Row>
          <Col>
            <a href={companyDocument.file_url} target="_blank">
              {companyDocument.file_name}
            </a>
          </Col>
        </Row>,
        <Row>
          <Col md={5}>
            <span className="label label-primary">
              {companyDocument.uploaded_by}
            </span>
          </Col>
          <Col md={3}>
            {companyDocument.file_size}
          </Col>
          <Col md={3}>
            <Moment format="MM/DD/YYYY h:mm A">
              {companyDocument.created_at}
            </Moment>
          </Col>
          <Col md={1}>
            <a
              href="#"
              role="button"
              onClick={(event) => this.props.deleteCompanyDocument(event, id)}
            >
              <DeleteIcon size={24} />
            </a>
          </Col>
        </Row>,
        <hr />,
      ]
    );
  }
}

export default CompanyDocumentItem;
