import React, { useState, useEffect } from 'react';
import {
  Alert
} from 'reactstrap';

const getMessage = (code) => {
  let message = ''
  switch (code) {
    case "401":
      message = "You are not authorized to view this quote."
      break
    default:
      message = "Not Authorized."
      break
  }
  return message
}

const AuthorizationNotice = () => {
  const [code, setCode] = useState(null)
  
  useEffect(() => {
    const sp = new URLSearchParams(window.location.search)
    sp.has('code')
    const code = sp.get('code')
    setCode(code)
  }, [])

  if (code) {
    return (
      <Alert color="danger">
        {code} - {getMessage(code)}
      </Alert>
    )
  }

  return null;
}

export default AuthorizationNotice